import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from '../../components/Button';
import TokenIcon from './TokenIcon';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { Menu } from '@mui/material';
import Icon from '../../images/tokens/Icon';
import colors from '../../styles/colors';

const ADD_PILOT_TOKEN = 'ADD_PILOT_TOKEN';
const APPLY_PILOT_HITS = 'APPLY_PILOT_HITS';
// const REMOVE_PILOT_TOKEN = 'REMOVE_PILOT_TOKEN';

const TokenButton = ({ tokenName, onClick, ...rest }) => {
  const sanitizedTokenName = tokenName.toLowerCase();
  return (
    <TokenButtonContainer onClick={() => onClick(tokenName)}>
      <TokenIcon tokenName={sanitizedTokenName} suppressToolTip {...rest} />
      <p>{tokenName}</p>
    </TokenButtonContainer>
  );
};

function PilotButtons({ socket, pilotId, isOpponentCard }) {
  const [hitCount, setHitCount] = useState(0);
  const [critCount, setCritCount] = useState(0);
  const [containerClassName, setContainerClassName] = useState(
    isOpponentCard ? '' : 'collapsed'
  );

  // Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const incDamage = () => setHitCount(hitCount + 1);
  const incCrit = () => setCritCount(critCount + 1);
  const clearHits = () => {
    setHitCount(0);
    setCritCount(0);
  };

  const addToken = (token) => {
    socket.emit(ADD_PILOT_TOKEN, { pilotId, token });
  };
  // const removeToken = (token) => {
  //   socket.emit(REMOVE_PILOT_TOKEN, { pilotId, token });
  // };
  const applyHits = () => {
    socket.emit(APPLY_PILOT_HITS, {
      pilotId,
      hits: { hits: hitCount, crits: critCount },
    });
    clearHits();
  };

  useEffect(() => {
    setTimeout(() => {
      setContainerClassName(isOpponentCard ? 'collapsed' : '');
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpponentCard]);

  const hasHit = hitCount > 0;
  const hasCrit = critCount > 0;

  return (
    <ButtonContainer className={containerClassName}>
      <Button onClick={() => addToken('stress')} type="token" background="#333">
        <TokenIcon tokenName="stress" toolTipText="Add Stress Token" />
      </Button>
      <Button onClick={() => addToken('focus')} type="token" background="#333">
        <TokenIcon tokenName="focus" toolTipText="Add Focus Token" />
      </Button>
      <Button onClick={() => addToken('evade')} type="token" background="#333">
        <TokenIcon tokenName="evade" toolTipText="Add Evade Token" />
      </Button>
      <Button
        onClick={handleClick}
        type="text"
        color="white"
        background="#333"
        toolTipText="Add Other Tokens"
      >
        <AddCircleIcon />
      </Button>

      <Menu
        id="more-tokens-menu"
        aria-labelledby="more-tokens-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{
          div: {
            backgroundColor: 'transparent',
          },
          ul: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <ExtraTokensContainer>
          <TokenButton tokenName="Calculate" onClick={addToken} size="large" />
          <TokenButton tokenName="Reinforce" onClick={addToken} size="large" />
          <TokenButton
            tokenName="ReinforceAft"
            onClick={addToken}
            size="large"
          />
          <TokenButton tokenName="Disarm" onClick={addToken} size="large" />
          <TokenButton tokenName="Tractor" onClick={addToken} size="large" />
          <TokenButton tokenName="Jam" onClick={addToken} size="large" />
          <TokenButton tokenName="Strain" onClick={addToken} size="large" />
          <TokenButton tokenName="Ion" onClick={addToken} size="large" />
          <TokenButton tokenName="Deplete" onClick={addToken} size="large" />
          <TokenButton tokenName="Cloak" onClick={addToken} size="large" />
        </ExtraTokensContainer>
      </Menu>
      <Button
        type="secondary"
        toolTipText="Stage Hit Damage"
        onClick={incDamage}
        style={{ border: hasHit ? `2px solid ${colors.hullYellow}` : '' }}
      >
        <Icon
          type="hit"
          size="30px"
          color={hasHit ? colors.hullYellow : colors.white}
          sx={{ marginTop: '6px' }}
        />
        {hasHit && (
          <span style={{ color: colors.hullYellow, marginRight: '8px' }}>
            {hitCount}
          </span>
        )}
      </Button>

      <Button
        type="secondary"
        toolTipText="Stage Critical Hit Damage"
        onClick={incCrit}
        style={{ border: hasCrit ? `2px solid ${colors.weaponRed}` : '' }}
      >
        <Icon
          type="criticalHit"
          size="30px"
          color={hasCrit ? colors.weaponRed : colors.white}
          sx={{ marginTop: '6px' }}
        />
        {hasCrit && (
          <span style={{ color: colors.weaponRed, marginRight: '8px' }}>
            {critCount}
          </span>
        )}
      </Button>
      <div className="damage-mod">
        <Button
          type="secondary"
          toolTipText="Apply All Hits"
          placement="bottom"
          onClick={applyHits}
          style={{ padding: '1px', maxWidth: '100%' }}
        >
          <CheckIcon sx={{ fontSize: '25px' }} />
        </Button>
        <Button
          type="secondary"
          toolTipText="Reset"
          placement="bottom"
          onClick={clearHits}
          style={{ padding: '3px 2px', maxWidth: '100%' }}
        >
          <CloseIcon sx={{ fontSize: '25px' }} />
        </Button>
      </div>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  opacity: 100%;
  width: 69px;
  min-width: 69px;
  overflow: hidden;
  transition: all 0.1s ease;

  &.collapsed {
    width: 0px;
    padding: 0;
    opacity: 0;
  }

  button {
    margin-bottom: 6px;
  }

  svg {
    width: 40px;
  }

  .damage-mod {
    display: grid;
    grid-template-columns: 30px 30px;
    grid-gap: 2px;
    width: 61px;

    svg {
      stroke: yellow;
      stroke-width: 2;
    }
  }
`;

const TokenButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 10px;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  p {
    font-weight: bold;
    font-size: 12px;
  }

  svg {
    padding: 4px;
  }

  &:hover {
    svg {
      background-color: #666;
      border-radius: 4px;
    }
    p {
      text-shadow: 2px 2px 2px black;
    }
  }
`;

const ExtraTokensContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  padding: 0 8px;

  div:nth-child(10) {
    grid-column: 2 / span 1;
  }
`;

export default PilotButtons;
