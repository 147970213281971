import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import GameBoardHeader from './GameBoardHeader';
import PilotCard from './PilotCard';
import PilotSummaryCard from './PilotSummaryCard';
import CustomButton from '../../components/Button';

import { GameStateContext } from '../../context/gameState';
import { SocketContext } from '../../context/socket';
import {
  X_WING_BACKGROUND,
  X_WING_CUSTOM_BACKGROUND,
  REJOIN_GAME,
  X_WING_GAME_CODE,
  X_WING_PLAYER_NAME,
  X_WING_SOCKET_ID,
  names,
} from '../../utils';

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Button, Modal } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { getBackground } from './gameBoardBGs';
import SettingsModal from './SettingsModal';

const idRefMap = {};

const getCustomBackground = (current) => {
  if (current) return current;
  return localStorage.getItem(X_WING_CUSTOM_BACKGROUND);
};

function GameBoard() {
  const [showOpponentInMain, setShowOpponentInMain] = useState(false);
  const [collapseSideBar, setCollapseSideBar] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [background, setBackground] = useState('');
  const [customBackground, setCustomBackground] = useState(
    getCustomBackground()
  );

  const socket = useContext(SocketContext);
  const { gameState } = useContext(GameStateContext);

  const {
    player1 = {},
    player2 = {},
    currentShips = [],
    phase,
    isHost,
  } = gameState || {};

  const player = isHost ? player1 : player2;
  const otherPlayer = isHost ? player2 : player1;

  const pilots = player?.pilots ?? [];
  const opponentPilotSummary = otherPlayer?.pilots ?? [];

  const mainPilotsList = showOpponentInMain ? opponentPilotSummary : pilots;
  const sidePilotsList = showOpponentInMain ? pilots : opponentPilotSummary;

  const toggleSettingsModal = () => setShowSettingsModal(!showSettingsModal);

  const handleInitialPageLoad = () => {
    const backgroundSetting = localStorage.getItem(X_WING_BACKGROUND);
    if (!backgroundSetting) {
      localStorage.setItem(X_WING_BACKGROUND, 'random');
    }

    setBackground(backgroundSetting);

    const savedGameCode = localStorage.getItem(X_WING_GAME_CODE);
    const savedPlayerName = localStorage.getItem(X_WING_PLAYER_NAME);
    const savedSocketId = localStorage.getItem(X_WING_SOCKET_ID);

    if (savedGameCode && savedPlayerName) {
      socket.emit(REJOIN_GAME, {
        gameCode: savedGameCode,
        playerName: savedPlayerName,
        savedSocketId: savedSocketId,
      });
    }
  };
  useEffect(handleInitialPageLoad, [socket]);

  const myRefs = useRef([]);
  const [mostRecentCurrentShipId, setMostRecentCurrentShipId] = useState(null);

  useEffect(() => {
    if (currentShips.length === 0) return setMostRecentCurrentShipId(null);

    const currentShipId = currentShips[0];
    const currentRefIndex = idRefMap[currentShipId];
    const scrollRef = myRefs.current[currentRefIndex];

    if (!scrollRef) return setMostRecentCurrentShipId(null);

    if (mostRecentCurrentShipId !== currentShipId) {
      setMostRecentCurrentShipId(currentShipId);
      scrollRef?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [currentShips, mostRecentCurrentShipId]);

  const setRefIndex = (pilotId, index) => {
    idRefMap[pilotId] = index;
  };

  const isOpponentPlayerOne = otherPlayer.isPlayerOne ^ showOpponentInMain;
  const isPlayerOne = player.isPlayerOne ^ showOpponentInMain;

  const bg = getBackground(background, getCustomBackground(customBackground));

  return (
    <Container isOpponent={showOpponentInMain} bg={bg}>
      <GameBoardHeader
        isPlayerOne={isPlayerOne}
        toggleSettingsModal={toggleSettingsModal}
      />
      <LowerArea className={names({ opponent: showOpponentInMain })}>
        <PilotsContainer>
          <h2
            className={names(
              {
                expanded: showOpponentInMain,
              },
              'opponent-label'
            )}
          >
            Opponent's Squadron
          </h2>
          {mainPilotsList.map((pilot, i) => (
            <PilotCard
              key={pilot.name + i}
              forwardRef={(element) => {
                myRefs.current[i] = element;
                setRefIndex(pilot.id, i);
              }}
              pilot={pilot}
              teamShipId={i + 1}
              player={player}
              isOpponentCard={showOpponentInMain}
              currentShips={currentShips}
              destroyedPilots={player.destroyedPilotIds}
              phase={phase}
            />
          ))}
        </PilotsContainer>
        <OpponentContainer
          collapsed={collapseSideBar}
          isOpponent={showOpponentInMain}
        >
          <div className="button-row">
            {collapseSideBar ? null : (
              <>
                <CustomButton
                  type="secondary"
                  onClick={() => setShowOpponentInMain(!showOpponentInMain)}
                >
                  Swap
                  <SwapHorizIcon />
                </CustomButton>
                <h3>
                  {showOpponentInMain ? 'Your Squadron' : 'Opponent Summary'}
                </h3>
              </>
            )}
            <Button
              onClick={() => setCollapseSideBar(!collapseSideBar)}
              sx={{ minWidth: '30px', width: '40px' }}
            >
              <KeyboardDoubleArrowRightIcon
                sx={{
                  transform: collapseSideBar ? 'rotate(180deg)' : null,
                  transition: 'transform .25s ease',
                }}
              />
            </Button>
          </div>
          <SummaryArea>
            {sidePilotsList.map((p, i) => (
              <PilotSummaryCard
                key={p.name + i}
                pilot={p}
                teamShipId={i + 1}
                isPlayerOne={isOpponentPlayerOne}
                currentShips={currentShips}
                destroyedPilots={otherPlayer.destroyedPilotIds}
                phase={phase}
                collapsed={collapseSideBar}
              />
            ))}
          </SummaryArea>
        </OpponentContainer>
      </LowerArea>
      <Modal
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      >
        <div>
          <SettingsModal
            background={background}
            setBackground={setBackground}
            setShowSettingsModal={setShowSettingsModal}
            customBackground={customBackground}
            setCustomBackground={setCustomBackground}
          />
        </div>
      </Modal>
    </Container>
  );
}

const Container = styled.main`
  transition: background-color 1s ease;
  background: ${({ bg }) => bg?.imgUrl || bg?.color || 'black'};
  background-size: cover;
  background-attachment: fixed;
  overflow: scroll;
`;

const headerHeight = '55px';

const LowerArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 55px;
  background-color: rgba(0, 0, 0, ${({ bg }) => 1 - bg?.opacity});
  height: 100vh;

  &.opponent {
    background-color: rgba(255, 0, 0, 0.15);
  }
`;

const PilotsContainer = styled.div`
  padding: 12px;
  .opponent-label {
    height: 0;
    overflow: hidden;
    transition: all 0.1s ease 0.1s;
  }

  .opponent-label.expanded {
    margin-bottom: 12px;
    height: 36px;
  }
`;

const OpponentContainer = styled.div`
  background: rgba(0, 0, 0, 0.6);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: -4px 0px 5px 1px rgb(0, 0, 0, 0.5);
  height: calc(100vh - ${headerHeight});
  width: ${({ collapsed }) => (collapsed ? '60px' : '310px')};
  position: fixed;
  right: 0;
  display: grid;
  grid-template-rows: ${headerHeight} 1fr;
  transition: width 0.5s ease;
  z-index: 100;

  .button-row {
    height: ${headerHeight};
    display: grid;
    gap: 6px;
    grid-template-columns: auto 1fr auto;
    place-items: center;
    padding: 0 12px;
    box-shadow: 0px 3px 5px 1px rgb(0, 0, 0, 0.5);
    background-color: #333;
  }

  h3 {
    text-align: left;
    justify-self: flex-start;
  }
`;

const SummaryArea = styled.div`
  overflow-y: auto;
  padding: 12px 0;
`;

export default GameBoard;
