const randomOpacityValue = () => {
  const rInt = Math.floor(Math.random() * 10 + 1);
  return rInt / 10;
};

export const generateOpacityArray = () => {
  var opacityArray = [];
  for (var i = 0; i < 19; i++) {
    opacityArray.push(randomOpacityValue());
  }

  return opacityArray;
};

export const minOpacity = 0.2;
const gap = (1 - minOpacity) / 18;

export const offArray = new Array(19).fill(minOpacity);

export const onArray = new Array(19).fill(minOpacity).map((val, i) => {
  return val + gap * i;
});

export const generateForceOpacityArray = (prevArray) => {
  if (prevArray[prevArray.length - 1] !== minOpacity) {
    let firstOn = prevArray.findIndex((val) => val !== minOpacity);

    const copy = [...prevArray];
    copy[firstOn] = minOpacity;

    return copy;
  }

  let firstMin = prevArray.findIndex((val) => val === minOpacity);

  return [...onArray.slice(18 - firstMin), ...offArray.slice(0, 18 - firstMin)];
};
