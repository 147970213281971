import React from 'react';
import { XWingSvgContainer } from './xWingStyles';

function XWingSilhouette(props) {
  const {
    blurValue,
    open,
    fill,
    stroke,
    animateOnHover,
    height,
    width,
    blur,
    ...rest
  } = props || {};

  const fillColor = fill || '#fff';
  const strokeColor = stroke || 'none';

  return (
    <XWingSvgContainer
      xmlns="http://www.w3.org/2000/svg"
      width={width || '300'}
      height={height || '150'}
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 300 150"
      xmlSpace="preserve"
      open={open}
      animateOnHover={animateOnHover}
      {...rest}
    >
      <defs>
        <filter id="f2" x="0" y="0">
          <feGaussianBlur
            in="SourceGraphic"
            stdDeviation={blurValue || '1.5'}
          />
        </filter>
      </defs>
      <path
        fill={fillColor}
        filter={blur ? 'url(#f2)' : ''}
        stroke={strokeColor}
        strokeWidth="2"
        d="M150 44.633a5 5 0 00-.045.004 4.983 4.983 0 00-.59.04 5 5 0 00-.144.022c-.191.03-.38.071-.565.123a5 5 0 00-.148.045 4.996 4.996 0 00-.508.19 5 5 0 00-.13.06c-.172.081-.338.17-.499.27l-.015.01a5 5 0 00-.159.105c-.12.082-.238.17-.351.264a5 5 0 00-.235.203 4.954 4.954 0 00-.254.254 5 5 0 00-.246.28c0 .003-.002.005-.004.007A5 5 0 00145 49.633a5 5 0 001.727 3.78 4.994 4.994 0 00.548.411 5 5 0 00.053.033c.189.12.384.226.586.319a5 5 0 00.022.01c.203.092.414.171.628.236a5 5 0 00.051.016c.447.129.913.197 1.385.197s.938-.068 1.385-.197a5 5 0 00.05-.016c.215-.065.425-.144.63-.236l.02-.01c.203-.093.398-.2.587-.319a5 5 0 00.053-.033 5 5 0 00.011-.008c.187-.122.367-.256.537-.404a5 5 0 001.727-3.78 5 5 0 00-1.098-3.122 5 5 0 00-.183-.217 5 5 0 00-.184-.195 5 5 0 00-.01-.01 4.999 4.999 0 00-.168-.158 5 5 0 00-.037-.034l-.043-.037a5 5 0 00-.164-.134 5 5 0 00-.008-.008.403.403 0 01-.012-.01 5 5 0 00-.183-.139 5 5 0 00-.028-.02l-.033-.02a5 5 0 00-.199-.132 5 5 0 00-.178-.105c-.03-.017-.06-.036-.092-.053-.008-.004-.015-.01-.023-.013a5 5 0 00-.193-.098 5 5 0 00-.25-.113 5 5 0 00-.002 0 5 5 0 00-.26-.098 5 5 0 00-.232-.074l-.086-.026a5 5 0 00-.215-.054 5 5 0 00-.002 0h-.004a5 5 0 00-.211-.045 5.066 5.066 0 00-.104-.018 5 5 0 00-.181-.025 5 5 0 00-.053-.008c-.01-.001-.02 0-.03-.002a5 5 0 00-.25-.021 5 5 0 00-.284-.01z"
        opacity="1"
      ></path>
      <path
        className="x-wing-wing-set-1"
        fill={fillColor}
        filter={blur ? 'url(#f2)' : ''}
        stroke={strokeColor}
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M108.178 48.258a10.091 10.091 0 00-8.81 5.168 12.2 12.2 0 00-3.934 8.986c0 1.716.354 3.35.992 4.832h-4.024l-2.867.828v-1.144h-1.738v1.646l-2.803.809v-1.328h-1.738v1.83l-2.803.808v-1.355h-1.738v1.857l-1.192.344h-.015l-2.028.59v1.119H9.965l-2 1.074v1.074h120.344v-2.148h-7.89v-4.754l-1.235-1.25h-.272a12.2 12.2 0 00.992-4.832c0-2.455-.726-4.738-1.97-6.654-1.146-4.317-5.079-7.5-9.756-7.5zm-95.221 10.81v1.41a5.851 5.851 0 00-4.023 3.717H7.549v3.448h1.25a5.852 5.852 0 004.158 4.156v1.408h3.006v-1.408a5.852 5.852 0 004.355-5.66 5.86 5.86 0 00-4.355-5.66v-1.41zm158.486 16.58v2.15h14.953a12.233 12.233 0 00-4.578 17.49c1.146 4.316 5.079 7.5 9.756 7.5 3.785 0 7.083-2.086 8.81-5.169a12.2 12.2 0 002.936-13.818h4.03l2.869-.828v1.144h1.736v-1.646l2.805-.809v1.328h1.736v-1.83l2.805-.808v1.355h1.736V79.85l1.192-.344h.015l2.027-.59v-1.117h65.518l2-1.076v-1.075zm112.346 2.19v1.408a5.856 5.856 0 00-4.354 5.66 5.856 5.856 0 004.354 5.66v1.41h3.008v-1.41a5.858 5.858 0 004.023-3.716h1.385v-3.448h-1.252a5.861 5.861 0 00-1.517-2.638 5.86 5.86 0 00-2.64-1.518v-1.408z"
        opacity="1"
      ></path>
      <path
        className="x-wing-wing-set-2"
        fill={fillColor}
        filter={blur ? 'url(#f2)' : ''}
        stroke={strokeColor}
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M191.576 48.258c3.785 0 7.083 2.085 8.81 5.168a12.2 12.2 0 013.934 8.986c0 1.716-.354 3.35-.992 4.832h4.024l2.867.828v-1.144h1.738v1.646l2.803.809v-1.328h1.738v1.83l2.803.808v-1.355h1.738v1.857l1.191.344h.016l2.027.59v1.119h65.516l2 1.074v1.074H171.445v-2.148h7.889v-4.754l1.236-1.25h.272a12.2 12.2 0 01-.992-4.832c0-2.455.726-4.738 1.97-6.654 1.146-4.317 5.079-7.5 9.756-7.5zm95.22 10.81v1.41a5.851 5.851 0 014.024 3.717h1.385v3.448h-1.25a5.852 5.852 0 01-4.158 4.156v1.408h-3.006v-1.408a5.851 5.851 0 01-4.355-5.66 5.86 5.86 0 014.355-5.66v-1.41zm-158.485 16.58v2.15h-14.954a12.233 12.233 0 014.579 17.49c-1.146 4.316-5.079 7.5-9.756 7.5a10.091 10.091 0 01-8.81-5.169 12.2 12.2 0 01-2.936-13.818h-4.03l-2.869-.828v1.144H87.8v-1.646l-2.805-.809v1.328h-1.736v-1.83l-2.805-.808v1.355h-1.736V79.85l-1.192-.344h-.015l-2.028-.59v-1.117H9.965l-2-1.076v-1.075zm-112.346 2.19v1.408a5.856 5.856 0 014.354 5.66 5.856 5.856 0 01-4.354 5.66v1.41h-3.008v-1.41a5.858 5.858 0 01-4.023-3.716H7.549v-3.448H8.8a5.861 5.861 0 011.517-2.638 5.86 5.86 0 012.639-1.518v-1.408z"
        opacity="1"
      ></path>
      <path
        fill={fillColor}
        filter={blur ? 'url(#f2)' : ''}
        fillOpacity="1"
        stroke={strokeColor}
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="2"
        d="M139.64 47.953l-2.535 4.17h-2.57l-5.654 4.785-1.594 6.496-.031-.015-.02.082-.052-.026-.133.817-.58 2.455-1.059.086-.02.078-.02.078-1.264 8.598 3.351 13.287.983.088 2.293 7.199.646 2.031 1.313.233 5.443.96.117.022.652 1.605h.002l3.479.66 7.611 1.448H150l2.725-.518 5.207-.99 3.16-.6.515-1.267.14-.338 5.679-1.004 1.193-.21.912-2.866v-.004l2.028-6.361.98-.088 3.354-13.287-1.266-8.598-.022-.078-.019-.078-1.059-.086-.574-2.43-.137-.842-.054.028-.02-.084-.031.015-1.594-6.496-5.652-4.785h-2.57l-2.536-4.17H144.82z"
        display="inline"
        opacity="1"
      ></path>
    </XWingSvgContainer>
  );
}

export default XWingSilhouette;
