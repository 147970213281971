import React from 'react';

function BoostIcon({ color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...rest}
    >
      <g
        fill={color || 'white'}
        fillOpacity="1"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="1"
        strokeWidth="0"
      >
        <path
          d="M492.816 54.874L384.031 246.85l64.142-.517v359.972h92.364V245.591l64.142-.516L492.816 54.874z"
          transform="matrix(1 0 0 .86528 5.645 131.777)"
        ></path>
        <path d="M663.328 370.935l.49 70.406c-30.781 7.257-63.035 24.823-96.699 52.256v135.468h15.393c15.482-31.797 25.089-63.39 81.941-96.615l.412 59.133 164.576-111.861z"></path>
        <path d="M329.132 370.935L163.02 479.722l164.576 111.861.412-59.133c56.853 33.224 66.459 64.818 81.942 96.615h15.392V493.597c-33.664-27.433-65.918-45-96.7-52.256z"></path>
        <path d="M453.724 676.841H546.182V756.404H453.724z"></path>
        <path d="M453.724 783.676H546.182V824.138H453.724z"></path>
      </g>
    </svg>
  );
}

export default BoostIcon;
