import React from 'react';

function LockIcon({ color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...rest}
    >
      <g
        fill={color || 'white'}
        fillOpacity="1"
        stroke="red"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="1"
      >
        <path
          strokeWidth="1.108"
          d="M500 333.316A166.683 166.683 0 00333.316 500 166.683 166.683 0 00500 666.684 166.683 166.683 0 00666.684 500 166.683 166.683 0 00500 333.316zm0 27.004A139.68 139.68 0 01639.68 500 139.68 139.68 0 01500 639.68 139.68 139.68 0 01360.32 500 139.68 139.68 0 01500 360.32z"
          display="inline"
        ></path>
        <path
          strokeWidth="1.1"
          d="M673.829 831.887H326.17L500 530.807z"
          transform="matrix(1.4248 0 0 .91959 -212.4 80.938)"
        ></path>
        <path
          strokeWidth="1.1"
          d="M673.829 831.887H326.17L500 530.807z"
          transform="matrix(1.4248 0 0 -.91959 -212.4 926.345)"
        ></path>
        <path
          strokeWidth="1.1"
          d="M413.884 500L99.708 681.39V318.61z"
          transform="matrix(1.00087 0 0 .56623 -.222 216.887)"
        ></path>
        <path
          strokeWidth="1.1"
          d="M413.884 500L99.708 681.39V318.61z"
          transform="matrix(-1.00087 0 0 .56623 1008.723 216.887)"
        ></path>
      </g>
    </svg>
  );
}

export default LockIcon;
