import React from 'react';

import styled from 'styled-components';

const colors = ['green', 'yellow', 'orange', 'red', 'magenta', 'purple'];

const columnFromThreat = (threat) => {
  let str = '';
  for (let i = 0; i < threat; i++) {
    if (i === threat - 1) {
      str += `${5 - threat + 1 || 1}fr`;
    } else {
      str += '1fr ';
    }
  }

  return str;
};

function ThreatBar({ threat }) {
  const color = colors[threat - 1];
  const defaultBars = [1, 2, 3, 4, 5];
  const sixThreat = threat > 5;

  if (sixThreat) defaultBars.push(6);

  const bars = defaultBars.map((bar, i) => {
    const subBars = [];

    for (let i = 0; i < bar; i++) {
      subBars.push(<div key={i} />);
    }

    return (
      <Bar key={bar} active={bar <= threat} threat={i + 1} color={color}>
        {subBars}
      </Bar>
    );
  });

  return <Container six={sixThreat}>{bars}</Container>;
}

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ six }) =>
    six ? 'repeat(6, 1fr)' : 'repeat(5, 1fr)'}};
  grid-gap: 8px;
`;

const Bar = styled.div`
  height: 4px;
  display: grid;
  grid-template-columns: ${({ threat }) => columnFromThreat(threat)};
  grid-gap: 1px;

  div {
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background-color: ${({ active, color }) =>
      active ? color : 'transparent'};
    border: ${({ active }) => (active ? 'gray' : '1px solid gray')};
  }
`;

export default ThreatBar;
