import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';

import { SocketContext, socket } from './context/socket';
import { GameDataContext } from './context/gameData';
import { GameStateContext } from './context/gameState';
import { GameSettingsContext, defaultGameSettings } from './context/gameSettings';

import GameBoard from './views/GameBoard/GameBoard';
import Home from './views/Home/Home';
import QuickBuild from './views/QuickBuild/QuickBuild';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import './App.css';
import { X_WING_SOCKET_ID } from './utils';
import { Button, Modal } from '@mui/material';
import { ModalContainer } from './views/GameBoard/PilotCard.styles';
import Sandbox from './views/Sandbox';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const UPDATED_GAME_STATE = 'UPDATED_GAME_STATE';
const ERROR = 'ERROR';

function App() {
  const [gameData, setGameData] = useState({});
  const [gameState, setGameState] = useState({});
  const [gameSettings, setGameSettings] = useState(defaultGameSettings);

  const [errorTitle, setErrorTitle] = useState('Error');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const toggleErrorModal = () => setShowErrorModal(!showErrorModal);
  const closeError = () => {
    setShowErrorModal(false);
    setErrorMessage('');
  };

  useEffect(() => {
    const updateGameState = (gameStateData) => {
      const thisPlayer = gameStateData.isHost
        ? gameStateData.player1
        : gameStateData.player2;
      localStorage.setItem(X_WING_SOCKET_ID, thisPlayer.socket);
      setGameState(gameStateData);
      console.log('Game state update:', gameStateData);
    };

    const handleError = (errorData) => {
      console.error('Error:', errorData);
      if (typeof errorData === 'string') {
        setErrorMessage(errorData);
        setShowErrorModal(true);
        return;
      }

      const { title, message } = errorData;
      setErrorTitle(title);
      setErrorMessage(message);
      setShowErrorModal(true);
    };

    socket.on(UPDATED_GAME_STATE, updateGameState);
    socket.on(ERROR, handleError);
  }, []);

  const formattedErrorMsg = (
    <p>
      {errorMessage.split('\n').map((line, i) => {
        return (
          <span key={i}>
            <span>{line}</span>
            <br />
          </span>
        );
      })}
    </p>
  );

  return (
    <SocketContext.Provider value={socket}>
      <GameDataContext.Provider value={{ gameData, setGameData }}>
        <GameStateContext.Provider value={{ gameState, setGameState }}>
          <GameSettingsContext.Provider
            value={{ gameSettings, setGameSettings }}
          >
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <Router>
                <Routes>
                  <Route path="game-board" element={<GameBoard />} />
                  <Route path="quick-build/*" element={<QuickBuild />} />
                  <Route path="/sandbox" element={<Sandbox />} />
                  <Route path="/" element={<Home />} />
                </Routes>
              </Router>
            </ThemeProvider>
          </GameSettingsContext.Provider>
        </GameStateContext.Provider>
      </GameDataContext.Provider>

      <Modal open={showErrorModal} onClose={toggleErrorModal}>
        <ModalWrapper>
          <h2>{errorTitle}</h2>
          <p>{formattedErrorMsg}</p>
          <Button variant="outlined" onClick={closeError} size="large">
            Okay
          </Button>
        </ModalWrapper>
      </Modal>
    </SocketContext.Provider>
  );
}

const ModalWrapper = styled(ModalContainer)`
  border: 2px solid red;

  h2 {
    color: red;
  }
  p {
    color: yellow;
    font-size: 1.2rem;
    margin: 1.4rem 0;
  }
`;

export default App;
