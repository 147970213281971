import styled from 'styled-components';
import { redAlert, redAlertBackground } from './PilotCard.styles';
import colors from '../../styles/colors';
import { names } from '../../utils';

function CritCard({ crit, removeCritCard }) {
  const hasAction = crit?.effect?.includes('Action: Repair this card.');
  const cleanEffect = crit?.effect?.replace('Action: Repair this card.', '');

  return (
    <Container
      className={names(
        {
          'has-action': hasAction,
        },
        'crit'
      )}
      title={crit.effect}
      onClick={() => removeCritCard(crit.title)}
    >
      <div className="upper">
        <h4>{crit.title}</h4>
        <p>{cleanEffect}</p>
      </div>
      {hasAction && <ActionBar>Action: Click to repair this card</ActionBar>}
    </Container>
  );
}

const Container = styled.div`
  height: fit-content;
  min-width: 150px;
  max-width: 250px;
  background-color: rgba(100, 0, 0, 0.5);
  border: 1px solid red;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  &.has-action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h4 {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    animation-name: ${redAlertBackground};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    padding: 8px;
  }

  p {
    padding: 8px;
  }

  &.crit {
    animation-name: ${redAlert};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
`;

const ActionBar = styled.div`
  width: 100%;
  background-color: ${colors.red};
  color: white;
  padding: 8px;
  font-weight: bold;
  font-size: 0.9rem;
  text-align: center;

  animation-name: ${redAlertBackground};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

export default CritCard;
