import React from 'react';
import styled from 'styled-components';

import FocusIcon from './Icons/Focus';
import EvadeIcon from './Icons/Evade';
import CalculateIcon from './Icons/Calculate';
import ChargeIcon from './Icons/Charge';
import CloakIcon from './Icons/Cloak';
import DepleteIcon from './Icons/Deplete';
import ForceChargeIcon from './Icons/ForceCharge';
import StrainIcon from './Icons/Strain';
import StressIcon from './Icons/Stress';
import BarrelRollIcon from '../icons/BarrelRollIcon';
import LockIcon from '../icons/LockIcon';
import BoostIcon from '../icons/BoostIcon';
import RotateIcon from '../icons/RotateIcon';
import ReloadIcon from '../icons/ReloadIcon';
import CoordinateIcon from '../icons/CoordinateIcon';
import ReinforceIcon from './Icons/Reinforce';
import JamIcon from './Icons/Jam';
import SlamIcon from '../icons/SlamIcon';
import HitIcon from '../icons/HitIcon';
import CritHitIcon from '../icons/CritHitIcon';

import { convertCase } from '../../utils';
import ArcIcon from '../arcs/ArcIcon';
import ShieldStatIcon from '../icons/ShieldStatIcon';
import AgilityStatIcon from '../icons/AgilityStatIcon';
import ForceStatIcon from '../icons/ForceStatIcon';
import ChargeStatIcon from '../icons/ChargeStatIcon';

const icons = {
  agility: AgilityStatIcon,
  calculate: CalculateIcon,
  charge: ChargeIcon,
  chargeStat: ChargeStatIcon,
  cloak: CloakIcon,
  criticalHit: CritHitIcon,
  deplete: DepleteIcon,
  evade: EvadeIcon,
  focus: FocusIcon,
  jam: JamIcon,
  reinforce: ReinforceIcon,
  hit: HitIcon,
  force: ForceChargeIcon,
  forceStat: ForceStatIcon,
  shield: ShieldStatIcon,
  strain: StrainIcon,
  stress: StressIcon,

  // actions
  barrelRoll: BarrelRollIcon,
  boost: BoostIcon,
  coordinate: CoordinateIcon,
  lock: LockIcon,
  reload: ReloadIcon,
  rotate: RotateIcon,
  slam: SlamIcon,
};

const standAloneIcons = [
  'agility',
  'barrelRoll',
  'boost',
  'chargeStat',
  'coordinate',
  'criticalHit',
  'forceStat',
  'hit',
  'lock',
  'reload',
  'rotate',
  'slam',
  'shield',
];

const arcsList = [
  'front',
  'rear',
  'left',
  'right',
  'turret',
  'singleTurret',
  'doubleTurret',
  'forward',
  'bullseye',
];

const Icon = ({ type, color, size, scale, sx, ...rest }) => {
  const casedType = convertCase(type).camelCase;

  if (arcsList.includes(casedType) || casedType.includes('Arc')) {
    return (
      <ArcIcon type={casedType} color={color} size={size} sx={sx} {...rest} />
    );
  }

  let Svg = icons[casedType];

  if (!Svg) {
    return null;
  }

  if (standAloneIcons.includes(casedType)) {
    return (
      <Wrapper style={sx} scale={scale}>
        <Svg
          color={color || 'white'}
          width={size || '100%'}
          height={size || '100%'}
          {...rest}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper style={sx} scale={scale}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size || '100%'}
        height={size || '100%'}
        version="1.1"
        viewBox="0 0 1000 1000"
      >
        <g fillOpacity="1" transform="scale(1.2) translate(-100 -100)">
          <Svg color={color || 'white'} {...rest} />
        </g>
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline;
  svg {
    display: inline;
    transform: scale(${({ scale }) => scale || 1});
  }
`;

export default Icon;
