import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled, { keyframes } from 'styled-components';
import { Box, Button, Tab, Tabs, TextField } from '@mui/material';

import Header from '../../components/Header';

import { SocketContext } from '../../context/socket';
import { useEffect } from 'react';
import { GameStateContext } from '../../context/gameState';
import {
  getDefaultGameCode,
  getDefaultPlayerName,
  X_WING_GAME_CODE,
  X_WING_PLAYER_NAME,
  X_WING_SOCKET_ID,
  DEV_MODE,
  randomIntFromInterval,
  names,
} from '../../utils';
import XWing from '../../images/svgs/XWingSvg';
import BWing from '../../images/svgs/BWingSvg';
import { randomBackground } from '../../images/imageUtils';
import TextCrawl from '../../components/TextCrawl';
// import uss-end-d
import entD from '../../images/backgrounds/uss-ent-d.webp';

const JOIN_GAME = 'JOIN_GAME';
const JOIN_SUCCESS = 'JOIN_SUCCESS';
const JOIN_FAILURE = 'JOIN_FAILURE';
const CREATE_GAME = 'CREATE_GAME';
const CREATE_SUCCESS = 'CREATE_SUCCESS';
const CREATE_FAILURE = 'CREATE_FAILURE';
const NAV_TO_GAME_BOARD = 'NAV_TO_GAME_BOARD';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const { background, opacity, isSilhouette, silhouetteColor, increaseBlur } =
  randomBackground();

const a11yProps = (index) => {
  return {
    id: `login-tab-${index}`,
    'aria-controls': `login-tabpanel-${index}`,
  };
};

function Home() {
  // hooks
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const { setGameState } = useContext(GameStateContext);

  // refs
  const isMounted = useRef(true);

  // state
  const [tabState, setTabState] = useState(0);
  const [playerName, setPlayerName] = useState(getDefaultPlayerName());
  const [gameCode, setGameCode] = useState(getDefaultGameCode());
  const [xWingOpen, setXWingOpen] = useState(false);
  const [xWingRotation, setXWingRotation] = useState(0);
  const [bWingOpen, setBWingOpen] = useState(false);
  const [bWingRotation, setBWingRotation] = useState(0);

  const [moveEnt, setMoveEnt] = useState(false);

  const [hideBox, setHideBox] = useState(false);

  // error state
  const [playerNameErrorMessage, setPlayerNameErrorMessage] = useState('');
  const [gameCodeErrorMessage, setGameCodeErrorMessage] = useState('');

  // functions
  const handleNameInputChange = (e) => {
    const nameValue = e.target.value;
    setPlayerNameErrorMessage('');
    setPlayerName(nameValue);
    localStorage.setItem(X_WING_PLAYER_NAME, nameValue);
  };
  const handleGameInputChange = (e) => {
    setGameCodeErrorMessage('');
    setGameCode(e.target.value.toUpperCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!playerName) return setPlayerNameErrorMessage("Name can't be blank");

    if (tabState === 1) {
      if (!gameCode) return setGameCodeErrorMessage("Code can't be blank");

      return socket.emit(JOIN_GAME, { playerName, gameCode });
    }

    return socket.emit(CREATE_GAME, { playerName });
  };

  const handleTabChange = (_event, newValue) => {
    if (DEV_MODE) {
      setPlayerName('Joe');
      localStorage.setItem(X_WING_PLAYER_NAME, 'Joe');
    }
    setTabState(newValue);
  };

  // BUG: this sometimes runs even after the component is unmounted
  const randomOpen = (state, setState) => {
    if (!isMounted.current) return;

    const seconds = Math.floor(Math.random() * 10) + 4;
    setTimeout(() => {
      setState(!state);
      if (isMounted.current) {
        randomOpen(!state, setState);
      }
    }, seconds * 1000);
  };

  // effects
  useEffect(() => {
    const handleJoinSuccess = (gameData) => {
      localStorage.setItem(X_WING_GAME_CODE, gameData.code);
      localStorage.setItem(X_WING_SOCKET_ID, socket.id);

      setGameState(gameData);

      navigate('/quick-build');
    };

    const handleJoinFailure = (message) => {
      alert(message);
    };

    const handleCreateSuccess = (gameData) => {
      localStorage.setItem(X_WING_GAME_CODE, gameData.code);
      localStorage.setItem(X_WING_SOCKET_ID, socket.id);

      setGameState(gameData);

      navigate('/quick-build');
    };
    const handleCreateFailure = (gameData) => {
      alert(gameData);
    };

    const handleNavToGameBoard = () => {
      navigate('/game-board');
    };

    socket.on(JOIN_SUCCESS, handleJoinSuccess);
    socket.on(JOIN_FAILURE, handleJoinFailure);
    socket.on(CREATE_SUCCESS, handleCreateSuccess);
    socket.on(CREATE_FAILURE, handleCreateFailure);
    socket.on(NAV_TO_GAME_BOARD, handleNavToGameBoard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, navigate, setGameState]);

  useEffect(() => {
    randomOpen(xWingOpen, setXWingOpen);
    randomOpen(bWingOpen, setBWingOpen);
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setXWingRotation(randomIntFromInterval(-20, 20));
  }, [xWingOpen]);
  useEffect(() => {
    setBWingRotation(randomIntFromInterval(-10, 10));
  }, [bWingOpen]);

  useEffect(() => {
    setTimeout(() => {
      setMoveEnt(true);
    }, 50000);
  }, []);

  return (
    <Container>
      <Header />

      <Content>
        <TextCrawl
          title="Star Wars"
          subtitle="X-Wing Miniatures Game"
          episode="Second Edition"
          style={{ position: 'absolute', top: '64px', zIndex: 1 }}
          flipTitle
        >
          <p>
            Star Wars: X-Wing is a tactical ship-to-ship combat game in which
            players take control of powerful Rebel X-wings or nimble Imperial
            TIE fighters, facing them against each other in fast-paced space
            combat. Featuring stunningly detailed and painted miniatures, the
            X-Wing Miniatures Game recreates exciting Star Wars space combat
            throughout its several included scenarios. Select your crew, plan
            your maneuvers, and complete your mission!
          </p>
          <br />
          <br />
          <h2 className="title">Credits</h2>
          <br />
          <ul>
            <li>Dev: Lloyd Grubham</li>
            <li>Product Manager: Joe Winkler</li>
          </ul>
        </TextCrawl>

        <SubContent xWingRotation={xWingRotation} bWingRotation={bWingRotation}>
          <div className="x-wing-wrapper" style={{ zIndex: 1 }}>
            <div className="move-wrapper">
              <XWing
                className="x-wing"
                increaseBlur={increaseBlur}
                width="300"
                height="150"
                outline
                silhouetteOnly={isSilhouette}
                silhouetteColor={silhouetteColor}
                open={xWingOpen}
                onClick={() => setXWingOpen(!xWingOpen)}
              />
            </div>
          </div>
          <BoxUI hideBox={hideBox}>
            <Tabs
              value={tabState}
              onChange={handleTabChange}
              variant="fullWidth"
            >
              <Tab label="Create Game" {...a11yProps(0)} />
              <Tab label="Join Game" {...a11yProps(1)} />
            </Tabs>
            <InputForm
              className={names({ tall: tabState !== 0 }, 'create-join-form')}
              onSubmit={handleSubmit}
            >
              <TabPanel value={tabState} index={0}>
                <TextField
                  label="Player Name"
                  value={playerName}
                  onChange={handleNameInputChange}
                  fullWidth
                  margin="normal"
                  error={!!playerNameErrorMessage}
                  helperText={playerNameErrorMessage}
                />
                <ButtonArea>
                  <Button type="submit" variant="contained">
                    Create
                  </Button>
                </ButtonArea>
              </TabPanel>
              <TabPanel value={tabState} index={1}>
                <TextField
                  label="Player Name"
                  value={playerName}
                  onChange={handleNameInputChange}
                  fullWidth
                  margin="normal"
                  error={!!playerNameErrorMessage}
                  helperText={playerNameErrorMessage}
                />
                <TextField
                  label="Enter Game Code"
                  value={gameCode}
                  onChange={handleGameInputChange}
                  fullWidth
                  error={!!gameCodeErrorMessage}
                  helperText={gameCodeErrorMessage}
                  margin="normal"
                />
                <ButtonArea>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={null}
                    type="submit"
                  >
                    Join Game
                  </Button>
                </ButtonArea>
              </TabPanel>
              {DEV_MODE && (
                <div className="test">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate('/quick-build')}
                  >
                    Test as Lloyd
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate('/quick-build')}
                  >
                    Test as Joe
                  </Button>
                </div>
              )}
            </InputForm>
          </BoxUI>

          <div className="b-wing-wrapper" style={{ zIndex: 1 }}>
            <div className="move-wrapper">
              <BWing
                className="b-wing"
                width="200px"
                height="400px"
                outline
                silhouetteOnly={isSilhouette}
                silhouetteColor={silhouetteColor}
                open={bWingOpen}
                onClick={() => setBWingOpen(!bWingOpen)}
              />
            </div>
          </div>

          <EntD
            className={names({ activated: moveEnt }, 'ent')}
            src={entD}
            alt=""
          />
        </SubContent>
      </Content>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setHideBox(!hideBox)}
        size="small"
        style={{
          position: 'absolute',
          top: '64px',
          right: 0,
          margin: '8px',
          zIndex: 2000,
        }}
      >
        {hideBox ? 'Show Login Box' : 'Hide Login Box'}
      </Button>
    </Container>
  );
}

const dogfight = keyframes`
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  25% {
    transform: translateX(80px) translateY(80px) rotate(4deg);
  }
  50% {
    transform: translateX(150px) translateY(0px) rotate(18deg);
  }
  75% {
    transform: translateX(80px) translateY(-80px) rotate(13deg);
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
`;

const entDAnimation = keyframes`
  0% {
    transform: translateX(0px) translateY(0px);
  }
  1% {
    transform: translateX(-130vw) translateY(0px);
  }
  100% {
    transform: translateX(-130vw) translateY(0px);
  }
`;

const EntD = styled.img`
  position: absolute;
  height: 50px;
  bottom: 100px;
  right: -250px;
  transition: all 0.5s linear;
  animation: ${entDAnimation} 300s linear 50s infinite;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(${background});
  background-size: cover;
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SubContent = styled.div`
  display: flex;
  gap: 150px;
  align-items: center;

  z-index: 10;

  svg {
    cursor: pointer;
  }

  .x-wing-wrapper {
    transition: all 1.5s ease 0.35s;

    .move-wrapper {
      animation: ${dogfight} 10s ease-in-out infinite;
    }
  }
  .b-wing-wrapper {
    transition: all 1.5s ease 1s;

    .move-wrapper {
      animation: ${dogfight} 10s ease-in-out 3s infinite;
    }
  }

  .x-wing-wrapper {
    transform: rotate(${(props) => props.xWingRotation}deg);
  }
  .b-wing-wrapper {
    transform: rotate(${(props) => props.bWingRotation}deg);
  }
`;

const BoxUI = styled.div`
  border: 1px solid #555;
  border-radius: 8px;
  padding: 48px;
  background-color: rgba(0, 0, 0, ${opacity});
  display: grid;
  grid-template-columns: 1fr;

  opacity: ${(props) => (props.hideBox ? 0 : 1)};

  z-index: 100px;
`;

const InputForm = styled.form`
  width: 300px;
  height: 175px;
  transition: height 0.25s ease;
  overflow: hidden;

  &.tall {
    height: 250px;
  }
`;

const ButtonArea = styled.div`
  width: 100%;
  margin-top: 16px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr;
`;

export default Home;
