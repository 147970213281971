import React from 'react';

function HitIcon({ color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...rest}
    >
      <g>
        <path
          fill={color || '#000'}
          fillOpacity="1"
          stroke="none"
          strokeWidth="0.443"
          d="M185.994 139.073l249.761 207.894 62.08-330.609 59.191 327 251.205-204.285-153.033 285.132 256.98 20.212-233.88 98.894 248.317 201.397-311.118-93.84 21.655 237.49-144.37-192.736-201.398 288.02 83.013-329.887L73.385 759.867l246.152-216.556-212.947-96.728 231.715-20.212z"
          opacity="1"
        ></path>
      </g>
    </svg>
  );
}

export default HitIcon;
