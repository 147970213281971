import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { names } from '../../utils';

function ShipDock({ clickFunc, docked, factionColor, small }) {
  return (
    <Container className={names({ docked, small })} factionColor={factionColor}>
      {clickFunc && (
        <Button
          onClick={clickFunc}
          variant="outlined"
          size="small"
          sx={{ width: '100px' }}
          endIcon={
            docked ? (
              <SendIcon />
            ) : (
              <SendIcon sx={{ transform: 'rotate(180deg)' }} />
            )
          }
        >
          {docked ? 'Deploy' : 'Dock'}
        </Button>
      )}
      <p>{docked ? 'Docked' : 'Deployed'}</p>
    </Container>
  );
}

const transitionTime = '0.5s';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  grid-column: span 2;
  text-align: center;
  padding: 16px;
  color: #666;
  font-weight: bold;
  font-size: 1.2rem;

  margin: 0 45px;
  transition: all ${transitionTime};

  &.docked {
    border-left: 5px solid ${({ factionColor }) => factionColor};
    border-right: 5px solid ${({ factionColor }) => factionColor};
    color: ${({ factionColor }) => factionColor};
    padding: 8px;
  }

  &.small {
    padding: 8px;
    font-size: 1rem;
  }

  &.small.docked {
    padding: 0;
  }

  .MuiSvgIcon-root {
    transition: all ${transitionTime};
  }
`;

export default ShipDock;
