import styled, { keyframes } from 'styled-components';
import ActionIcon from '../../components/ActionIcon';
import ConstructionIcon from '@mui/icons-material/Construction';

import colors, { black } from '../../styles/colors';
import { testActionForLinks } from '../../components/actionUtils';
import CritHitIcon from '../../images/icons/CritHitIcon';
import { names } from '../../utils';
import { Tooltip } from '@mui/material';

function ActionBar({ actions, factionColor, repairableCritCardCount }) {
  const hasLinkedActions = actions.some(testActionForLinks);
  const singleActionSize = hasLinkedActions ? '40px' : '32px';

  return (
    <Container className="actions" color={factionColor}>
      {actions.map((action, i) => (
        <ActionWrapper key={i}>
          <ActionIcon
            action={action}
            size={testActionForLinks(action) ? null : singleActionSize}
          />
        </ActionWrapper>
      ))}
      {repairableCritCardCount > 0 && (
        <ActionWrapper>
          <Tooltip
            title={
              repairableCritCardCount > 1
                ? `${repairableCritCardCount} Critical Damage cards have repair actions.`
                : '1 Critical Damage card has a repair action.'
            }
            placement="right"
            arrow
            followCursor
          >
            <CritAction className={names({ horizontal: hasLinkedActions })}>
              <ConstructionIcon />
              <CritHitIcon
                className="crit-icon"
                width="30px"
                height="30px"
                color={colors.red}
              />
            </CritAction>
          </Tooltip>
        </ActionWrapper>
      )}
    </Container>
  );
}

const redAlertFill = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Container = styled.div`
  font-size: 12px;
  border: 2px solid ${({ color }) => color};
  border-top: 1px solid ${({ color }) => color};
  border-bottom: 1px solid ${({ color }) => color};
  background-color: ${({ color }) => color};
  border-radius: 4px 0 0 4px;
  border-right: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 2px;
  grid-row: 1 / span 3;
  grid-column: 2;
  justify-items: center;
  margin-top: -1px;
  z-index: 10;

  div:first-child {
    border-radius: 4px 0 0 0;
  }
  div:last-child {
    border-radius: 0 0 0 4px;
  }
`;

const ActionWrapper = styled.div`
  background-color: ${black};
  /* border-radius: 4px; */
  overflow: hidden;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
`;

const CritAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: help;

  &.horizontal {
    flex-direction: row;
    justify-content: center;
    margin-left: 4px;
  }

  .crit-icon {
    animation-name: ${redAlertFill};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
`;

export default ActionBar;
