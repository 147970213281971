import React from 'react';

function ForceChargeIcon({ color, ...rest }) {
  return (
    <path
      fill={color || '#fff'}
      strokeWidth="2.328"
      d="M499.176 202.959c-60.272 0-109.132 48.86-109.131 109.133.07 6.337.69 12.657 1.857 18.887h-36.31l-60.399-35.856-.214-.174.043.073-.073-.043.174.214 35.856 60.399v36.31a109.128 109.128 0 00-18.887-1.857c-60.272 0-109.133 48.859-109.133 109.13.002.276.006.55.01.825-.004.275-.008.55-.01.824 0 60.272 48.86 109.132 109.133 109.131a109.13 109.13 0 0018.887-1.857v36.31l-35.856 60.399-.174.214.073-.043-.043.073.214-.174 60.399-35.856h36.31a109.128 109.128 0 00-1.857 18.887c0 60.272 48.859 109.133 109.13 109.133.276-.002.55-.006.825-.01.275.004.55.008.824.01 60.272 0 109.132-48.86 109.131-109.133a109.128 109.128 0 00-1.857-18.887h36.31l60.399 35.856.214.174-.043-.073.073.043-.174-.214-35.856-60.399v-36.31a109.13 109.13 0 0018.887 1.857c60.272 0 109.133-48.859 109.133-109.13-.002-.276-.006-.55-.014-.825.004-.275.007-.55.014-.824 0-60.272-48.86-109.132-109.133-109.131-6.337.07-12.657.69-18.887 1.857v-36.31l35.856-60.399.174-.214-.073.043.043-.073-.214.174-60.399 35.856h-36.31a109.128 109.128 0 001.857-18.887c0-60.272-48.859-109.133-109.13-109.133-.276.002-.55.006-.825.01-.275-.004-.55-.008-.824-.01zm-162.69 133.527L446 404l-10-46 64 77.285L564 358l-10 46 109.514-67.514L596 446l46-10-77.285 64L642 564l-46-10 67.514 109.514L554 596l10 46-64-77.285L436 642l10-46-109.514 67.514L404 554l-46 10 77.285-64L358 436l46 10zm163.541 120.727a42.973 42.973 0 00-42.972 42.973 42.973 42.973 0 0042.972 42.974A42.973 42.973 0 00543 500.186a42.973 42.973 0 00-42.973-42.973z"
      opacity="1"
      {...rest}
    ></path>
  );
}

export default ForceChargeIcon;
