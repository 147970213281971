import React from 'react';

function XOverX({ top, bottom }) {
  return (
    <>
      {top}
      <span>/</span>
      {bottom}
    </>
  );
}

export default XOverX;
