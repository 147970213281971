import React from 'react';
import styled from 'styled-components';
import { Tooltip, Zoom, capitalize } from '@mui/material';

import Token from '../../images/tokens/Token';

const sizeMap = {
  small: '30px',
  large: '60px',
};

const fontSizeMap = {
  small: '12px',
  large: '20px',
};

function TokenIcon({
  colorOverride,
  count,
  placement,
  size,
  suppressToolTip,
  tokenName,
  toolTipText,
  ...rest
}) {
  if (suppressToolTip) {
    return (
      <TokenWrapper className="token-wrapper" size={size}>
        <Token type={tokenName} colorOverride={colorOverride} {...rest} />
        {count > 1 && <TokenCount size={size}>x{count}</TokenCount>}
      </TokenWrapper>
    );
  }

  return (
    <Tooltip
      title={toolTipText || capitalize(tokenName)}
      placement={placement || 'right'}
      TransitionComponent={Zoom}
      arrow
    >
      <TokenWrapper className="token-wrapper" size={size}>
        <Token type={tokenName} colorOverride={colorOverride} {...rest} />
        {count > 1 && <TokenCount size={size}>x{count}</TokenCount>}
      </TokenWrapper>
    </Tooltip>
  );
}

const TokenWrapper = styled.div`
  width: ${({ size }) => sizeMap[size] || '40px'};
  height: ${({ size }) => sizeMap[size] || '40px'};
  margin-bottom: 4px;
  position: relative;

  svg,
  img {
    height: 100%;
    width: 100%;
  }
`;

const TokenCount = styled.p`
  position: absolute;
  bottom: -6px;
  right: -2px;
  color: white;
  font-weight: bold;
  font-size: ${({ size }) => fontSizeMap[size] || '16px'};
`;

export default TokenIcon;
