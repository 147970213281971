import styled, { css } from 'styled-components';

const openCss = css`
  transform: rotate(0deg);

  .cockpit-rotation {
    transform: rotate(0deg);
  }
  .b-wing-wing-set-1 {
    transform: rotate(0deg);
  }
  .b-wing-wing-set-2 {
    transform: rotate(0deg);
  }
`;

const openCssHorizontal = css`
  .cockpit-rotation {
    transform: rotate(0deg);
  }
  .b-wing-wing-set-1 {
    transform: rotate(0deg);
  }
  .b-wing-wing-set-2 {
    transform: rotate(0deg);
  }
`;

const hoverCss = css`
  &:hover {
    ${openCss}
  }
`;

const getOpenCss = (open, horizontal) => {
  if (horizontal) return openCssHorizontal;
  return openCss;
};

export const SvgWrapper = styled.div`
  position: relative;
`;

const mainRotationTime = 2.1;

export const SvgContainer = styled.svg`
  opacity: ${({ silhouetteOnly }) => (silhouetteOnly ? 0 : 1)};
  transition: transform ${mainRotationTime}s ease;
  transform: rotate(-90deg);

  .cockpit-rotation {
    transform: rotate(90deg);
    transform-origin: 50% 9.1%;
    transition: transform ${mainRotationTime}s ease;
  }

  .b-wing-wing-set-1 {
    transform: rotate(90deg);
  }
  .b-wing-wing-set-2 {
    transform: rotate(-90deg);
  }

  .b-wing-wing-set-1,
  .b-wing-wing-set-2 {
    transition: transform 1.3s ease;
  }
  .b-wing-wing-set-1 {
    transform-origin: 57% 37%;
  }
  .b-wing-wing-set-2 {
    transform-origin: 43% 37%;
  }

  ${({ open, forceHorizontal }) => open && getOpenCss(open, forceHorizontal)}

  ${({ animateOnHover }) => animateOnHover && hoverCss}
`;
