import React from 'react';
import styled, { keyframes } from 'styled-components';
import { hullYellow, shieldBlue, white } from '../../styles/colors';
import { createArrayWithLengthN, names } from '../../utils';
import { Tooltip } from '@mui/material';
import StatBlock from './StatBlock';

function HullShieldBars({
  crit,
  factionColor,
  hull = 0,
  hullClick = () => {},
  maxHull = 1,
  maxShield = 0,
  shield = 0,
  shieldClick = () => {},
}) {
  const hullBars = (onClick) => {
    return createArrayWithLengthN(maxHull).map((_, i) => {
      const damage = maxHull - hull;
      return (
        <Bar
          key={i}
          className={names({
            hull: i > damage - 1,
            bar: i <= damage - 1,
          })}
          onClick={() => onClick(i > damage - 1 ? -1 : 1)}
        >
          <p>{maxHull - i}</p>
        </Bar>
      );
    });
  };

  const shieldBars = (onClick) => {
    return createArrayWithLengthN(maxShield).map((_, i) => {
      const damage = maxShield - shield;
      return (
        <Bar
          key={i}
          className={names({
            shield: i > damage - 1,
            bar: i <= damage - 1,
          })}
          onClick={() => onClick(i > damage - 1 ? -1 : 1)}
        >
          <p>{maxShield - i}</p>
        </Bar>
      );
    });
  };

  const hullSet = hullBars(hullClick);
  const shieldSet = shieldBars(shieldClick);

  return (
    <Container
      className={names({ crit })}
      factionColor={factionColor}
      hasShield={Boolean(maxShield)}
    >
      <ColumnWrapper>
        {maxShield > 0 ? (
          <LeftCol className="hull-column" max={maxHull}>
            {hullSet}
          </LeftCol>
        ) : (
          <Column className="hull-column" max={maxHull}>
            {hullSet}
          </Column>
        )}
        <div className="num hull">
          <Tooltip title="Hull" placement="bottom" arrow>
            <div className="stat-wrapper">
              <StatBlock type="hull" size="26px" inverted />
            </div>
          </Tooltip>
        </div>
      </ColumnWrapper>
      {maxShield > 0 && (
        <ColumnWrapper>
          <RightCol className="shield" max={maxShield}>
            {shieldSet}
          </RightCol>
          <div className="num shield">
            <Tooltip title="Shield" placement="bottom" arrow>
              <div className="stat-wrapper">
                <StatBlock type="shield" size="26px" inverted />
              </div>
            </Tooltip>
          </div>
        </ColumnWrapper>
      )}
    </Container>
  );
}

const gap = '5px';

const redAlert = keyframes`
  0% {
    border-color: black;
  }
  50% {
    border-color: red;
  }
  100% {
    border-color: black;
    }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ hasShield }) => (hasShield ? '1fr 1fr' : '1fr')};
  grid-gap: ${gap};
  padding: 6px;
  padding-bottom: 0;
  width: ${({ hasShield }) => (hasShield ? '75px' : '40px')};
  border: 3px solid ${({ factionColor }) => factionColor || white};
  border-left: 0;
  border-radius: 0 8px 8px 0;
  margin-right: 8px;
  background-color: #222;
  text-align: center;

  &.crit {
    animation-name: ${redAlert};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .value {
    font-weight: bold;
    font-size: 20px;
  }
  .total {
    font-size: 14px;
  }
  .num {
    &.hull p {
      color: ${hullYellow};
    }
    &.shield p {
      color: ${shieldBlue};
    }
  }

  .label {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
  }

  .stat-wrapper {
    padding-top: 4px;
    padding-bottom: 8px;
  }
`;
const Column = styled.div`
  display: grid;
  grid-template-rows: ${({ max }) => `repeat(${max}, 1fr)`};
  grid-gap: ${gap};
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  /* margin-bottom: 4px; */
`;

const LeftCol = styled(Column)`
  border-radius: 4px 0 0 4px;
`;
const RightCol = styled(Column)`
  border-radius: 0 4px 4px 0;
`;

const Bar = styled.div`
  background-color: rgba(255, 0, 0, 0.1);
  transition: background-color 0.35s ease;
  color: rgba(0, 0, 0, 0.3);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-weight: black;
  display: grid;
  place-items: center;
  cursor: pointer;

  &.hull {
    background-color: ${hullYellow};
  }

  &.shield {
    background-color: ${shieldBlue};
  }

  &:hover p {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export default HullShieldBars;
