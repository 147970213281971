import React from 'react';

function RotateIcon({ color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...rest}
    >
      <g>
        <path
          fill={color || 'white'}
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="1.1"
          d="M463.88 80.242l-298.08 56.33 83.997 84.198a374.453 374.453 0 00-115.69 268.632A374.453 374.453 0 00508.56 863.855a374.453 374.453 0 00374.453-374.453 374.453 374.453 0 00-195.377-328.787l-20.268 95.533a282.281 282.281 0 01123.475 233.254 282.281 282.281 0 01-282.283 282.282 282.281 282.281 0 01-282.282-282.282 282.281 282.281 0 0188.07-203.93l91.282 91.5 58.252-296.73zm45.972 271.305l-100.233 283.08h200.467l-100.234-283.08z"
          opacity="1"
        ></path>
      </g>
    </svg>
  );
}

export default RotateIcon;
