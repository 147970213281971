import React from 'react';

function DisarmIcon({ color, ...rest }) {
  return (
    <>
      <path
        className="fire-icon"
        fill={color || '#fff'}
        fillOpacity="1"
        d="M347.85 353.623l101.73 131.9L479.105 456 347.85 353.623zm128.109 46.648l21.045 37.83 9.049-9.05-30.094-28.78zm-135.434 11.346l89.538 93.424 13.294-13.293-102.832-80.13zm16.245 68.13l52.798 45.79 12.957-12.959-65.755-32.832zm228.501 2.37l-31.115 31.115c13.854 15.804 35.025 45.329 41.5 49.202 8.081 22.943 29.62 39.192 14.565 54.816-7.353 7.63-33.325-10.037-33.325-10.037-24.891-13.813-38.513-22.01-60.908-39.1-3.317-3.677-5.722-6.722-7.44-9.273l-43.333 43.334c23.223 12.77 49.262 26.52 69.34 35.562 27.99 12.605 90.966 31.628 116.98 8.754 29.188-25.664-.52-118.185-.52-118.185l-6.763 13.392-14.717-54.58-9.484 47.684-34.78-52.684zm-39.214 39.215l-26.496 26.496c15.123 11.517 37.629 27.229 43.632 33.233 15.73 15.728 17.718 12.157 19.887 10.35 2.17-1.809 1.987-11.04-1.086-18.634-1.65-4.077-31.156-39.58-31.156-39.58-1.918-4.544-3.495-8.465-4.781-11.865z"
        opacity="1"
        {...rest}
      ></path>
      <path
        className="ring-and-cross"
        fill={color || '#fff'}
        d="M500 214.42A285.58 285.58 0 00214.42 500 285.58 285.58 0 00500 785.58 285.58 285.58 0 00785.58 500 285.58 285.58 0 00500 214.42zm0 49.953a235.626 235.626 0 01146.105 50.867L315.58 645.766A235.626 235.626 0 01264.373 500 235.626 235.626 0 01500 264.373zm184.76 89.522A235.626 235.626 0 01735.627 500 235.626 235.626 0 01500 735.627a235.626 235.626 0 01-145.766-51.207z"
        opacity="1"
        {...rest}
      ></path>
    </>
  );
}

export default DisarmIcon;
