import React from 'react';

function CloakIcon({ color, ...rest }) {
  return (
    <path
      fill={color || '#fff'}
      d="m 499.37174,305.72196 c -106.89285,0.0746 -193.75752,86.27405 -194.65396,193.16317 l 41.70157,-23.85278 c 12.43821,-74.8431 77.08302,-129.77664 152.95239,-129.97506 19.04218,0.1167 37.90091,3.73098 55.63704,10.66272 l 41.68799,-23.84502 c -29.58596,-17.10385 -63.15092,-26.12337 -97.32503,-26.15303 z m 188.35301,6.66202 -68.03901,38.93351 29.10548,29.10548 z m -110.06283,62.98037 -39.46344,22.57938 63.96649,63.9665 22.57938,-39.46344 z m 90.57372,28.20288 -23.9207,41.79087 c 6.82568,17.59619 10.39018,36.28822 10.51906,55.16148 -0.18727,75.74625 -54.93788,140.33201 -129.63147,152.91741 l -23.87413,41.70746 c 106.75174,-1.07191 192.752,-87.8678 192.84092,-194.62487 -0.0266,-34.02785 -8.9695,-67.45471 -25.93368,-96.95235 z m -167.15764,15.61844 -45.22864,25.88126 99.19242,99.19242 25.88126,-45.22863 z m -75.71818,43.32826 -58.14693,33.2712 137.1107,137.11069 33.27119,-58.14692 z m -84.30967,48.24324 -110.61993,63.29679 173.82938,21.79514 21.79515,173.82937 63.2968,-110.61992 z"
      {...rest}
    ></path>
  );
}

export default CloakIcon;
