import React from 'react';

function CoordinateIcon({ color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...rest}
    >
      <g
        fill={color || 'white'}
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
      >
        <path d="M499.851 470.243l45.283 247.314L921.33 52.249 268.213 437.152z"></path>
        <path d="M151.25 361.613l.87 120.24 232.241-282.266L44.876 326.863z"></path>
        <path d="M614.169 831.977l39.514 113.566L782.75 603.561 502.27 833.303z"></path>
      </g>
    </svg>
  );
}

export default CoordinateIcon;
