import styled from 'styled-components';

import { Link } from 'react-router-dom';

import colors from '../../styles/colors';

export const QuickBuildWrapper = styled.main`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 75px;
`;

export const LowerArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const FactionButtonContainer = styled.div`
  width: ${({ collapseSide }) => (collapseSide ? '85px' : '200px')};
  align-self: flex-start;
  display: grid;
  grid-template-columns: repeat(1, auto);
  gap: 8px;
  padding: 0 16px;
  transition: width 0.25s ease;

  h3 {
    text-align: center;
  }

  svg {
    transition: transform 0.5s ease;
  }
`;

const selectThickness = '2px';
const selectFuzzy = '2px';
const hoverThickness = '2px';
const hoverFuzzy = '2px';

export const FactionButton = styled.button`
  max-width: 170px;
  max-height: 66px;
  width: 168px;
  background-color: ${({ styleprops }) => styleprops.background};
  color: ${({ styleprops }) => styleprops.color};
  display: grid;
  gap: 14px;
  grid-template-columns: 32px auto;
  align-items: center;
  justify-items: start;
  padding: 6px;
  font-weight: bold;
  text-align: left;
  border-radius: 8px;
  transition: all 0.25s ease;
  overflow: hidden;
  cursor: pointer;

  &.collapsed {
    width: 45px;
    grid-template-columns: 32px;
  }

  &.selected {
    box-shadow: -${selectThickness} -${selectThickness} ${selectFuzzy}
        ${selectFuzzy} ${colors.lime},
      ${selectThickness} ${selectThickness} ${selectFuzzy} ${selectFuzzy}
        ${colors.lime},
      -${selectThickness} ${selectThickness} ${selectFuzzy} ${selectFuzzy} ${colors.lime},
      ${selectThickness} -${selectThickness} ${selectFuzzy} ${selectFuzzy} ${colors.lime};
  }

  p {
    justify-self: start;
    font-size: 18px;
    text-shadow: 1px 1px 1px black;
  }

  &:hover {
    box-shadow: -${hoverThickness} -${hoverThickness} ${hoverFuzzy}
        ${hoverFuzzy} ${colors.hullYellow},
      ${hoverThickness} ${hoverThickness} ${hoverFuzzy} ${hoverFuzzy}
        ${colors.hullYellow},
      -${hoverThickness} ${hoverThickness} ${hoverFuzzy} ${hoverFuzzy} ${colors.hullYellow},
      ${hoverThickness} -${hoverThickness} ${hoverFuzzy} ${hoverFuzzy} ${colors.hullYellow};
  }
  &.selected:hover {
    box-shadow: -${selectThickness} -${selectThickness} ${selectFuzzy}
        ${selectFuzzy} ${colors.lime},
      ${selectThickness} ${selectThickness} ${selectFuzzy} ${selectFuzzy}
        ${colors.lime},
      -${selectThickness} ${selectThickness} ${selectFuzzy} ${selectFuzzy} ${colors.lime},
      ${selectThickness} -${selectThickness} ${selectFuzzy} ${selectFuzzy} ${colors.lime};
  }

  &.not-available {
    border: 2px solid ${colors.weaponRed};
    background-color: #333;
    cursor: not-allowed;
    img,
    p {
      opacity: 0.3;
    }
    border: 1px dashed ${colors.weaponRed};
    box-shadow: none;
  }

  &:disabled {
    background-color: #333;
    cursor: not-allowed;
    img,
    p {
      opacity: 0.3;
    }
    border: 1px dashed ${colors.weaponRed};
    box-shadow: none;
  }
`;

export const PreviewBanner = styled.div`
  width: 100%;
  font-size: 34px;
  color: ${colors.hullYellow};
  text-align: center;
  h3 {
    margin-bottom: 0px;
  }
  p {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

export const DataBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  gap: 12px;
  place-items: center;

  .ship-wrapper {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  .ship-name {
    border: 1px solid #888;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 24px;
  }
  .select-text {
    font-size: 24px;
    padding: 8px;
    border-radius: 8px;
  }
`;

export const Faction = styled(Link)`
  place-self: start;
  color: ${({ factiondata }) => factiondata.color || 'white'};
  background-color: ${({ factiondata }) => factiondata.background || 'black'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  text-align: left;
  border-radius: 8px;
  text-shadow: 1px 1px 1px black;
  font-size: 24px;

  img {
    margin-right: 14px;
  }
`;

export const SelectionArea = styled.div`
  padding: 0 12px 12px 0;
`;

export const RouteWrapper = styled.div`
  width: 100%;
`;

export const SelectionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`;

export const ModalWrapper = styled.div`
  position: relative;
  max-width: 800px;
  padding: 36px 48px;
  padding-top: 24px;
  background-color: ${colors.navy};
  border: 5px solid ${colors.weaponRed};
  border-radius: 12px;
  font-size: 24px;
  box-shadow: inset 0 0 12px black;

  h1 {
    text-align: center;
    margin-bottom: 24px;
  }
  li {
    color: ${colors.hullYellow};
  }
  p {
    width: fit-content;
  }
`;

export const ConfirmModalWrapper = styled(ModalWrapper)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: yellow;
    margin-bottom: 20px;
  }
  p {
    width: 100%;
  }

  .confirmation-actions {
    margin-top: 30px;
    max-width: 250px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 24px;
    width: 100%;
  }
`;
