import React from 'react';

function DiamondBase({ color, children }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
    >
      <g display="inline">
        <path
          fill="#000"
          d="M454.904 1.826L1.826 454.904v90.192l453.078 453.078h90.192l453.078-453.078v-90.192L545.096 1.826z"
        ></path>
        <path
          fill={color || 'white'}
          d="M499.883 133.893c-202.13 0-365.99 163.86-365.99 365.99 0 66.931 18.013 129.634 49.386 183.613l-21.525 21.527 6.031 6.032 19.975-19.973c4.555 7.42 9.392 14.645 14.449 21.703l-16.346 16.348 6.03 6.03 22.324-22.327c-6.929-9.27-13.345-18.928-19.342-28.87l28.121-28.124-.187-.153c.153.016.522-.084.562-.013-25.985-46.02-40.812-99.175-40.812-155.793 0-175.253 142.07-317.324 317.324-317.324 56.425 0 109.41 14.728 155.322 40.548l28.107-28.132c10.467 6.308 20.619 13.088 30.344 20.404l22.489-22.504-6.047-6.047-16.188 16.188a366.48 366.48 0 00-23.193-15.45l20.054-20.064-6.04-6.041-21.598 21.598c-53.896-31.241-116.471-49.167-183.25-49.166zm0 10.13c63.938 0 123.943 16.937 175.814 46.471l-21.717 21.717c-45.905-24.642-98.35-38.684-154.097-38.684-180.242 0-326.36 146.114-326.36 326.356 0 55.902 14.132 108.477 38.9 154.472l-22.032 22.034c-29.629-52.029-46.372-112.313-46.371-176.506 0-196.536 159.327-355.86 355.863-355.86zm310.422 123.012l-23.016 22.897.03.017c-.008.01-.038.014-.046.022 6.832 9.337 13.164 19.059 19.077 29.056l-28.182 28.207.014.008-.014.018c24.89 45.285 39.045 97.3 39.045 152.625 0 175.253-142.071 317.323-317.324 317.324-55.202 0-107.352-13.822-152.567-38.61l-28.76 28.758c-10.423-6.335-20.53-13.138-30.214-20.478.035.022-.06.235-.041.287l-.143-.146-22.207 22.207 6.031 6.03 16.194-16.19a351.655 351.655 0 0023.306 15.365l-20.164 20.162 6.035 6.035 21.707-21.71c52.887 30.154 114.29 46.956 180.823 46.956 202.13 0 365.99-163.86 365.99-365.99 0-65.719-17.365-127.363-47.692-180.674l22.147-22.147-6.018-6.017-20.54 20.553a366.39 366.39 0 00-14.284-21.907l16.735-16.736-5.922-5.922zm.449 59.604c28.636 51.274 44.988 110.342 44.988 173.244 0 196.536-156.108 359.078-352.644 359.078-64.434 0-124.845-17.163-176.977-47.11l22.725-22.722c45.176 23.639 96.516 37.11 151.037 37.11 180.241-.001 326.355-146.115 326.355-326.356 0-54.677-13.53-106.164-37.297-151.434l21.813-21.81z"
        ></path>
        {children}
      </g>
    </svg>
  );
}

export default DiamondBase;
