import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  agilityGreen,
  hullYellow,
  shieldBlue,
  weaponRed,
} from '../../styles/colors';
import getSilhouette from '../../images/silhouettes';
import ArcIcon from '../../images/arcs/ArcIcon';
import StatBlock from '../GameBoard/StatBlock';

function ShipCard({ ship, path }) {
  const silhouette = getSilhouette(ship.name);
  const hasSilhouette = !!silhouette;

  const firingArcs = ship?.wpn?.map ? ship.wpn : [ship.wpn];
  const noShield =
    ship.shd === undefined || ship.shd === null || ship.shd === 0;

  return (
    <Container to={path}>
      <Title hasSilhouette={hasSilhouette}>
        {silhouette && (
          <img className="silhouette" src={silhouette} alt="Ship Silhouette" />
        )}
        <h3>{ship.name}</h3>
      </Title>
      <ShipImageContainer>
        {ship.imgUrl ? (
          <img src={ship.imgUrl} alt="Ship" />
        ) : (
          <div className="image-placeholder">
            No Image
            <br />
            Available
          </div>
        )}
      </ShipImageContainer>

      <StatColumn>
        <div className="stat wpn">
          <span className="damage-row">
            {firingArcs.map((wpn, i) => (
              <span key={wpn.arc + i}>
                <ArcIcon type={wpn.arc} showRing size="30px" />
                <p className="stat-num">{wpn?.damage ?? 1}</p>
              </span>
            ))}
          </span>
        </div>
        <div className="stat agl">
          <StatBlock type="agility" number={ship.agl} size="30px" />
        </div>
        <div className="stat hull">
          <StatBlock type="hull" number={ship.hull} size="30px" />
        </div>
        <div className="stat shd">
          <StatBlock
            type="shield"
            number={ship.shd || '0'}
            size="30px"
            sx={{ opacity: noShield ? 0.35 : 1 }}
          />
        </div>
      </StatColumn>
    </Container>
  );
}

const Container = styled(Link)`
  color: white;
  border: 1px solid white;
  border-radius: 4px;
  text-align: center;
  padding: 8px 0;
  padding-bottom: 0;
  display: grid;
  gap: 12px;
  grid-template-rows: 50px 150px auto;

  &:hover {
    color: gold;
    font-weight: bold;
    border: 1px solid gold;
  }
`;

const Title = styled.div`
  font-weight: bold;
  padding: 0 12px;
  max-height: 90px;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.hasSilhouette ? '45px 1fr' : '1fr'};
  align-items: center;

  .silhouette {
    height: 45px;
    float: right;
  }
`;

const StatColumn = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .stat {
    min-height: 72px;
    font-weight: bold;
    .stat-header {
      margin-bottom: 2px;
      font-size: 14px;
    }
    p {
      font-size: 24px;
      line-height: 24px;
      font-family: Teko, roboto, sans-serif;
    }
    .damage-row {
      display: flex;
      align-items: center;
      gap: 6px;

      .stat-num {
        margin-top: -3px;
      }
    }
  }

  .wpn {
    color: ${weaponRed};
  }
  .agl {
    color: ${agilityGreen};
  }
  .hull {
    color: ${hullYellow};
  }
  .shd {
    color: ${shieldBlue};
  }
`;

const ShipImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    max-height: 100%;
    max-width: 150px;
  }
  .image-placeholder {
    color: #666;
  }
`;
export default ShipCard;
