import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';

import {
  CUSTOM,
  gameBoardBGs,
  gameBoardOptions,
  getRandomBackground,
} from './gameBoardBGs';
import {
  X_WING_BACKGROUND,
  X_WING_CUSTOM_BACKGROUND,
  names,
} from '../../utils';
import { IconButton, Switch, TextField, Tooltip } from '@mui/material';
import { GameSettingsContext } from '../../context/gameSettings';
import AgilityStatIcon from '../../images/icons/AgilityStatIcon';
import ForceStatIcon from '../../images/icons/ForceStatIcon';
import ArcIcon from '../../images/arcs/ArcIcon';
import HullStatIcon from '../../images/icons/HullStatIcon';
import ShieldStatIcon from '../../images/icons/ShieldStatIcon';
import ChargeStatIcon from '../../images/icons/ChargeStatIcon';

const options = [...gameBoardOptions, ...gameBoardBGs];

function SettingsModal({
  background,
  customBackground,
  setBackground,
  setCustomBackground,
  setShowSettingsModal,
}) {
  const { gameSettings, setGameSettings } = useContext(GameSettingsContext);

  const selectBG = (bgKey) => {
    localStorage.setItem(X_WING_BACKGROUND, bgKey);
    if (bgKey === 'random') {
      const randomBg = getRandomBackground();
      localStorage.setItem(X_WING_BACKGROUND, randomBg.key);
    }

    setBackground(bgKey);
  };

  const handleCustomChange = (e) => {
    const customBg = e.target.value;
    localStorage.setItem(X_WING_CUSTOM_BACKGROUND, customBg);
    setCustomBackground(customBg);
  };

  const handleGameSettingsChange = (e, settingName, value) => {
    console.log('e:', e);
    console.log('settingName:', settingName);
    console.log('value:', value);

    setGameSettings((prevSettings) => {
      return {
        ...prevSettings,
        [settingName]: value,
      };
    });
  };

  useEffect(() => {
    const backgroundSetting = localStorage.getItem(X_WING_BACKGROUND);
    if (!backgroundSetting) {
      setBackground('random');
    }
    const customBackground = localStorage.getItem(X_WING_CUSTOM_BACKGROUND);
    if (customBackground) {
      setCustomBackground(customBackground);
    }
  }, [setBackground, setCustomBackground]);

  return (
    <Container>
      <h1>Settings</h1>
      <h2>Animation Settings</h2>

      <div className="settings-group">
        <Tooltip title="May increase CPU loads on some systems.">
          <label htmlFor="heavy-animations-switch">
            Enable Stat
            <br />
            Ring Animation
          </label>
        </Tooltip>
        <Tooltip title="May increase CPU loads on some systems.">
          <Switch
            id="heavy-animations-switch"
            checked={gameSettings.showAnimations}
            onChange={(e) =>
              handleGameSettingsChange(e, 'showAnimations', e.target.checked)
            }
          />
        </Tooltip>
        <div className="stat-row">
          <ArcIcon type="Front" showRing width="60px" height="60px" />
          <AgilityStatIcon width="60px" height="60px" />
          <ForceStatIcon width="60px" height="60px" />
          <HullStatIcon width="60px" height="60px" />
          <ShieldStatIcon width="60px" height="60px" />
          <ChargeStatIcon width="60px" height="60px" />
        </div>
      </div>

      <h2>Game Board Background Image</h2>
      <BackgroundsContainer>
        {options.map((option) => (
          <BackgroundSelect
            key={option.key}
            className={names({ selected: option.key === background })}
            onClick={() => selectBG(option.key)}
          >
            {option.label ? (
              <OptionContainer optionColor={option.color}>
                <p>{option.label}</p>
              </OptionContainer>
            ) : (
              <img src={option.src} alt={option.key} />
            )}
            {option.key === CUSTOM && background === CUSTOM && (
              <div className="bg-input">
                <TextField
                  type="text"
                  label="CSS Background Value"
                  size="small"
                  value={customBackground}
                  onChange={handleCustomChange}
                />
              </div>
            )}
          </BackgroundSelect>
        ))}
      </BackgroundsContainer>
      <IconButton
        sx={{
          position: 'absolute',
          top: '2px',
          right: '2px',
        }}
        onClick={() => setShowSettingsModal(false)}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  margin: auto;
  margin-top: 5vh;
  background-color: #333;
  border: 1px solid #666;
  width: fit-content;
  padding: 0 2rem 2rem 2rem;
  border-radius: 8px;
  max-height: 90vh;
  overflow-y: auto;

  h1,
  h2 {
    margin: 1rem auto 0.75rem;
    text-align: center;
  }

  h1 {
    color: yellow;
    font-family: 'Pathway Gothic One', sans-serif;
  }

  .settings-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .stat-row {
    margin-left: 2rem;
    display: flex;
    gap: 12px;
  }
`;

const BackgroundsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  place-items: center;
`;

const OptionContainer = styled.div`
  background: ${({ optionColor }) => optionColor || '#222'};
  width: 250px;
  height: 100px;
  display: grid;
  place-items: center;
  border-radius: 8px;

  p {
    color: white;
    font-weight: bold;
    font-size: 1.4rem;
    text-shadow: 3px 2px 3px black;
  }
`;

const BackgroundSelect = styled.div`
  border-radius: 8px;
  border: 1px solid #666;
  padding: 6px;
  display: grid;
  place-items: center;
  cursor: pointer;

  img {
    max-width: 250px;
    border-radius: 8px;
  }

  &:hover {
    background-color: #666;
  }

  &.selected {
    border: 2px solid yellow;
  }

  .bg-input {
    padding-top: 12px;
  }
`;

export default SettingsModal;
