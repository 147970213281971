import reactStringReplace from 'react-string-replace';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

import colors from '../../styles/colors';
import Icon from '../../images/tokens/Icon';

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 20px;
  svg {
    transform: translate(-2px, 3px);
  }
`;

const TextIconWrapper = (props) => {
  return (
    <Tooltip title={props.title} placement="top">
      <IconWrapper color={props.color}>{props.children}</IconWrapper>
    </Tooltip>
  );
};

const tokenTextRxs = [
  {
    rgx: /\n/g,
    token: 'br',
  },
  {
    rgx: /(\[Charge\])/g,
    token: 'Charge',
    size: '26px',
  },
  {
    rgx: /(\[Force\])/g,
    token: 'Force',
    size: '24px',
  },
  {
    rgx: /(\[Focus\])/g,
    token: 'Focus',
    size: '30px',
  },
  {
    rgx: /(\[Hit\])/g,
    token: 'Hit',
  },
  {
    rgx: /(\[Stress\])/g,
    token: 'Stress',
  },
  {
    rgx: /(\[Energy\])/g,
    token: 'Energy',
  },
  {
    rgx: /(\[Deplete\])/g,
    token: 'Deplete',
    size: '32px',
  },
  {
    rgx: /(\[Lock\])/g,
    token: 'Lock',
  },
  {
    rgx: /(\[Action: Lock\])/g,
    token: 'Lock',
  },
  {
    rgx: /(\[Stain\])/g,
    token: 'Stain',
  },
  {
    rgx: /(\[Illicit\])/g,
    token: 'Illicit',
  },
  {
    rgx: /(\[Critical\sHit\])/g,
    token: 'Critical Hit',
  },
  {
    rgx: /(\[Front\sArc\])/g,
    token: 'Front Arc',
  },
  {
    rgx: /(\[Bullseye\sArc\])/g,
    token: 'Bullseye',
  },
  {
    rgx: /(\[Turret\sArc\])/g,
    token: 'Turret Arc',
  },
  {
    rgx: /(\[Spare\sParts\])/g,
    token: 'Spare Parts',
  },
  {
    rgx: /(\[Rear\sArc\])/g,
    token: 'Rear Arc',
  },
  {
    rgx: /(\[Left\sArc\])/g,
    token: 'Left Arc',
  },
  {
    rgx: /(\[Right\sArc\])/g,
    token: 'Right Arc',
  },
  {
    rgx: /(\[Action:\sFocus\])/g,
    token: 'Focus',
    size: '30px',
  },
  {
    rgx: /(\[Action:\sEvade\])/g,
    token: 'Evade',
    size: '30px',
  },
  {
    rgx: /(\[Action:\sBoost\])/g,
    token: 'Boost',
  },
  {
    rgx: /(\[Action:\sCoordinate\])/g,
    token: 'Coordinate',
  },
  {
    rgx: /(\[Action:\sCoordinate Purple\])/g,
    token: 'Coordinate',
    color: colors.forcePurple,
  },
  {
    rgx: /(\[Action:\sJam\])/g,
    token: 'Jam',
  },
];

export const replaceTextWithTokens = (text, color) => {
  let newText = text;
  let matches = 0;

  const labelRgx = /^(\w+\s*\w*:)/;
  newText = reactStringReplace(newText, labelRgx, (match, index, offset) => {
    const key = `${index}-${offset}-${matches}`;
    return (
      <span key={key} className="bold">
        {match}
      </span>
    );
  });
  const labelRgx2 = /\n(\w+\s*\w*:)/;
  newText = reactStringReplace(newText, labelRgx2, (match, index, offset) => {
    const key = `${index}-${offset}-${matches}`;
    return (
      <span key={key} className="bold">
        {match}
      </span>
    );
  });

  tokenTextRxs.forEach((rx, i) => {
    newText = reactStringReplace(newText, rx.rgx, (match, index, offset) => {
      matches++;
      if (rx.token === 'br') {
        return <br key={index} />;
      }

      const title = match.replace('[', '').replace(']', '').trim();
      const key = `${title}-${i}-${index}-${offset}-${matches}`;

      return (
        <TextIconWrapper key={key} title={title}>
          <Icon
            type={rx.token}
            size={rx.size || '22px'}
            color={rx.color || color || colors.black}
          />
        </TextIconWrapper>
      );
    });
  });

  return newText;
};
