import React from 'react';
import { Tooltip } from '@mui/material';

import styled, { keyframes } from 'styled-components';
import colors, {
  agilityGreen,
  black,
  forcePurple,
  goldenrod,
  lightGray,
  weaponRed,
  white,
} from '../../styles/colors';
import { factionColors, transformTokenState } from './gameUtils';
import HullShieldBarsCompact from './HullShieldBarsCompact';

import TokenIcon from './TokenIcon';
import { createArrayWithLengthN, names } from '../../utils';
import ArcIcon from '../../images/arcs/ArcIcon';
import ActionIcon from '../../components/ActionIcon';
import CardTooltip from '../../components/CardTooltip';
import Icon from '../../images/tokens/Icon';
import getSilhouette from '../../images/silhouettes';
import { Silhouette, getActionsList } from './upgradeUtils';
import StatBlock from './StatBlock';
import ShipDock from './ShipDock';
import RechargeArrow from '../../components/RechargeArrow';
import StatIcon from '../../components/StatIcon';

function PilotSummaryCard({
  pilot,
  teamShipId,
  isPlayerOne,
  currentShips,
  destroyedPilots,
  phase,
  collapsed,
}) {
  const hull = pilot.hull;
  const shield = pilot.shield;
  const maxHull = pilot.maxHull;
  const maxShield = pilot.maxShield || 0;
  const crits = pilot?.crits ?? [];
  const tokens = pilot?.tokens ?? [];
  const factionColor = factionColors[pilot.faction] || white;

  const tokenList = transformTokenState(tokens, true);
  const [nameFirstLine] = pilot.name.split(' (');

  const { forceRecharge, force, maxForce } = pilot;

  const upgradesWithCharges = pilot.upgrades.filter((u) => u.maxCharges > 0);
  const twoSidedUpgrades = pilot.upgrades.filter((u) => !!u.back);

  const silhouette = getSilhouette(pilot?.ship?.name);
  const hasSilhouette = !!silhouette;
  const actionList = getActionsList(pilot.ship.actions, pilot || []);
  const coShipIsDestroyed = destroyedPilots.includes(pilot.coShip);

  return (
    <Tooltip
      title={
        collapsed ? (
          <React.Fragment>
            <p
              style={{
                color: pilot.isDestroyed ? weaponRed : null,
                backgroundColor: pilot.isDestroyed ? black : null,
                padding: '4px',
                borderRadius: '4px',
                marginBottom: '4px',
                fontWeight: 'bold',
                fontSize: '16px',
              }}
            >
              {pilot.name} (<span>{pilot.ship.name}</span>)
            </p>
            <HullShieldBarsCompact
              crit={crits.length > 0}
              maxHull={maxHull}
              hull={hull}
              maxShield={maxShield}
              shield={shield}
            />
          </React.Fragment>
        ) : (
          ''
        )
      }
      placement="left"
      arrow
    >
      <SuperContainer>
        <Container
          factionColor={factionColor}
          className={names({
            'turn-highlight': currentShips.includes(pilot.id),
            engagement: phase === 'ENGAGEMENT',
          })}
        >
          <TitleRow hasSilhouette={hasSilhouette}>
            <div className="initiative">{pilot.initiative}</div>
            {silhouette && (
              <ShipSilhouette
                src={silhouette}
                alt="Ship Silhouette"
                factionColor={factionColor}
              />
            )}
            {!collapsed && (
              <>
                <CardTooltip
                  title={
                    <React.Fragment>
                      <PilotCardImg src={`/pilots/${pilot.cardImage}`} />
                    </React.Fragment>
                  }
                  placement="left"
                >
                  <p className="pilot-name">{nameFirstLine}</p>
                </CardTooltip>
                <div className={names({ black: !isPlayerOne }, 'team-ship-id')}>
                  {teamShipId}
                </div>
                <p className="ship-name">{pilot.ship.name}</p>
              </>
            )}
          </TitleRow>
          {!collapsed && (
            <Stats className="stats" hasForce={!!pilot.force}>
              <div className="stat dmg">
                <WeaponContainer className="stat-value">
                  {pilot.ship.wpn.map((w) => {
                    return (
                      <span key={w.arc} className="wpn" title={w.arc}>
                        <ArcIcon type={w.arc} size="36px" horizontal showRing />
                        <p>{w.damage}</p>
                      </span>
                    );
                  })}
                </WeaponContainer>
              </div>
              <StatBlock
                type="agility"
                size="36px"
                number={pilot.ship.agl}
                color={colors.green}
                sx={{ margin: ' 12px 0' }}
                horizontal
              />
              {pilot.maxCharges ? (
                <Tooltip
                  title={`Recharge ${forceRecharge} token${
                    forceRecharge > 1 ? 's' : ''
                  } in end phase`}
                  arrow
                  placement="left"
                >
                  <div className="stat charges">
                    <StatIcon
                      type="charge"
                      size="36px"
                      horizontal
                      showRing
                      color={colors.goldenrod}
                      number={`${pilot.charges}/${pilot.maxCharges}`}
                      recharge={pilot.recharge}
                    />
                  </div>
                </Tooltip>
              ) : null}
            </Stats>
          )}
          {!collapsed && (
            <HullShieldWrapper>
              <HullShieldBarsCompact
                crit={crits.length > 0 && !pilot.isDestroyed}
                maxHull={maxHull}
                hull={hull}
                maxShield={maxShield}
                shield={shield}
              />
            </HullShieldWrapper>
          )}
          {!collapsed && (
            <ActionsContainer>
              {actionList.map((action, i) => (
                <ActionWrapper key={i}>
                  <ActionIcon action={action} />
                </ActionWrapper>
              ))}
            </ActionsContainer>
          )}
          {tokenList.length > 0 && !collapsed && (
            <TokenContainer>
              {tokenList.map((token, i) => (
                <TokenIcon
                  key={token + i}
                  tokenName={token.token}
                  count={token.count}
                  size="small"
                />
              ))}
            </TokenContainer>
          )}
          {!collapsed && !pilot.isDestroyed && (
            <CritsContainer className="crits">
              {crits.map((c, i) => (
                <Tooltip title={c.effect} key={c.title + i}>
                  <div className="crit">{c.title}</div>
                </Tooltip>
              ))}
            </CritsContainer>
          )}
          {!collapsed && (
            <ChargesWrapper>
              {maxForce ? (
                <Tooltip
                  title={`Recharge ${forceRecharge} token${
                    forceRecharge > 1 ? 's' : ''
                  } in end phase`}
                  arrow
                  placement="left"
                >
                  <ForceArea>
                    <span className="recharge-arrows">
                      {forceRecharge &&
                        createArrayWithLengthN(forceRecharge).map((_, i) => (
                          <RechargeArrow key={i} fill={colors.forcePurple} />
                        ))}
                    </span>
                    <div className="icon-wrapper">
                      <Icon
                        type="force"
                        size="28px"
                        color={colors.forcePurple}
                      />
                    </div>

                    {createArrayWithLengthN(maxForce).map((_, i) => (
                      <ForceDot key={i} active={i < force} />
                    ))}
                  </ForceArea>
                </Tooltip>
              ) : null}
              {upgradesWithCharges.length > 0
                ? upgradesWithCharges
                    .sort((a, b) => a.name.length - b.name.length)
                    .map((u, i) => (
                      <Tooltip
                        title={u.ability}
                        key={u.name + i}
                        placement="left"
                        arrow
                      >
                        <ChargeArea>
                          <p>{u.name}:</p>
                          {createArrayWithLengthN(u.maxCharges).map((_, i) => (
                            <ChargeDot key={i} active={i < u.charges} />
                          ))}
                        </ChargeArea>
                      </Tooltip>
                    ))
                : null}
              {twoSidedUpgrades.length > 0
                ? twoSidedUpgrades.map((u, i) => {
                    return (
                      <Tooltip
                        title={u.isFaceUp ? u.ability : u.back.ability}
                        key={u.name + i}
                        placement="left"
                        arrow
                      >
                        <TwoSidedArea>
                          {u.isFaceUp ? u.name : u.back.name}
                          <Silhouette
                            upgrade={u}
                            callSignStyle={{ color: 'white' }}
                            width="36"
                            height="18"
                            open={u.isFaceUp}
                            style={{ marginLeft: '8px' }}
                            forceHorizontal
                            mini
                          />
                        </TwoSidedArea>
                      </Tooltip>
                    );
                  })
                : null}
            </ChargesWrapper>
          )}
          {pilot.isDestroyed && (
            <DestroyedContainer>
              <div className="overlay">
                {!collapsed ? (
                  <p>Destroyed</p>
                ) : (
                  <Icon type="hit" color={colors.red} size="50px" />
                )}
              </div>
            </DestroyedContainer>
          )}
        </Container>
        {pilot.isMainShip && !pilot.isDestroyed && !coShipIsDestroyed && (
          <ShipDock docked={pilot.docked} factionColor={factionColor} small />
        )}
      </SuperContainer>
    </Tooltip>
  );
}

const turnHighlight = (flashColor) => keyframes`
  0% {
    border: 4px solid;
    border-color: black;
  }
  50% {
    border: 4px solid;
    border-color: ${flashColor};
  }
  100% {
    border: 4px solid;
    border-color: black;
  }
`;

const SuperContainer = styled.div``;

const Container = styled.div`
  position: relative;
  user-select: none;
  border-top: 3px solid ${({ factionColor }) => factionColor};
  border-bottom: 3px solid ${({ factionColor }) => factionColor};
  /* margin-bottom: 12px; */

  &.turn-highlight {
    animation-name: ${() => turnHighlight('lime')};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  &.turn-highlight.engagement {
    animation-name: ${() => turnHighlight('red')};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
`;

// TODO: break out into smaller components
const Stats = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 4px 12px;
  background-color: black;

  .stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
  }

  .stat-value {
    display: flex;
    font-weight: bold;
    margin-left: 4px;
    width: fit-content;
    font-size: 28px;
    font-family: 'Teko', 'roboto', sans-serif;
  }

  .wpn {
    width: fit-content;
    margin-right: 8px;
  }
  .dmg {
    color: ${weaponRed};
  }
  .agl {
    color: ${agilityGreen};
    span {
      margin-right: 8px;
    }
  }
  .force {
    color: ${forcePurple};
  }
  .charges {
    color: ${goldenrod};

    .recharge {
      height: 12px;
      margin-left: 2px;
      align-self: flex-start;
    }
  }
`;

const WeaponContainer = styled.div`
  margin: 2px 0;
  fill: ${weaponRed};
  color: ${weaponRed};
  text-align: center;

  span {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 4px;
    padding: 4px;
    padding-left: 6px;
    width: fit-content;
    border-radius: 4px;
  }

  svg {
    width: 36px;
    height: 36px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: white;
  background-color: black;
`;

const ActionWrapper = styled.div`
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: center;
  border: 1px solid #333;
  min-width: 50px;
  flex-grow: 1;

  &:hover {
    border: 1px solid white;
    border-radius: 4px;
  }
`;

const HullShieldWrapper = styled.div`
  background-color: black;
  padding: 0 12px 6px 12px;
`;

const redAlert = keyframes`
  0% {
    border-color: black;
  }
  50% {
    border-color: red;
  }
  100% {
    border-color: black;
    }
`;

const TitleRow = styled.div`
  display: grid;
  column-gap: 12px;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  padding: 4px 6px;
  background-color: #333;

  .initiative {
    color: ${goldenrod};
    font-weight: bold;
    font-size: 32px;
    font-family: 'Teko', 'roboto', sans-serif;
    grid-row: 1 / span 2;
    grid-row: ${({ hasSilhouette }) => (hasSilhouette ? '1' : '1 / span 2')};
  }

  .pilot-name {
    place-self: center;
    font-size: 20px;
    font-weight: bold;
  }

  .team-ship-id {
    display: flex;
    align-items: center;
    max-height: 26px;
    grid-column: 3;
    grid-row: 1 / span 2;
    place-self: center;
    border-radius: 4px;
    padding: 0 4px;
    font-weight: bold;
    font-size: 20px;
    color: black;
    background-color: white;
    border: 1px solid black;

    &.black {
      color: white;
      background-color: black;
      border: 1px solid white;
    }
  }

  .ship-name {
    font-size: 12px;
    font-style: italic;
    width: 100%;
    text-align: center;
    grid-column: 2;
  }
`;

const ShipSilhouette = styled.img`
  max-height: 32px;
  max-width: 42px;
  border: 1px solid ${({ factionColor }) => factionColor};
  border-radius: 4px;
  padding: 2px;
  background-color: black;
  margin-left: 2px;
  grid-row: 2;
`;

const TokenContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 4px;
  margin: 6px 0;
  background-color: #333;
  padding: 4px;
  padding-top: 6px;
  border-radius: 6px;
`;

const CritsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;

  .crit {
    cursor: help;
    user-select: none;
    text-align: center;
    font-size: 11px;
    border-radius: 4px;
    padding: 4px;
    background-color: maroon;
    color: white;
    border: 2px solid black;
    animation-name: ${redAlert};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
`;

const ChargesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 6px 0;
`;

const DotArea = styled.div`
  display: flex;
  font-weight: bold;
  align-items: center;
  padding: 0 8px;
  margin: 6px;
  margin-bottom: 0;
  border-radius: 8px;
  width: fit-content;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50px;
  margin-left: 4px;
`;

const ForceArea = styled(DotArea)`
  display: flex;
  align-items: center;
  color: ${forcePurple};
  border: 1px solid ${forcePurple};

  .icon-wrapper {
    height: 24px;
    width: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 9px;
  }

  .recharge-arrows {
    height: 11px;
    margin-right: 6px;
    display: flex;
    align-items: flex-start;
  }
`;

const ForceDot = styled(Dot)`
  background-color: ${({ active }) => (active ? forcePurple : colors.darkRed)};
  border: 1px solid ${forcePurple};
`;

const ChargeArea = styled(DotArea)`
  display: flex;
  align-items: center;
  color: ${goldenrod};
  border: 1px solid ${goldenrod};

  p {
    padding-top: 3px;
  }
`;

const ChargeDot = styled(Dot)`
  background-color: ${({ active }) => (active ? goldenrod : colors.darkRed)};
  border: 1px solid ${goldenrod};
`;

const TwoSidedArea = styled(DotArea)`
  color: ${lightGray};
  border: 1px solid ${lightGray};
`;

const PilotCardImg = styled.img`
  width: 400px;
  height: 500px;
  object-fit: contain;
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 0;
`;

const DestroyedContainer = styled.div`
  z-index: 100;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 0, 0, 0.65);
    border-radius: 8px 0 0 8px;
  }
  p {
    color: ${weaponRed};
    font-size: 54px;
    font-family: 'Teko', 'roboto', sans-serif;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-35deg);
    text-shadow: 2px 2px 2px black;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export default PilotSummaryCard;
