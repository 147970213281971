import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ThreatBar from './ThreatBar';
import axiosRequest from '../../requests/axios';
import { IconButton, Modal, Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import colors from '../../styles/colors';
import PilotCard from '../GameBoard/PilotCard';
import factionImages from '../../images/factions';
import { names } from '../../utils';
import { removeParenthesesFromName } from './qbUtils';

function QuickBuildCard({
  card,
  faction,
  handleSelect,
  remove,
  isOpponentCard,
  previewOnly,
}) {
  const [pilotData, setPilotData] = useState({});
  const [wingManData, setWingManData] = useState();
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    const fetchPilotData = async () => {
      const buildPromises = [
        axiosRequest.post('/game-data/pilot/build', {
          build: card,
        }),
      ];

      if (card.flownWith) {
        buildPromises.push(
          axiosRequest.post('/game-data/pilot/build', {
            build: card.flownWith,
          })
        );
      }

      const [pilotRes, wingManRes] = await Promise.all(buildPromises);

      setPilotData(pilotRes.data);
      setWingManData(wingManRes?.data);
    };

    try {
      fetchPilotData();
    } catch (error) {
      console.log('error:', error);
    }
  }, [card]);

  const cardNameClean = removeParenthesesFromName(card.name);
  const wingManNameClean = removeParenthesesFromName(
    card?.flownWith?.name ?? ''
  );

  const pilotShip = pilotData?.ship?.name;
  const wingManShip = wingManData?.ship?.name;

  const linked = !!card.flownWith;

  return (
    <Wrapper className={names({ linked })}>
      <ActionBar className="action-bar">
        {!isOpponentCard && (
          <IconButton
            onClick={(e) => handleSelect(card, pilotShip)}
            className="select"
            style={{
              color: remove ? colors.red : 'lime',
              width: '2rem',
              height: '2rem',
              display: previewOnly ? 'none' : '',
            }}
          >
            {remove ? (
              <Tooltip title="Remove from Squadron" placement="top">
                <RemoveCircleIcon
                  style={{ width: '2rem', height: '2rem' }}
                  size="large"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Add to Squadron" placement="top">
                <AddCircleIcon
                  style={{ width: '2rem', height: '2rem' }}
                  size="large"
                />
              </Tooltip>
            )}
          </IconButton>
        )}
        <InfoButton onClick={() => setInfoModalOpen(true)} className="info">
          <Tooltip title="More Info">
            <InfoOutlinedIcon
              style={{ width: '2rem', height: '2rem' }}
              size="large"
            />
          </Tooltip>
        </InfoButton>
      </ActionBar>
      <CardContainer className={names({ linked }, 'card')}>
        <TopRow className="top-row">
          <Tooltip title={pilotData.pilotAbility} placement="bottom-start">
            <div className="card-name">
              <p className="card-name-text">{cardNameClean}</p>
              {pilotShip && <p className="ship-designation">{pilotShip}</p>}
            </div>
          </Tooltip>
          <img
            className="faction-image"
            src={factionImages[card?.faction || faction]}
            alt={card?.faction || faction}
          />
        </TopRow>
        <ThreatBar threat={card.threatLevel} />
        <AbilitiesContainer className="upgrade-list">
          {pilotData?.upgrades?.map((upgrade) => {
            return (
              <Tooltip
                key={upgrade.title}
                title={upgrade?.ability}
                placement="bottom-start"
              >
                <div className="upgrade">{upgrade.title}</div>
              </Tooltip>
            );
          })}
        </AbilitiesContainer>
        <Modal
          open={infoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          aria-labelledby="modal-pilot-preview"
          aria-describedby="modal-pilot-preview-description"
        >
          <ModalContainer>
            <CardStack>
              <PilotCard
                pilot={pilotData}
                player={{}}
                currentShips={[]}
                isOpponentCard
              />
              {wingManData && (
                <PilotCard
                  pilot={wingManData}
                  player={{}}
                  currentShips={[]}
                  isOpponentCard
                />
              )}
            </CardStack>
          </ModalContainer>
        </Modal>
      </CardContainer>
      {card.flownWith && (
        <CardContainer className="card second">
          <TopRow className="top-row">
            <Tooltip title={pilotData.pilotAbility} placement="bottom-start">
              <div className="card-name">
                <p className="card-name-text">{wingManNameClean}</p>
                {wingManShip && (
                  <p className="ship-designation">{wingManShip}</p>
                )}
              </div>
            </Tooltip>
            <img
              className="faction-image"
              src={factionImages[card?.faction || faction]}
              alt={card?.faction || faction}
            />
          </TopRow>
          <ThreatBar threat={card.threatLevel} />
          <AbilitiesContainer className="upgrade-list">
            {wingManData?.upgrades?.map((upgrade) => {
              return (
                <Tooltip
                  key={upgrade.title}
                  title={upgrade?.ability}
                  placement="bottom-start"
                >
                  <div className="upgrade">{upgrade.title}</div>
                </Tooltip>
              );
            })}
          </AbilitiesContainer>
        </CardContainer>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;

  &.linked {
    grid-column: span 2;
    grid-template-columns: auto 1fr 1fr;
  }

  &:hover {
    background-color: black;
    .card,
    .action-bar {
      border: 1px solid green;
    }
  }
`;

const ActionBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12px 4px;
  background-color: #333;
  border: 1px solid yellow;
  border-right: none;
  border-radius: 8px 0 0 8px;

  &:hover {
    background-color: black;
  }
`;

const CardContainer = styled.div`
  border: 1px solid yellow;
  border-radius: 0 8px 8px 0;
  padding: 8px;
  cursor: pointer;

  &.linked {
    border-radius: 0;
  }
  p {
    font-weight: bold;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .card-name {
    display: flex;
    flex-direction: column;
  }

  .card-name-text {
  }

  .card-name .ship-designation {
    color: #777;
    font-weight: normal;
    font-size: 14px;
  }
  img {
    height: 45px;
    width: 45px;
  }
  .faction-image {
    height: 35px;
    width: 35px;
  }
`;

const AbilitiesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  padding: 8px 0;
  font-size: 14px;
`;

const InfoButton = styled.button`
  cursor: pointer;
  color: white;
  background-color: transparent;
  opacity: 0.7;
  margin-bottom: -8px;

  svg {
    cursor: pointer !important;
    color: white;
  }

  &:hover {
    opacity: 1;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  margin: 5% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: ${colors.navy};
  min-width: fit-content;
  max-width: 80vw;
  max-height: 90vh;
  padding-left: 100px;
  border-radius: 12px;
  border: 2px solid ${({ factionColor }) => factionColor};
  text-align: center;
`;

const CardStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding: 36px 0;
`;

export default QuickBuildCard;
