import React from 'react';

import styled from 'styled-components';
import { getTokenData } from './tokenUtils';
import RoundBase from './RoundBase';
import FocusIcon from './Icons/Focus';

function Token({ type, colorOverride, size, onClick, ...rest }) {
  const tokenData = getTokenData(type);
  const Base = tokenData?.base ?? RoundBase;
  const Icon = tokenData?.icon ?? FocusIcon;
  const color = colorOverride || tokenData?.color || 'white';

  return (
    <Wrapper className={`token ${type?.toLowerCase()}`}>
      <Base color={color} {...rest}>
        <Icon type={type} color={color} {...rest} />
      </Base>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  svg {
    height: 100%;
    width: 100%;
  }
`;

export default Token;
