import { useContext } from 'react';
import { Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SettingsIcon from '@mui/icons-material/Settings';

import { GameStateContext } from '../../context/gameState';
import { SocketContext } from '../../context/socket';

import CustomButton from '../../components/Button';
import { names } from '../../utils';

const ADVANCE_PHASE = 'ADVANCE_PHASE';
const ADVANCE_SHIP_TURN = 'ADVANCE_SHIP_TURN';
const REGRESS_PHASE = 'REGRESS_PHASE';
const GAME_UNDO = 'GAME_UNDO';
const GAME_REDO = 'GAME_REDO';

function GameBoardHeader({ isPlayerOne, toggleSettingsModal }) {
  const { gameState } = useContext(GameStateContext);
  const socket = useContext(SocketContext);

  const advancePhase = () => {
    socket.emit(ADVANCE_PHASE);
  };
  const regressPhase = () => {
    socket.emit(REGRESS_PHASE);
  };
  const advanceShipTurn = (delta = 1) => {
    socket.emit(ADVANCE_SHIP_TURN, { delta });
  };

  const undo = () => {
    socket.emit(GAME_UNDO);
  };
  const redo = () => {
    socket.emit(GAME_REDO);
  };

  const { round = '', phase = '' } = gameState;

  return (
    <HeaderBar>
      <PlayerNum className={names({ player1: isPlayerOne }, 'player-num')}>
        {isPlayerOne ? 'Player 1' : 'Player 2'}
      </PlayerNum>
      <Link to="/" style={{ color: 'white', marginRight: '8px' }}>
        <Button variant="outlined">HOME</Button>
      </Link>
      <Counters>
        <h3>
          Round:
          <span>{round}</span>
        </h3>
        <h3 className="phase">
          Phase:
          <span>{phase.toUpperCase()}</span>
        </h3>
      </Counters>
      <Controls className="controls">
        <Button onClick={regressPhase}>
          <ArrowBackIosNewIcon />
        </Button>
        <CustomButton onClick={advancePhase}>Next Phase</CustomButton>
        {['ACTIVATION', 'ENGAGEMENT'].includes(phase) ? (
          <div className="ship-ordering-controls">
            <Button variant="outlined" onClick={() => advanceShipTurn(-1)}>
              <ArrowBackIosNewIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => advanceShipTurn()}
              disabled={gameState.disableNextTurn}
            >
              Next Turn
            </Button>
          </div>
        ) : null}
      </Controls>
      <UndoRedo>
        <Button
          onClick={undo}
          variant="outlined"
          disabled={gameState.undoCount < 2}
        >
          UNDO
        </Button>
        <Button
          onClick={redo}
          variant="outlined"
          disabled={gameState.redoCount < 1}
        >
          REDO
        </Button>
      </UndoRedo>
      {/* <SettingsIcon /> */}
      <h3 style={{ color: '#bbb', margin: '0 10px 0 auto' }}>
        Game: {gameState.code}
      </h3>

      <SettingsArea>
        <IconButton onClick={toggleSettingsModal}>
          <SettingsIcon />
        </IconButton>
      </SettingsArea>
    </HeaderBar>
  );
}

const headerHeight = '55px';

const HeaderBar = styled.div`
  position: fixed;
  padding: 12px;
  width: 100vw;
  height: ${headerHeight};
  display: flex;
  align-items: center;
  background-color: #222;
  box-shadow: 1px 0px 8px 1px black;
  z-index: 1000;
`;

const PlayerNum = styled.h3`
  margin: 0 20px 0 12px;
  padding: 4px 8px;
  background-color: black;
  color: white;
  border-radius: 8px;
  border: 2px solid white;

  &.player1 {
    background-color: white;
    color: black;
    border: 2px solid black;
  }
`;

const Counters = styled.div`
  margin-right: 12px;
  display: grid;
  grid-template-columns: auto auto;
  text-align: center;

  h3 {
    font-weight: bold;
    border-right: 1px solid white;
    padding: 0 12px;
    text-align: left;
    span {
      color: forestgreen;
      margin-left: 8px;
    }
  }

  .phase {
    min-width: 240px;
  }
`;

const Controls = styled.div`
  margin: 0 12px;
  display: flex;

  .ship-ordering-controls {
    display: grid;
    padding: 0 12px;
    grid-template-columns: auto auto;
    gap: 12px;
    border-left: 1px solid white;
    margin-left: 16px;
  }
`;

const UndoRedo = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 12px;
  margin-left: 12px;
`;

const SettingsArea = styled.div`
  margin-right: 12px;
`;

export default GameBoardHeader;
