import React from 'react';
import XWingSilhouette from './XWingSilhouette.js';
import { XWingSvgContainer, SvgWrapper } from './xWingStyles';

import SantaHat from './SantaHat.js';

function XWingSvg(props) {
  const {
    animateOnHover,
    increaseBlur,
    droidColor,
    fill,
    height,
    open,
    outline,
    squadronColor,
    silhouetteColor,
    silhouetteOnly,
    stroke,
    width,
    ...rest
  } = props;

  const squadronColorValue = squadronColor || '#d40000';

  const droidColorValue = droidColor || '#0500ff';

  const hullColorValue = '#b3b3b3';

  const isDecember = new Date().getMonth() === 11;

  return (
    <SvgWrapper>
      {isDecember && (
        <SantaHat
          style={{
            position: 'absolute',
            width: '11%',
            left: '42.5%',
            top: '22%',
          }}
        />
      )}
      {outline && (
        <XWingSilhouette
          open={open}
          fill={silhouetteColor || 'black'}
          stroke={stroke}
          height={height}
          width={width}
          style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}
          blur
        />
      )}
      {increaseBlur && (
        <XWingSilhouette
          open={open}
          fill={silhouetteColor || 'black'}
          stroke={stroke}
          height={height}
          width={width}
          style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}
          blur
        />
      )}
      <XWingSvgContainer
        xmlns="http://www.w3.org/2000/svg"
        width="300"
        height="150"
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 300 150"
        xmlSpace="preserve"
        open={open}
        animateOnHover={animateOnHover}
        silhouetteOnly={silhouetteOnly}
        {...rest}
      >
        <g
          className="x-wing-svg-droid"
          stroke="#000"
          strokeWidth="0.2"
          display="inline"
        >
          <path
            fill="#fff"
            d="M155 49.634a5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5 5 5 0 015 5z"
            opacity="1"
          ></path>
          <path
            fill="#767676"
            d="M151.914 47.27a.31.31 0 01-.309.309.31.31 0 01-.31-.31.31.31 0 01.31-.309.31.31 0 01.31.31z"
            opacity="1"
          ></path>
          <path
            fill={droidColorValue}
            fillOpacity="1"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            d="M150 44.634a5 5 0 00-3.893 1.875h7.795A4.999 4.999 0 00150 44.634zm-3.273 8.778a4.997 4.997 0 006.546 0z"
            opacity="1"
          ></path>
        </g>
        <g stroke="#000" className="x-wing-wing-set-2">
          <g>
            <g display="inline" opacity="1">
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M89.535 84.116h-1.738v-2.003h1.738z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M84.994 82.99h-1.738v-2.004h1.738z"
              ></path>
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M80.453 81.708h-1.738v-2.003h1.738z"
              ></path>
              <path
                fill="gray"
                fillOpacity="1"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M108.419 82.552l-1.235 1.248H92.403l-16.922-4.884v-1.804h32.938z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                fillOpacity="0.318"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M107.647 81.49v-4.377H82.056l10.347 4.378z"
                opacity="1"
              ></path>
              <path
                fill="#333"
                fillOpacity="1"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.204"
                d="M107.647 79.505v-2.392H75.481v1.802l2.027.59z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                fillOpacity="0.99"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M107.647 79.505v-2.392H82.056l5.654 2.392z"
                opacity="1"
              ></path>
            </g>
            <path
              fill={hullColorValue}
              strokeWidth="0.3"
              d="M128.309 77.798H9.964l-2-1.075v-1.075h120.345z"
              display="inline"
              opacity="1"
            ></path>
            <g display="inline">
              <path
                fill="#ababab"
                strokeWidth="0.508"
                d="M9.095 86.85H7.548v-3.448h1.547z"
                opacity="1"
              ></path>
              <path
                fill="#ababab"
                strokeWidth="0.508"
                d="M8.602 84.907a5.858 5.858 0 1111.716 0 5.858 5.858 0 01-11.716 0z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="gray"
                strokeWidth="0.319"
                d="M10.157 84.907a4.303 4.303 0 118.606 0 4.303 4.303 0 01-8.606 0z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#666"
                strokeWidth="0.289"
                d="M15.963 91.976h-3.006V77.838h3.006z"
                opacity="1"
              ></path>
              <path
                fill="#ababab"
                strokeWidth="0.238"
                d="M15.963 89.678h-3.006v-9.542h3.006z"
                opacity="1"
              ></path>
              <path
                strokeWidth="0.355"
                d="M13.584 84.907a.876.876 0 111.752 0 .876.876 0 01-1.752 0z"
                display="inline"
                opacity="1"
              ></path>
            </g>
            <g
              strokeDasharray="none"
              strokeMiterlimit="4"
              strokeWidth="0.3"
              display="inline"
            >
              <path
                fill="#666"
                d="M98.083 92.692c0-5.575 4.52-10.095 10.095-10.095 5.575 0 10.095 4.52 10.095 10.095 0 5.575-4.52 10.095-10.095 10.095-5.575 0-10.095-4.52-10.095-10.095z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#4d4d4d"
                strokeWidth="0.254"
                d="M97.322 88.632c0-5.714 4.632-10.347 10.347-10.347s10.347 4.633 10.347 10.347c0 5.715-4.632 10.348-10.347 10.348s-10.348-4.633-10.348-10.348z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#1a1a1a"
                fillOpacity="1"
                strokeWidth="0.21"
                d="M99.089 88.632a8.58 8.58 0 1117.16 0 8.58 8.58 0 11-17.16 0z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#999"
                d="M107.669 100.869c6.758 0 12.236-5.478 12.236-12.236 0-6.758-5.478-12.236-12.236-12.236-6.758 0-12.236 5.478-12.236 12.236 0 6.758 5.478 12.236 12.236 12.236zm-.547-1.904a10.347 10.347 0 01-9.785-9.785h6.793a3.583 3.583 0 002.992 2.992zm1.094-.027v-6.766a3.583 3.583 0 002.992-2.992h6.766a10.348 10.348 0 01-9.758 9.758zm9.758-10.852h-6.766a3.583 3.583 0 00-3.539-3.037 3.583 3.583 0 00-3.539 3.037h-6.793c.29-5.494 4.83-9.8 10.332-9.8a10.348 10.348 0 0110.305 9.8z"
                display="inline"
                opacity="1"
              ></path>
            </g>
          </g>
          <g>
            <g display="inline" opacity="1">
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M210.218 66.929h1.738v2.003h-1.738z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M214.759 68.056h1.738v2.003h-1.738z"
              ></path>
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M219.3 69.337h1.738v2.003H219.3z"
              ></path>
              <path
                fill="gray"
                fillOpacity="1"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M179.334 68.493l1.235-1.248h26.781l16.922 4.884v1.804h-44.938z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                fillOpacity="0.318"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M192.106 69.554v4.378h25.591l-10.347-4.378z"
                opacity="1"
              ></path>
              <path
                fill="#333"
                fillOpacity="1"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.204"
                d="M192.106 71.54v2.392h32.166V72.13l-2.027-.59z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                fillOpacity="0.99"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M192.106 71.54v2.392h25.591l-5.654-2.392z"
                opacity="1"
              ></path>
            </g>
            <path
              fill={hullColorValue}
              strokeWidth="0.3"
              d="M171.444 73.247h118.345l2 1.075v1.075H171.444z"
              display="inline"
              opacity="1"
            ></path>
            <g display="inline">
              <path
                fill="#ababab"
                strokeWidth="0.508"
                d="M290.658 64.196h1.547v3.447h-1.547z"
                opacity="1"
              ></path>
              <path
                fill="#ababab"
                strokeWidth="0.508"
                d="M291.151 66.138a5.858 5.858 0 01-5.858 5.858 5.858 5.858 0 01-5.858-5.858 5.858 5.858 0 015.858-5.858 5.858 5.858 0 015.858 5.858z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="gray"
                strokeWidth="0.319"
                d="M289.596 66.138a4.303 4.303 0 01-4.303 4.303 4.303 4.303 0 01-4.302-4.303 4.303 4.303 0 014.302-4.302 4.303 4.303 0 014.303 4.302z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#666"
                strokeWidth="0.289"
                d="M283.79 59.069h3.006v14.138h-3.006z"
                opacity="1"
              ></path>
              <path
                fill="#ababab"
                strokeWidth="0.238"
                d="M283.79 61.367h3.006v9.542h-3.006z"
                opacity="1"
              ></path>
              <path
                strokeWidth="0.355"
                d="M286.17 66.138a.876.876 0 01-.877.876.876.876 0 01-.876-.876.876.876 0 01.876-.876.876.876 0 01.876.876z"
                display="inline"
                opacity="1"
              ></path>
            </g>
            <g
              strokeDasharray="none"
              strokeMiterlimit="4"
              strokeWidth="0.3"
              display="inline"
            >
              <path
                fill="#666"
                d="M201.67 58.353a10.095 10.095 0 01-10.095 10.095 10.095 10.095 0 01-10.095-10.095 10.095 10.095 0 0110.095-10.095 10.095 10.095 0 0110.095 10.095z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#4d4d4d"
                strokeWidth="0.254"
                d="M202.432 62.413a10.347 10.347 0 01-10.348 10.347 10.347 10.347 0 01-10.347-10.347 10.347 10.347 0 0110.347-10.348 10.347 10.347 0 0110.348 10.348z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#1a1a1a"
                fillOpacity="1"
                strokeWidth="0.21"
                d="M200.664 62.413a8.58 8.58 0 01-8.58 8.58 8.58 8.58 0 01-8.58-8.58 8.58 8.58 0 018.58-8.58 8.58 8.58 0 018.58 8.58z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#999"
                d="M192.084 50.176a12.237 12.237 0 00-12.236 12.236 12.237 12.237 0 0012.236 12.236 12.237 12.237 0 0012.236-12.236 12.237 12.237 0 00-12.236-12.236zm.547 1.904a10.347 10.347 0 019.785 9.785h-6.793a3.583 3.583 0 00-2.992-2.992zm-1.094.027v6.766a3.583 3.583 0 00-2.992 2.992h-6.766a10.347 10.347 0 019.758-9.758zM181.78 62.96h6.766a3.583 3.583 0 003.539 3.037 3.583 3.583 0 003.54-3.037h6.792a10.347 10.347 0 01-10.332 9.8 10.347 10.347 0 01-10.305-9.8z"
                display="inline"
                opacity="1"
              ></path>
            </g>
          </g>
        </g>
        <g stroke="#000" className="x-wing-wing-set-1">
          <g>
            <g display="inline" opacity="1">
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M210.218 84.116h1.738v-2.003h-1.738z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M214.759 82.99h1.738v-2.004h-1.738z"
              ></path>
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M219.3 81.708h1.738v-2.003H219.3z"
              ></path>
              <path
                fill="gray"
                fillOpacity="1"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M191.334 82.552l1.235 1.248h14.781l16.922-4.884v-1.804h-32.938z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                fillOpacity="0.318"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M192.106 81.49v-4.377h25.591l-10.347 4.378z"
                opacity="1"
              ></path>
              <path
                fill="#333"
                fillOpacity="1"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.204"
                d="M192.106 79.505v-2.392h32.166v1.802l-2.027.59z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                fillOpacity="0.99"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M192.106 79.505v-2.392h25.591l-5.654 2.392z"
                opacity="1"
              ></path>
            </g>
            <path
              fill={hullColorValue}
              strokeWidth="0.3"
              d="M171.444 77.798h118.345l2-1.075v-1.075H171.444z"
              display="inline"
              opacity="1"
            ></path>
            <g display="inline">
              <path
                fill="#ababab"
                strokeWidth="0.508"
                d="M290.658 86.85h1.547v-3.448h-1.547z"
                opacity="1"
              ></path>
              <path
                fill="#ababab"
                strokeWidth="0.508"
                d="M291.151 84.907a5.86 5.86 0 00-5.858-5.858 5.856 5.856 0 00-5.858 5.858 5.857 5.857 0 1011.716 0z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="gray"
                strokeWidth="0.319"
                d="M289.596 84.907a4.303 4.303 0 10-8.606.002 4.303 4.303 0 008.606-.002z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#666"
                strokeWidth="0.289"
                d="M283.79 91.976h3.006V77.838h-3.006z"
                opacity="1"
              ></path>
              <path
                fill="#ababab"
                strokeWidth="0.238"
                d="M283.79 89.678h3.006v-9.542h-3.006z"
                opacity="1"
              ></path>
              <path
                strokeWidth="0.355"
                d="M286.169 84.907a.876.876 0 10-1.752 0 .876.876 0 001.752 0z"
                display="inline"
                opacity="1"
              ></path>
            </g>
            <g
              strokeDasharray="none"
              strokeMiterlimit="4"
              strokeWidth="0.3"
              display="inline"
            >
              <path
                fill="#666"
                d="M201.67 92.692c0-5.575-4.52-10.095-10.095-10.095-5.575 0-10.095 4.52-10.095 10.095 0 5.575 4.52 10.095 10.095 10.095 5.575 0 10.095-4.52 10.095-10.095z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#4d4d4d"
                strokeWidth="0.254"
                d="M202.431 88.632c0-5.714-4.632-10.347-10.347-10.347s-10.347 4.633-10.347 10.347c0 5.715 4.632 10.348 10.347 10.348s10.347-4.633 10.347-10.348z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#1a1a1a"
                fillOpacity="1"
                strokeWidth="0.21"
                d="M200.664 88.632a8.58 8.58 0 00-8.58-8.58 8.58 8.58 0 00-8.58 8.58 8.58 8.58 0 008.58 8.58 8.58 8.58 0 008.58-8.58z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#999"
                d="M192.084 100.869c-6.758 0-12.236-5.478-12.236-12.236 0-6.758 5.478-12.236 12.236-12.236 6.758 0 12.236 5.478 12.236 12.236 0 6.758-5.478 12.236-12.236 12.236zm.547-1.904a10.347 10.347 0 009.785-9.785h-6.793a3.583 3.583 0 01-2.992 2.992zm-1.094-.027v-6.766a3.583 3.583 0 01-2.992-2.992h-6.766a10.348 10.348 0 009.758 9.758zm-9.758-10.852h6.766a3.583 3.583 0 013.539-3.037 3.583 3.583 0 013.539 3.037h6.793a10.348 10.348 0 00-10.332-9.8 10.348 10.348 0 00-10.305 9.8z"
                display="inline"
                opacity="1"
              ></path>
            </g>
          </g>
          <g>
            <g display="inline" opacity="1">
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M89.535 66.929h-1.738v2.003h1.738z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M84.994 68.056h-1.738v2.003h1.738z"
              ></path>
              <path
                fill={hullColorValue}
                strokeWidth="0.186"
                d="M80.453 69.337h-1.738v2.003h1.738z"
              ></path>
              <path
                fill="gray"
                fillOpacity="1"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M120.419 68.493l-1.235-1.248H92.403l-16.922 4.884v1.804h44.938z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                fillOpacity="0.318"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M107.647 69.554v4.378H82.056l10.347-4.378z"
                opacity="1"
              ></path>
              <path
                fill="#333"
                fillOpacity="1"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.204"
                d="M107.647 71.54v2.392H75.481V72.13l2.027-.59z"
                opacity="1"
              ></path>
              <path
                fill={hullColorValue}
                fillOpacity="0.99"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.2"
                d="M107.647 71.54v2.392H82.056l5.654-2.392z"
                opacity="1"
              ></path>
            </g>
            <path
              fill={hullColorValue}
              strokeWidth="0.3"
              d="M128.309 73.247H9.964l-2 1.075v1.075h120.345z"
              display="inline"
              opacity="1"
            ></path>
            <g display="inline">
              <path
                fill="#ababab"
                strokeWidth="0.508"
                d="M9.095 64.196H7.548v3.447h1.547z"
                opacity="1"
              ></path>
              <path
                fill="#ababab"
                strokeWidth="0.508"
                d="M8.602 66.138a5.858 5.858 0 1011.716 0 5.858 5.858 0 00-11.716 0z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="gray"
                strokeWidth="0.319"
                d="M10.157 66.138a4.303 4.303 0 108.606 0 4.303 4.303 0 00-8.606 0z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#666"
                strokeWidth="0.289"
                d="M15.963 59.069h-3.006v14.138h3.006z"
                opacity="1"
              ></path>
              <path
                fill="#ababab"
                strokeWidth="0.238"
                d="M15.963 61.367h-3.006v9.542h3.006z"
                opacity="1"
              ></path>
              <path
                strokeWidth="0.355"
                d="M13.584 66.138a.876.876 0 101.752 0 .876.876 0 00-1.752 0z"
                display="inline"
                opacity="1"
              ></path>
            </g>
            <g
              strokeDasharray="none"
              strokeMiterlimit="4"
              strokeWidth="0.3"
              display="inline"
            >
              <path
                fill="#666"
                d="M98.083 58.353c0 5.575 4.52 10.095 10.095 10.095 5.575 0 10.095-4.52 10.095-10.095 0-5.575-4.52-10.095-10.095-10.095-5.575 0-10.095 4.52-10.095 10.095z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#4d4d4d"
                strokeWidth="0.254"
                d="M97.322 62.413c0 5.714 4.632 10.347 10.347 10.347s10.347-4.633 10.347-10.347c0-5.715-4.632-10.348-10.347-10.348s-10.347 4.633-10.347 10.348z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#1a1a1a"
                fillOpacity="1"
                strokeWidth="0.21"
                d="M99.089 62.413a8.58 8.58 0 1017.16 0 8.58 8.58 0 10-17.16 0z"
                display="inline"
                opacity="1"
              ></path>
              <path
                fill="#999"
                d="M107.669 50.176c6.758 0 12.236 5.478 12.236 12.236 0 6.758-5.478 12.236-12.236 12.236-6.758 0-12.236-5.478-12.236-12.236 0-6.758 5.478-12.236 12.236-12.236zm-.547 1.904a10.347 10.347 0 00-9.785 9.785h6.793a3.583 3.583 0 012.992-2.992zm1.094.027v6.766a3.583 3.583 0 012.992 2.992h6.766a10.348 10.348 0 00-9.758-9.758zm9.758 10.852h-6.766a3.583 3.583 0 01-3.539 3.037 3.583 3.583 0 01-3.539-3.037h-6.793c.29 5.494 4.83 9.8 10.332 9.8a10.348 10.348 0 0010.305-9.8z"
                display="inline"
                opacity="1"
              ></path>
            </g>
          </g>
        </g>
        <g>
          <path
            fill={hullColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.2"
            d="M125.412 66.803l-.02.078-.02.079-1.264 8.597 3.351 13.286 22.54 2 22.54-2 3.353-13.286-1.266-8.597-.02-.078-.02-.079-24.587-2z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.2"
            d="M139.64 47.954l-2.286 4.17h-2.819l-5.654 4.785-2.466 10.05-1.213 8.598 3.151 13.096 3.027 9.51 6.873 1.214.654 1.606 11.092 2.107 11.093-2.107.654-1.606 6.872-1.214 3.028-9.51 3.15-13.096-1.212-8.597-2.466-10.051-5.654-4.785h-2.819l-2.286-4.17H144.82z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill={hullColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.2"
            d="M139.64 47.954l-6.741 11.086-2.558.66-.917 4.845-2.168-1.156-.841 3.57-1.213 8.555 3.151 13.139 3.027 9.51 6.873 1.214.654 1.606 11.092 2.107 11.093-2.107.654-1.606 6.872-1.214 3.028-9.51 3.15-13.139-1.212-8.554-.841-3.57-2.168 1.155-.918-4.845-2.557-.66-6.741-11.086H144.82z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill={hullColorValue}
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M140.156 57.301l-2.002.69-2.508 3.484-.933 5.662 4.599 2.184 10.731-5.84 10.73 5.84 4.6-2.184-.936-5.662-2.505-3.484-2.002-.69h-9.887z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill={squadronColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M138.93 75.554l-13.728.004 1.981-12.114 12.129 5.877zm22.139 0l13.728.004-1.981-12.114-12.129 5.877z"
            display="inline"
          ></path>
          <path
            fill={squadronColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M138.93 75.554l-12.165.004 2.512-11.051 10.035 4.814zm22.139 0l12.165.004-2.512-11.051-10.035 4.814z"
            display="inline"
          ></path>
          <path
            fill={squadronColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M138.93 75.554l-9.016.004 2.346-9.659 7.052 3.422zm22.139 0l9.016.004-2.346-9.659-7.052 3.422z"
            display="inline"
          ></path>
          <path
            fill={squadronColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M138.93 75.554l-6.199.004 1.982-8.421 4.599 2.184zm22.139 0l6.199.004-1.982-8.421-4.599 2.184z"
            display="inline"
          ></path>
          <path
            fill={squadronColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M138.93 75.554l-3.735-.018 1.794-7.339 2.323 1.124zm22.139 0l3.735-.018-1.794-7.339-2.323 1.124z"
            display="inline"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M150.131 81.74l-9.545.089-9.901 3.713.049 10.59.647 2.03 6.873 1.215.654 1.606L150 103.09l11.092-2.107.654-1.605 6.873-1.215.912-2.865.045-9.756-9.898-3.713z"
            display="inline"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.285"
            d="M150.131 84.227l-8.58.088-8.899 3.244.041 10.836 5.561.983.654 1.605L150 103.09l11.092-2.107.654-1.605 5.68-1.004.142-.502.041-10.313-8.896-3.244z"
            display="inline"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="bevel"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M140.286 57.301l-3.167 10.896 5.964 3.09.829-6.936zm19.427 0l3.167 10.896-5.965 3.09-.828-6.936z"
          ></path>
          <path
            fill={hullColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.358"
            d="M150.29 61.884c-2.323 0-5.358-.159-8.735 2.691-3.378 2.85-3.254 10.66-2.937 11.821.317 1.161 1.213 3.551 3.8 4.593 2.916 1.174 7.493 1.188 7.883 1.187.391.001 4.968-.013 7.884-1.187 2.587-1.042 3.483-3.432 3.8-4.593.316-1.16.443-8.97-2.935-11.82-3.377-2.85-6.413-2.692-8.735-2.692H150.289z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M150.292 63.534c-1.945 0-4.486-.133-7.315 2.254-2.828 2.386-2.724 8.926-2.459 9.898.266.972 1.016 2.974 3.182 3.846 2.442.983 6.275.995 6.602.994.327 0 4.159-.011 6.601-.994 2.166-.872 2.917-2.874 3.182-3.846.265-.972.371-7.512-2.457-9.898-2.829-2.387-5.37-2.254-7.315-2.254H150.292z"
            display="inline"
          ></path>
          <path
            fill={hullColorValue}
            fillOpacity="0.117"
            stroke="#000"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeWidth="0.3"
            d="M158.707 64.296a12.67 12.67 0 00-.906-.654 11.22 11.22 0 00-1.213-.69 10.344 10.344 0 00-1.742-.654 10.408 10.408 0 00-1.104-.24 12.027 12.027 0 00-1.053-.124c-.17-.013-.338-.024-.504-.031a23.547 23.547 0 00-.966-.02h-1.832a23.63 23.63 0 00-.967.02 15.514 15.514 0 00-1.024.08 12.027 12.027 0 00-1.078.178 10.376 10.376 0 00-1.709.527 10.32 10.32 0 00-1.193.58 11.777 11.777 0 00-1.545 1.049c-.039.03-.078.064-.117.096l4.367 11.527h8.176z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill={hullColorValue}
            fillOpacity="0.117"
            stroke="#000"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeWidth="0.3"
            d="M144 81.485l.195.049a18.76 18.76 0 001.264.25 24.312 24.312 0 001.848.24 35.2 35.2 0 002.664.15c.168.003.281.003.33.002.049 0 .164.001.332-.002a30.85 30.85 0 001.566-.064 33.19 33.19 0 001.695-.15 25.559 25.559 0 001.883-.29c.212-.041.423-.087.631-.136l.026-.006-2.137-5.645h-8.176z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill={hullColorValue}
            stroke="#000"
            strokeWidth="0.3"
            d="M147.752 68.016v4.274h4.842v-4.274z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill={hullColorValue}
            stroke="#000"
            strokeWidth="0.3"
            d="M147.752 68.016h4.842V65.4h-4.842z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M128.251 77.306h6.826l2.355 2.835-1.483-.545h-7.022l-.676.676zm43.497 0h-6.826l-2.355 2.835 1.483-.545h7.022l.676.676z"
            display="inline"
          ></path>
          <path
            fill="gray"
            stroke="#000"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M145.002 57.301v3.496h2.5l.57.448h3.856l.57-.447h2.498V57.3h-4.953z"
            opacity="1"
          ></path>
          <path
            fill={hullColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinejoin="bevel"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.3"
            d="M132.658 89.127l.035 9.268 5.561.983.654 1.605L150 103.09l11.092-2.107.654-1.605 5.68-1.004.142-.502.035-8.745h-8.736l-4.368.124-4.368.123-4.368-.123-4.369-.124z"
            opacity="1"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.2"
            d="M149.889 86.946c-5.794.052-11.34 2.355-11.34 2.355l-.496 2.618s1.434.674 1.521 1.351c.087.677-1.521 1.135-1.521 1.135v1.57s1.543.48 1.543 1.22c0 .743-1.543 1.267-1.543 1.267l.084.894.117.022.654 1.605L150 103.09l11.092-2.107.515-1.268.12-1.253s-1.544-.524-1.543-1.266c0-.742 1.543-1.22 1.543-1.22v-1.571s-1.609-.458-1.522-1.135c.087-.677 1.522-1.351 1.522-1.351l-.497-2.618s-5.548-2.303-11.341-2.355z"
          ></path>
          <path
            fill="#666"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.299"
            d="M142.387 91.567v4.209h15.545v-4.209z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill={hullColorValue}
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.299"
            d="M142.387 95.776v5.867L150 103.09l7.932-1.507v-5.807z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="#000"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.2"
            d="M140.373 50.096l1.035 5.725 8.592-.022 8.592.022 1.035-5.725H150zm-1.3.948l-2.774 5.308 3.568-.797zm21.854 0l2.774 5.308-3.568-.797z"
          ></path>
        </g>
      </XWingSvgContainer>{' '}
    </SvgWrapper>
  );
}

export default XWingSvg;
