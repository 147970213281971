import React from 'react';

function ReloadIcon({ color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...rest}
    >
      <g>
        <path
          fill={color || 'white'}
          fillOpacity="1"
          stroke="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0"
          d="M508.168 115.764c-157.226.42-297.438 99.031-350.994 246.855l80.976 22.117C281.498 273.33 388.626 199.818 508.168 199.45c91.588.08 177.782 43.318 232.607 116.684l-64.177 42.85 219.377 108.279-15.916-244.125-69.868 46.648c-70.328-96.594-182.539-153.818-302.023-154.021zm115.041 234.578C570.723 348.198 436.576 500 436.576 500l-68.35-19.088-38.177 46.797 68.35 20.32-41.256 40.026 45.566 35.097 30.789-30.172 32.02 65.887 36.33-44.336-18.473-69.58S652.392 414.86 633.621 354.064c-1.085-3.515-6.262-3.553-10.412-3.722zM153.169 517.99l-10.612 244.412 74.847-38.986c70.58 88.605 177.485 140.489 290.764 141.115 157.084-.421 297.2-98.86 350.86-246.496l-80.99-22.12c-43.442 111.215-150.471 184.558-269.87 184.93a290.699 290.699 0 01-215.387-96.689l66.75-34.767z"
          display="inline"
          opacity="1"
        ></path>
      </g>
    </svg>
  );
}

export default ReloadIcon;
