import {
  forcePurple,
  goldenrod,
  green,
  orange,
  red,
  shieldBlue,
} from '../../styles/colors';

import DiamondBase from './DiamondBase';
import HybridBase from './HybridBase';
import RoundBase from './RoundBase';

import CalculateIcon from './Icons/Calculate';
import ChargeIcon from './Icons/Charge';
import CloakIcon from './Icons/Cloak';
import DepleteIcon from './Icons/Deplete';
import DisarmIcon from './Icons/Disarm';
import EvadeIcon from './Icons/Evade';
import FocusIcon from './Icons/Focus';
import ForceChargeIcon from './Icons/ForceCharge';
import IonIcon from './Icons/Ion';
import JamIcon from './Icons/Jam';
import ReinforceIcon from './Icons/Reinforce';
import StrainIcon from './Icons/Strain';
import StressIcon from './Icons/Stress';
import TractorIcon from './Icons/Tractor';

export const tokenDataMap = {
  calculate: {
    color: green,
    base: RoundBase,
    icon: CalculateIcon,
  },
  charge: {
    color: goldenrod,
    base: HybridBase,
    icon: ChargeIcon,
  },
  cloak: {
    color: shieldBlue,
    base: DiamondBase,
    icon: CloakIcon,
  },
  deplete: {
    color: red,
    base: DiamondBase,
    icon: DepleteIcon,
  },
  disarm: { color: orange, base: RoundBase, icon: DisarmIcon },
  evade: { color: green, base: RoundBase, icon: EvadeIcon },
  focus: { color: green, base: RoundBase, icon: FocusIcon },
  force: {
    color: forcePurple,
    base: HybridBase,
    icon: ForceChargeIcon,
  },
  ion: { color: red, base: DiamondBase, icon: IonIcon },
  jam: { color: orange, base: RoundBase, icon: JamIcon },
  lock: {},
  reinforce: {
    color: green,
    base: RoundBase,
    icon: ReinforceIcon,
  },
  reinforceaft: {
    color: green,
    base: RoundBase,
    icon: ReinforceIcon,
  },
  strain: { color: red, base: DiamondBase, icon: StrainIcon },
  stress: { color: red, base: DiamondBase, icon: StressIcon },
  tractor: {
    color: orange,
    base: RoundBase,
    icon: TractorIcon,
  },
};

export const getTokenData = (tokenName = '') => {
  return tokenDataMap[tokenName.toLowerCase()];
};
