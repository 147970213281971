import React from 'react';
import styled, { keyframes } from 'styled-components';
import { createArrayWithLengthN, names } from '../../utils';

function HullShieldBarsCompact({ crit, maxHull, hull, maxShield, shield }) {
  const hullBars = () => {
    return createArrayWithLengthN(maxHull).map((_, i) => {
      return (
        <Bar
          key={i}
          className={names({ bar: i > hull - 1, hull: i <= hull - 1 })}
        />
      );
    });
  };

  const shieldBars = () => {
    return createArrayWithLengthN(maxShield).map((_, i) => {
      return (
        <Bar
          key={i}
          className={names({
            bar: i > shield - 1,
            shield: i <= shield - 1,
          })}
        />
      );
    });
  };

  const hullSet = hullBars();
  const shieldSet = shieldBars();

  return (
    <Container className={names({ crit })} hasShield={Boolean(maxShield)}>
      {maxShield > 0 && (
        <RowWrapper>
          <TopRow className="shield" max={maxShield}>
            {shieldSet}
          </TopRow>
        </RowWrapper>
      )}
      <RowWrapper>
        {maxShield > 0 ? (
          <BottomRow className="hull" max={maxHull}>
            {hullSet}
          </BottomRow>
        ) : (
          <Row className="hull" max={maxHull}>
            {hullSet}
          </Row>
        )}
      </RowWrapper>
    </Container>
  );
}

const gap = '4px';

const redAlert = keyframes`
  0% {
    border-color: black;
  }
  50% {
    border-color: red;
  }
  100% {
    border-color: black;
    }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: ${gap};
  padding: 4px;
  width: 100%;
  border: 1px solid white;
  border-radius: 4px;
  margin-right: 8px;
  background-color: #222;
  text-align: center;

  &.crit {
    animation-name: ${redAlert};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
`;

const RowWrapper = styled.div`
  /* display: flex; */
  /* flex-direction: row; */
  /* height: 100%; */

  .value {
    font-weight: bold;
    font-size: 20px;
  }
  .total {
    font-size: 14px;
  }

  .label {
    font-size: 10px;
  }
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: ${({ max }) => `repeat(${max}, 1fr)`};
  grid-template-rows: 8px;
  grid-gap: ${gap};
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
`;

const TopRow = styled(Row)`
  border-radius: 2px 2px 0 0;
`;
const BottomRow = styled(Row)`
  border-radius: 0 0 2px 2px;
`;

const Bar = styled.div`
  background-color: rgba(255, 0, 0, 0.1);
  transition: background-color 0.35s ease;

  &.hull {
    background-color: yellow;
  }

  &.shield {
    background-color: #3af;
  }
`;

export default HullShieldBarsCompact;
