import React from 'react';

function BarrelRollIcon({ color, ...rest }) {
  const fillColor = color || 'white';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...rest}
    >
      <path
        fill={fillColor}
        fillOpacity="1"
        d="M506.463 266.012c-140.395.716-176.718 151.681-156.248 215.824 13.417 42.045 40.025 70.813 40.025 70.813 38.163-3.233 69.65-10.403 100.985-25.555 0 0-61.27-41.564-58.498-93.596 2.507-47.088 29.25-78.201 76.355-79.125 47.106-.923 77.585 34.791 77.277 85.284-.308 50.492-12.93 86.513-68.35 112.683-55.418 26.17-119.764 26.786-143.163 27.094-22.205.292-87.69-8.668-94.682-9.629l11.553-59.336-198.89 68.658 158.25 140.086 12.019-61.732c14.699 2.607 198.85 33.33 316.76-44.867C750.38 499.52 681.335 265.12 506.462 266.012zm242.15 257.08l-12.51 61.207-63.537-10.406c-23.089 33.116-60.367 53.618-97.906 73.89 38.242 7.158 93.298 13.535 145.494 14.551l-14.644 71.657 201.664-71.737z"
        display="inline"
      ></path>
    </svg>
  );
}

export default BarrelRollIcon;
