import React from 'react';

function SlamIcon({ color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...rest}
    >
      <g>
        <path
          fill={color || 'white'}
          fillOpacity="1"
          stroke="red"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="1.1"
          d="M496.368 80.344L270.298 471.91h101.565l124.505-215.65 124.505 215.65h101.564L496.368 80.344zm0 255.997l-226.07 391.565h137.644v133.149h176.852v-133.15h137.643L496.368 336.342z"
          display="inline"
          transform="matrix(1.10776 0 0 .96876 -49.859 38.392)"
        ></path>
      </g>
    </svg>
  );
}

export default SlamIcon;
