import React from 'react';

function FocusIcon({ color }) {
  return (
    <path
      fill={color || '#fff'}
      d="M508.188 352.357c-3.65.008-7.345.057-11.08.147-239.077 5.784-285.35 156.172-285.35 156.172s27.588 137.154 310.414 140.748c179.25 2.278 275.71-134.963 275.71-134.963S738.1 351.92 508.189 352.357zm103.04 60.512c105.186 31.842 135.715 106.465 135.715 106.465s-63.475 75.076-182.308 90.082a137.234 133.485 0 0072.986-117.844 137.234 133.485 0 00-26.392-78.703zm-223.78 2.922a137.234 133.485 0 00-24.294 75.781 137.234 133.485 0 0065.739 113.842C279.372 583.51 262.71 515.03 262.71 515.03s22.949-66.923 124.736-99.238zm154.16 3.672a46.694 46.694 0 0146.695 46.695 46.694 46.694 0 01-46.696 46.694 46.694 46.694 0 01-46.695-46.694 46.694 46.694 0 0146.695-46.695z"
    ></path>
  );
}

export default FocusIcon;
