import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
// import { Tooltip } from '@mui/material';

import { getArcSvg } from '../images/arcs/arc';
import { convertCase, names } from '../utils';
import colors from '../styles/colors';
import { generateOpacityArray } from '../images/icons/statUtils';
import { GameSettingsContext } from '../context/gameSettings';
import StatRing from './StatRing';
import {
  getIconColor,
  getRingOpacityArray,
  getStatIcon,
  wpnArcs,
} from './statIconUtils';
import RechargeBlock from './RechargeBlock';

const scale = 0.6;
const translate = (1000 * (1 - scale)) / 2;

const StatIcon = ({
  type,
  sign,
  number,
  recharge,
  horizontal,
  inverted,
  showRing,
  size,
  sx,
  overrideColor,
  ...rest
}) => {
  const { gameSettings } = useContext(GameSettingsContext);
  const [array, setArray] = useState(getRingOpacityArray(type));
  const arc = getArcSvg(type, overrideColor);
  const statIcon = getStatIcon(type, overrideColor);
  const iconColor = getIconColor(type);

  const icon = statIcon || arc;

  useEffect(() => {
    if (gameSettings.showAnimations) {
      setTimeout(() => {
        setArray(generateOpacityArray());
      }, 1900);
    }
  }, [array, gameSettings.showAnimations]);

  const showNumber = number !== undefined && number !== null;

  const typeConvert = convertCase(type);

  const classNamesForContainer = names(
    {
      horizontal,
      inverted,
      arc: wpnArcs.includes(typeConvert.initialCaps),
    },
    typeConvert.kebabCase + ' stat-icon'
  );

  return (
    <StatContainer
      className={classNamesForContainer}
      style={{ fontSize: size, ...sx }}
      {...rest}
    >
      {/* <Tooltip title={convertCase(type).initialCaps}> */}
      <SvgContainer
        xmlns="http://www.w3.org/2000/svg"
        width={size || '100%'}
        height={size || '100%'}
        version="1.1"
        viewBox="0 0 1000 1000"
        className={names(
          {
            horizontal: horizontal,
            vertical: !horizontal,
          },
          typeConvert.kebabCase
        )}
        {...rest}
      >
        {showRing && <StatRing opacityArray={array} fillColor={iconColor} />}
        <g
          transform={
            showRing
              ? `translate(${translate} ${translate}) scale(${scale})`
              : null
          }
        >
          {icon}
        </g>
      </SvgContainer>
      {showNumber && (
        <Num>
          <p>
            {sign && <span className="sign">{sign}</span>}
            {number}
          </p>
          <RechargeBlock recharge={recharge} fill={iconColor} />
        </Num>
      )}
      {/* </Tooltip> */}
    </StatContainer>
  );
};

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* svg {
    width: 1em;
    height: 1em;
  } */

  &.horizontal {
    flex-direction: row;
    align-items: center;

    .stat-ring {
      transition: transform 0.5s;
      transform-origin: 50% 50%;
      transform: rotate(-90deg);
    }
  }

  &.inverted {
    flex-direction: column-reverse;
    align-items: center;

    .stat-ring {
      transition: transform 0.5s;
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  }

  &.shield {
    color: ${colors.shieldBlue};
    svg {
      fill: ${colors.shieldBlue};
    }
  }

  &.hull {
    color: ${colors.hullYellow};
    svg {
      fill: ${colors.hullYellow};
    }
  }
  &.agility {
    color: ${colors.agilityGreen};
    svg {
      fill: ${colors.agilityGreen};
    }
  }
  &.force {
    color: ${colors.forcePurple};
    svg {
      fill: ${colors.forcePurple};
    }
  }
  &.charge {
    color: ${colors.goldenrod};
    svg {
      fill: ${colors.goldenrod};
    }
  }
  &.arc {
    color: ${colors.weaponRed};
    svg {
      fill: ${colors.weaponRed};
    }
  }
`;

const SvgContainer = styled.svg`
  &.horizontal {
    flex-direction: row;
    flex-direction: column;
    align-items: center;

    .stat-ring {
      transition: transform 0.5s;
      transform-origin: 50% 50%;
      transform: rotate(-90deg);
    }
  }
`;

const Num = styled.div`
  position: relative;
  font-family: 'Teko', 'roboto', sans-serif;
  font-size: 0.7em;
  font-weight: 700;

  p {
    line-height: 1;

    span {
      font-weight: 400;
      margin-top: -20px;
    }
  }

  .sign {
    margin-right: 5px;
  }
  /* svg {
    width: 0.25em;
    height: 0.22em;
    fill: ${colors.white};
    margin-left: 0.15em;
  } */
`;

export default StatIcon;
