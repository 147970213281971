import React from 'react';

function IonIcon({ color }) {
  return (
    <path
      fill={color || '#fff'}
      d="M508.803 240.512l-11.344 25.84a231.856 231.856 0 00-180.13 89.908l-45.624 12.224 12.473 46.541a231.856 231.856 0 00-16.035 83 231.856 231.856 0 0029.455 111.887l-24.487 8.399 27.422-3.026a231.856 231.856 0 00170.19 112.352l31.85 31.851 32.298-32.299A231.856 231.856 0 00699.518 616.08l19.035 16.59-16.051-21.856a231.856 231.856 0 0029.353-112.789 231.856 231.856 0 00-18.697-90.158l11.475-42.826-44.278-11.863a231.856 231.856 0 00-176.625-86.74l5.073-25.926zM500 296.3a201.725 201.725 0 01201.727 201.724 201.725 201.725 0 01-19.159 85.653l-16.236-22.106a178.109 178.109 0 0011.777-63.547A178.109 178.109 0 00500 319.918a178.109 178.109 0 00-6.828.488l4.682-23.937A201.725 201.725 0 01500 296.3zm-16.252 1.28l-10.762 24.513a178.109 178.109 0 00-151.095 175.931 178.109 178.109 0 0027.156 94.243l-22.438 7.695a201.725 201.725 0 01-28.334-101.938A201.725 201.725 0 01483.748 297.58zM500 339.462a158.564 158.564 0 01158.564 158.562 158.564 158.564 0 01-6.369 44.301l-42.115-57.338-22.799 21.686-14.318 1.066-5.076 17.387-24.684 23.316 59.662-16.586 39.994 34.85A158.564 158.564 0 01500 656.59a158.564 158.564 0 01-115.635-50.557l70.88-7.822-7.384-30.588 6.237-12.934-12.518-13.09-7.85-33.035-15.468 59.965-49.951 17.131a158.564 158.564 0 01-26.873-87.635 158.564 158.564 0 01121.806-153.742l-28.101 64.01 30.18 8.9 8.081 11.87 17.6-4.297 32.533 9.718-44.2-43.379 9.94-50.798a158.564 158.564 0 0110.723-.844zm0 118.896a39.667 39.667 0 00-39.666 39.666A39.667 39.667 0 00500 537.693a39.667 39.667 0 0039.668-39.668A39.667 39.667 0 00500 458.36zm158.088 121.62l18.248 15.9a201.725 201.725 0 01-176.334 103.873 201.725 201.725 0 01-166.25-88.133l27.125-2.994a178.109 178.109 0 00139.125 67.51 178.109 178.109 0 00158.086-96.156z"
    ></path>
  );
}

export default IonIcon;
