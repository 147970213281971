export const DEV_MODE = process.env.REACT_APP_DEV === 'true';

export const X_WING_BACKGROUND = 'X_WING_BACKGROUND';
export const X_WING_CUSTOM_BACKGROUND = 'X_WING_CUSTOM_BACKGROUND';
export const X_WING_GAME_CODE = 'X_WING_GAME_CODE';
export const X_WING_PLAYER_NAME = 'X_WING_PLAYER_NAME';
export const X_WING_SOCKET_ID = 'X_WING_SOCKET_ID';
export const REJOIN_GAME = 'REJOIN_GAME';

export const getDefaultPlayerName = () => {
  const savedName = localStorage.getItem('X_WING_PLAYER_NAME');

  if (savedName) return savedName;

  return '';
};

export const getDefaultGameCode = () => {
  if (DEV_MODE) return 'TEST';

  return '';
};

export const shuffleArray = (arr) => {
  let shuffleArr = arr?.slice();
  let i = 0;
  let j = 0;
  let temp = null;
  for (i = shuffleArr.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = shuffleArr[i];
    shuffleArr[i] = shuffleArr[j];
    shuffleArr[j] = temp;
  }

  return shuffleArr;
};

export const capitalize = (str) => {
  return str?.[0].toUpperCase() + str?.slice(1);
};

export const convertToCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};

export const matchQuickBuildCard = (card1, card2) => {
  if (card1.name === card2.name) {
    return card1.upgradeList?.join(',') === card2.upgradeList?.join(',');
  }

  return false;
};

const shipNameRE = /(?<name>.*)\s+\(.*\)/;
const removeFaction = (shipName) =>
  shipName.match(shipNameRE)?.groups?.name ?? shipName;

export const getShipsFromName = (ships, shipMap) => {
  if (!ships || !shipMap) return [];

  return ships.map((ship) => ({ ...shipMap[removeFaction(ship)], path: ship }));
};

export const createArrayWithLengthN = (n, fillValue) =>
  Array(n).fill(fillValue || null);

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const camelCase = 'camelCase';
const kebabCase = 'kebabCase';
const snakeCase = 'snakeCase';
const upperSnake = 'upperSnake';
const pascalCase = 'pascalCase';
const initialCaps = 'initialCaps';
const lowerCase = 'lowerCase';
const upperCase = 'upperCase';

const determineCase = (str) => {
  if (!str) return str;
  str = str?.trim();
  const lowerRgx = /[a-z]/;
  const upperRgx = /[A-Z]/;
  const dash = '-';
  const _ = '_';
  const space = ' ';

  const lowerFlag = lowerRgx.test(str);
  const upperFlag = upperRgx.test(str);
  const dashFlag = str.includes(dash);
  const _Flag = str.includes(_);
  const spaceFlag = str.includes(space);

  if (lowerRgx.test(str?.[0])) {
    if (spaceFlag) {
      return lowerCase;
    } else if (upperFlag) {
      return camelCase;
    } else if (dashFlag) {
      return kebabCase;
    } else if (_Flag) {
      return snakeCase;
    }
  } else {
    if (lowerFlag) {
      if (spaceFlag) {
        return initialCaps;
      }
      return pascalCase;
    } else {
      if (spaceFlag) {
        return upperCase;
      } else if (_Flag) {
        return upperSnake;
      }
    }
  }

  return null;
};

function parseStr(str) {
  const type = determineCase(str);
  const caseSplits = [camelCase, pascalCase];

  if (caseSplits.includes(type)) {
    let outStr = '';
    for (let i = 0; i < str.length; i++) {
      if (/[A-Z]/.test(str[i]) && i > 0) {
        outStr += ` ${str[i]?.toLowerCase()}`;
      } else {
        outStr += str[i]?.toLowerCase();
      }
    }

    return outStr.split(' ');
  } else {
    let splitter = ' ';
    switch (type) {
      case kebabCase:
        splitter = '-';
        break;
      case snakeCase:
      case upperSnake:
        splitter = '_';
        break;
      default:
        break;
    }

    return str?.toLowerCase().split(splitter);
  }
}

export const convertCase = (str) => {
  if (!str)
    return {
      camelCase: str,
      kebabCase: str,
      snakeCase: str,
      upperSnake: str,
      pascalCase: str,
      initialCaps: str,
      lowerCase: str,
      upperCase: str,
    };
  const arr = parseStr(str);

  const initCaps = arr?.map((w) => w?.[0].toUpperCase() + w?.slice(1));
  const pascalCase = initCaps?.join('');
  const camelCase = pascalCase?.[0]?.toLowerCase() + pascalCase?.slice(1);
  const kebabCase = arr?.join('-');
  const snakeCase = arr?.join('_');
  const upperSnake = snakeCase?.toUpperCase();
  const initialCaps = initCaps?.join(' ');
  const lowerCase = arr?.join(' ');
  const upperCase = lowerCase?.toUpperCase();

  return {
    camelCase,
    kebabCase,
    snakeCase,
    upperSnake,
    pascalCase,
    initialCaps,
    lowerCase,
    upperCase,
  };
};

export const names = (conditionalClasses = {}, defaultClass = '') => {
  let classNames = [defaultClass];

  for (let key in conditionalClasses) {
    if (conditionalClasses[key]) {
      classNames.push(key);
    }
  }

  return classNames.join(' ');
};
