import React from 'react';

function TractorIcon({ color, ...rest }) {
  return (
    <path
      fill={color || '#fff'}
      d="M500 288.238a714.836 714.836 0 00-184.328 24.354l20.248 71.951h325.332l20.445-72.647A714.834 714.834 0 00500 288.238zm0 130c-50.418.248-98.668 5.83-147.912 16.65l21.29 75.655h250.415l21.473-76.303C596.864 423.82 549.51 418.458 500 418.238zm0 124c-39.178.086-72.283 3.393-110.92 9.887l23.195 82.418h172.621l23.31-82.82c-37.754-6.213-69.945-9.385-108.206-9.485zm0 126c-27.653.21-45.27 2.023-72.713 5.432l21.633 76.873h99.332l21.77-77.357c-26.56-3.145-43.277-4.797-70.022-4.948z"
      {...rest}
    ></path>
  );
}

export default TractorIcon;
