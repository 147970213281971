import empire from './empire.png';
import firstOrder from './firstOrder.png';
import rebel from './rebel.png';
import republic from './republic.png';
import resistance from './resistance.png';
import scum from './scum.png';
import separatist from './separatist.png';

const factionMap = {
  empire,
  'Galactic Empire': empire,
  firstOrder,
  'First Order': firstOrder,
  rebel,
  Rebellion: rebel,
  republic,
  'Galactic Republic': republic,
  resistance,
  Resistance: resistance,
  scum,
  'Scum & Villainy': scum,
  separatist,
  'Separatist Alliance': separatist,
};

export default factionMap;
