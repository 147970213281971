import React from 'react';
import styled from 'styled-components';

import { names } from '../utils';


const triangleWidth = 100;
const triangleHeight = 87;
const gap = 15;

const col2Left = triangleWidth + gap;

function RechargeArrows({ recharge = 0, fill }) {
  const fillColor = fill;

  return (
    <svg
      viewBox={`0 0 ${2 * triangleWidth + gap} ${3 * triangleHeight + 2 * gap}`}
    >
      {recharge > 0 && (
        <path
          fill={fillColor}
          d={`M ${
            triangleWidth / 2
          },0 0,${triangleHeight} H ${triangleWidth} Z`}
        />
      )}
      {recharge > 1 && (
        <path
          fill={fillColor}
          d={`M ${triangleWidth / 2},${triangleHeight + gap} 0,${
            2 * triangleHeight + gap
          } H ${triangleWidth} Z`}
        />
      )}
      {recharge > 2 && (
        <path
          fill={fillColor}
          d={`M ${triangleWidth / 2},${2 * (triangleHeight + gap)} 0,${
            3 * triangleHeight + 2 * gap
          } H ${triangleWidth} Z`}
        />
      )}

      {recharge > 3 && (
        <path
          fill={fillColor}
          d={`M ${
            triangleWidth / 2 + col2Left
          },0 ${col2Left},${triangleHeight} H ${col2Left + triangleWidth} Z`}
        />
      )}
      {recharge > 4 && (
        <path
          fill={fillColor}
          d={`M ${triangleWidth / 2 + col2Left},${
            triangleHeight + gap
          } ${col2Left},${2 * triangleHeight + gap} H ${
            col2Left + triangleWidth
          } Z`}
        />
      )}
      {recharge > 5 && (
        <path
          fill={fillColor}
          d={`M ${triangleWidth / 2 + col2Left},${
            2 * (triangleHeight + gap)
          } ${col2Left},${3 * triangleHeight + 2 * gap} H ${
            col2Left + triangleWidth
          } Z`}
        />
      )}
    </svg>
  );
}

function RechargeBlock({ fillColor, recharge, relative }) {
  return (
    <RechargeArrowContainer className={names({ relative })}>
      <RechargeArrows recharge={recharge} fill={fillColor} />
    </RechargeArrowContainer>
  );
}

const RechargeArrowContainer = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0.03em;
  padding-left: 0.04em;

  &.relative {
    position: relative;
    left: unset;
    top: unset;
  }

  svg {
    height: 0.85em;
    width: 0.85em;
  }
`;

export default RechargeBlock;
