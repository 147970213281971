import { Tooltip, Zoom } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

function Button({ children, type, toolTipText, placement, ...rest }) {
  const toolTipFlag = toolTipText !== undefined;

  if (toolTipFlag) {
    switch (type) {
      case 'secondary':
        return (
          <Tooltip
            title={toolTipFlag ? toolTipText : undefined}
            placement={placement || 'right'}
            TransitionComponent={Zoom}
            arrow
          >
            <Secondary {...rest}>{children}</Secondary>
          </Tooltip>
        );
      case 'text':
        return (
          <Tooltip
            title={toolTipFlag ? toolTipText : undefined}
            placement={placement || 'right'}
            TransitionComponent={Zoom}
            arrow
          >
            <Text {...rest}>{children}</Text>
          </Tooltip>
        );
      case 'token':
        return (
          <Tooltip
            title={toolTipFlag ? toolTipText : undefined}
            placement={placement || 'right'}
            TransitionComponent={Zoom}
            arrow
          >
            <Token {...rest}>{children}</Token>
          </Tooltip>
        );
      default:
        return (
          <Tooltip
            title={toolTipFlag ? toolTipText : undefined}
            placement={placement || 'right'}
            TransitionComponent={Zoom}
            arrow
          >
            <Primary {...rest}>{children}</Primary>
          </Tooltip>
        );
    }
  }
  switch (type) {
    case 'secondary':
      return <Secondary {...rest}>{children}</Secondary>;
    case 'text':
      return <Text {...rest}>{children}</Text>;
    case 'token':
      return <Token {...rest}>{children}</Token>;
    default:
      return <Primary {...rest}>{children}</Primary>;
  }
}

const DefaultButton = styled.button`
  border: 3px solid white;
  border-radius: 8px;
  font-weight: bold;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  .token-wrapper {
    margin-top: 3px;
  }
`;

const Primary = styled(DefaultButton)`
  color: black;
  background: yellow;
`;

const Secondary = styled(DefaultButton)`
  color: yellow;
  background: black;
  border: 2px solid white;
  &:hover {
    background: #444;
  }
`;

const Text = styled(DefaultButton)`
  color: ${({ color }) => color || '#ccc'};
  background: ${({ background }) => background || 'transparent'};
  border: 2px solid #666;

  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #666;
  }
`;

const Token = styled(DefaultButton)`
  color: ${({ color }) => color || '#ccc'};
  background: ${({ background }) => background || 'transparent'};
  border: 2px solid #666;
  &:hover {
    background: #666;
  }
`;

export default Button;
