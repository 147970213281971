import styled, { keyframes } from 'styled-components';
import {
  agilityGreen,
  black,
  forcePurple,
  goldenrod,
  navy,
  weaponRed,
  white,
} from '../../styles/colors';

export const redAlert = keyframes`
  0% {
    border-color: black;
  }
  50% {
    border-color: red;
  }
  100% {
    border-color: black;
  }
`;

export const redAlertBackground = keyframes`
  0% {
    background-color: transparent;
  }
  50% {
    background-color: red;
  }
  100% {
    background-color: transparent;
  }
`;

const turnHighlight = (flashColor) => keyframes`
  0% {
    border: 4px solid;
    border-color: black;
  }
  50% {
    border: 4px solid;
    border-color: ${flashColor};
  }
  100% {
    border: 4px solid;
    border-color: black;
  }
`;

export const Container = styled.div`
  display: flex;
  margin-bottom: 28px;
  border-radius: 12px;
  padding-right: 52px;

  &.is-opponent-card {
    button,
    svg,
    .hull,
    .shield {
      cursor: not-allowed;
    }
  }

  &.turn-highlight {
    animation-name: ${turnHighlight('lime')};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  &.turn-highlight.engagement {
    animation-name: ${turnHighlight('red')};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  &.main-ship {
    margin-bottom: 0;
  }
`;

export const TokenContainer = styled.div`
  background-color: green;
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: #333;
  border: 2px solid ${goldenrod};
  border-radius: 6px;
  margin-right: 8px;

  img,
  svg {
    width: 40px;
    height: 40px;
  }
`;

export const TokenIconContainer = styled.div`
  border-radius: 4px;
  padding: 4px;
  padding-bottom: 0;
  cursor: pointer;

  &:hover {
    background-color: #666;
  }
`;

export const CardTopRow = styled.div`
  position: relative;
  padding: 2px 8px 0 8px;
  display: grid;
  grid-template-columns: 35px auto 35px;
  grid-template-rows: auto 20px;
  justify-items: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  background-color: #333;
  border-radius: 8px 0 0 0;
  border-bottom: 2px solid ${({ factionColor }) => factionColor};

  .initiative {
    color: ${goldenrod};
    font-size: 36px;
    font-family: 'Teko', 'roboto', sans-serif;
  }

  .name {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 20px;
    text-shadow: 2px 2px 2px black;
    max-width: 200px;

    .limited-dots {
      font-size: 24px;
      margin-right: 6px;
    }
  }

  .call-sign {
    font-size: 12px;
    display: flex;
    justify-content: center;
    color: white;
    font-style: italic;

    height: 0;
    width: 300px;
    border-bottom: 24px solid #123;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    position: relative;
    grid-column: 1 / span 3;
    margin-top: -4px;

    p {
      position: absolute;
      bottom: -22px;
    }
  }
`;

export const CardMainSection = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto auto;
`;

export const PilotMain = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  min-width: 450px;
`;

export const PilotContainer = styled.div`
  position: relative;
  min-width: 200px;
  min-height: 190px;
  border: 3px solid ${({ factionColor }) => factionColor};
  border-radius: 8px 0 0 8px;
  overflow: visible;
  display: grid;
  grid-template-rows: auto 1fr auto auto;

  &.crit {
    animation-name: ${redAlert};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  &:hover {
    .info {
      opacity: 0.3;
    }
  }
`;

export const ShipName = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 200;
  border-top: 1px solid #333;
  border-radius: 3px;
  font-style: italic;
  margin-top: -1px;
  margin-left: 30px;
  background-color: #333;
`;

export const BottomLeftContainer = styled.div`
  z-index: 101;
  position: absolute;
  bottom: -4px;
  left: -4px;
  display: flex;
`;

export const ShipIndex = styled.div`
  place-self: center;
  border-radius: 6px;
  padding: 0 6px;
  font-weight: bold;
  font-size: 20px;

  color: ${({ black }) => (black ? white : 'black')};
  background-color: ${({ black }) => (black ? 'black' : white)};
  border: 1px solid ${({ black }) => (black ? white : 'black')};
`;

export const ShipSilhouette = styled.img`
  max-height: 32px;
  max-width: 42px;
  padding: 2px;
  border: 2px solid ${({ factionColor }) => factionColor};
  border-radius: 4px;
  background-color: black;
  margin-left: 2px;
`;

export const AbilitiesContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  font-size: 16px;
  min-width: 280px;
  min-height: 120px;
  justify-content: space-between;
  max-width: 320px;
  grid-column: 1 / span 1;
  border-bottom: 2px solid ${({ factionColor }) => factionColor};
  background-color: #efefef;
  color: black;
  box-shadow: inset 0px 0px 12px 2px black;
  text-align: center;

  &.no-ability {
    .pilot-ability {
      color: #aaa;
      text-align: center;
      width: 320px;
      font-style: italic;
    }
  }

  .pilot-ability {
    padding: 4px 8px 4px 0;
    padding: 12px;
    width: 100%;
  }
  .ship-ability {
    border-top: 3px solid ${({ factionColor }) => factionColor};
    padding: 4px 81px 4px 0;
    padding: 12px;
  }
`;

export const ShipStatsArea = styled.div`
  background-color: ${black};
  padding: 8px 36px 0px 36px;
  grid-column: 1 / span 1;
  cursor: context-menu;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  p {
    font-family: 'Teko', 'roboto', sans-serif;
    font-size: 30px;
  }

  .wpn-container {
    display: flex;
    .wpn-col {
      margin-right: 8px;
    }
  }
  .wpn {
    color: ${weaponRed};
  }
  .agl {
    color: ${agilityGreen};
  }
  .force {
    /* display: grid; */
    /* grid-template-columns: 1fr auto; */
    color: ${forcePurple};

    p {
      grid-column: 1 / span 1;
    }

    .recharge-arrows {
      width: 24px;
      height: 24px;
      align-self: start;
    }
  }

  .charge {
    /* display: grid; */
    /* grid-template-columns: 1fr auto; */
    color: ${goldenrod};

    p {
      grid-column: 1 / span 1;
    }

    .recharge-arrows {
      width: 24px;
      height: 24px;
      align-self: start;
    }
  }
`;

export const StatColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    height: 30px;
  }
  .bottom {
    height: 30px;
  }
`;

export const UpgradeSection = styled.div`
  display: grid;
  grid-template-rows: repeat(${({ big }) => (big ? 4 : 3)}, auto);
  grid-auto-flow: column;
  grid-template-columns: auto;
  margin-right: 8px;
  .force {
    display: flex;
    align-items: flex-start;
    .recharge-arrows {
      padding-top: 6px;
      width: 20px;
      height: 100%;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 2px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const ForceTokenContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: top;
  gap: 4px;
  margin-right: 8px;
  cursor: pointer;

  .force-recharge {
    color: ${forcePurple};
    font-size: 30px;
  }
`;

export const CritEffectsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(${({ big }) => (big ? 4 : 3)}, auto);
  grid-auto-flow: column;
  grid-template-columns: auto;
  margin-right: 8px;
  gap: 4px;
`;

export const InfoButton = styled.button`
  position: absolute;
  right: -30px;
  top: calc(50% - 12px);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
  color: white;
  background-color: #333;

  svg {
    cursor: pointer !important;
  }

  &:hover {
    opacity: 1 !important;
  }
`;

export const ModalContainer = styled.div`
  position: relative;
  margin: 8% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: ${navy};
  width: fit-content;
  padding: 52px 48px;
  border-radius: 12px;
  border: 2px solid ${({ factionColor }) => factionColor};
  text-align: center;

  a {
    color: gold;
    font-size: 20px;
    font-size: 16px;
    margin: 12px 0;
    &:hover {
      text-decoration: underline;
    }
  }

  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
`;

export const PilotCardImg = styled.img`
  width: 400px;
  height: 500px;
  object-fit: contain;
  margin: 0 auto 12px auto;
`;

export const DestroyedContainer = styled.div`
  z-index: 100;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 0, 0, 0.65);
    border-radius: 8px 0 0 8px;
  }
  p {
    color: ${weaponRed};
    font-size: 84px;
    font-family: 'Teko', 'roboto', sans-serif;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    text-shadow: 2px 2px 2px black;
  }
`;
