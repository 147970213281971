import React from 'react';

function StressIcon({ color, ...rest }) {
  return (
    <path
      fill="red"
      d="M408.533 257.479l28.906 308.19h125.122l28.906-308.19H408.533zm28.906 375.638v109.404h125.122V633.117H437.439z"
    ></path>
  );
}

export default StressIcon;
