import BWingSilhouette from '../../images/svgs/BWingSilhouette';
import XWingSilhouette from '../../images/svgs/XWingSilhouette';

export const upgradeSortFunction = (a, b) => {
  const aTwoSided = !!a.back;
  const bTwoSided = !!b.back;

  if (aTwoSided && !bTwoSided) return -1;
  if (!aTwoSided && bTwoSided) return 1;

  return b.maxCharges || 0 - a.maxCharges || 0;
};

const addActionRgx =
  /\+\[Action:\s(?<action1>\w+(?:\s\w+)*)\](\s►\s\[Action:\s(?<action2>\w+(?:\s\w+)*)\])*/;

const getActionsFromUpgrade = (upgrade) => {
  const actions = [];
  const hasBack = upgrade.hasOwnProperty('back');
  const useBackAbility =
    hasBack && upgrade.hasOwnProperty('isFaceUp') && !upgrade.isFaceUp;
  const abilityToUse = useBackAbility ? upgrade.back.ability : upgrade.ability;

  if (addActionRgx.test(abilityToUse)) {
    const lines = abilityToUse.split('\n');

    lines.forEach((line) => {
      if (line.trim()) {
        const matches = line.match(addActionRgx);
        if (matches) {
          const { action1, action2 } = matches.groups;
          if (action2) {
            actions.push(`${action1} links to ${action2}`);
          } else {
            actions.push(action1);
          }
        }
      }
    });
  }

  return actions;
};

export const getActionsList = (actions, pilot={}, ) => {
  const upgrades = pilot.upgrades || [];
  const pilotActions = pilot.actions || [];
  let outputActions = [...pilotActions, ...actions];

  upgrades.forEach((upgrade) => {
    const upgradeActions = getActionsFromUpgrade(upgrade);
    outputActions = [...outputActions, ...upgradeActions];
  });

  return outputActions;
};

export const Silhouette = ({
  upgrade,
  callSignStyle,
  mini,
  width,
  height,
  ...rest
}) => {
  const showXwingFoil = ['Servomotor S-Foils', 'Integrated S-Foils'].includes(
    upgrade.title
  );

  if (showXwingFoil)
    return (
      <XWingSilhouette
        className="x-wing"
        width={width || '100'}
        height={height || '50'}
        stroke={callSignStyle.outline}
        fill={callSignStyle.color}
        open={upgrade.isFaceUp}
        {...rest}
      />
    );
  if (upgrade.title.includes('Stabilized S-Foils'))
    return (
      <BWingSilhouette
        className="b-wing"
        width={mini ? '60' : width || '220'}
        height={mini ? '30' : height || '110'}
        stroke={callSignStyle.outline}
        fill={callSignStyle.color}
        open={upgrade.isFaceUp}
        {...rest}
      />
    );

  return null;
};

const valueModRgx = /(?<sign>[-+])(?<mod>\d+) (?<value>\w{4,})/;
const modKeys = ['hull', 'shield', 'force', 'energy', 'agility', 'attack'];

export const parseUpgradeText = (text) => {
  const actions = [];
  const bonuses = [];

  const lines = text.split('\n');
  const linesToRemove = [];

  lines.forEach((line, lineIndex) => {
    const valueModMatch = valueModRgx.exec(line);
    if (
      valueModMatch &&
      modKeys.includes(valueModMatch?.groups?.value?.toLowerCase())
    ) {
      linesToRemove.push(lineIndex);
      return bonuses.push({
        sign: valueModMatch?.groups?.sign,
        mod: parseInt(valueModMatch?.groups?.mod),
        value: valueModMatch?.groups?.value.toLowerCase(),
      });
    }

    const actionMatch = addActionRgx.exec(line);
    if (actionMatch) {
      linesToRemove.push(lineIndex);
      if (actionMatch?.groups?.action2) {
        actions.push(
          `${actionMatch?.groups?.action1} links to ${actionMatch?.groups?.action2}`
        );
      } else {
        actions.push(actionMatch?.groups?.action1);
      }
    }
  });

  const newText = lines
    .filter((_, index) => !linesToRemove.includes(index))
    .join('\n');

  return {
    text: newText,
    actions: actions,
    bonuses: bonuses,
  };
};
