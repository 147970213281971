import colors from '../styles/colors';
import { convertCase } from '../utils';

const redRegex = /Red$/;
const purpleRegex = /Purple$/;
const linksToRegex = /\slinks to\s/;

export const testActionForLinks = (action) => linksToRegex.test(action);

const getColor = (action) => {
  if (redRegex.test(action)) return colors.red;

  if (purpleRegex.test(action)) return colors.forcePurple;

  return colors.white;
};

const getKey = (action) => {
  return convertCase(action.replace(' Red', '').replace(' Purple', ''))
    ?.camelCase;
};

export const cleanLabel = (string) => {
  return string.replace(' Red', ' Stressed').replace(' Purple', ' Force');
};

const getActionIcons = (action) => {
  const linked = testActionForLinks(action);

  if (linked) {
    const [action1, action2] = action.split(linksToRegex);

    // return [...getActionData(action1), ...getActionData(action2)];
    return [
      { key: getKey(action1), color: getColor(action1) },
      { key: getKey(action2), color: getColor(action2) },
    ];
  }

  const color = getColor(action);
  return [{ key: getKey(action), color }];
};

export const getActionData = (action) => {
  const icons = getActionIcons(action);

  return {
    action,
    key: getKey(action),
    label: cleanLabel(action),
    icons,
  };
};
