import React from 'react';

function EvadeIcon({ color, ...rest }) {
  return (
    <path
      fill={color || '#000'}
      fillOpacity="1"
      stroke="none"
      strokeDasharray="none"
      strokeMiterlimit="4"
      strokeOpacity="1"
      strokeWidth="4.895"
      d="M478.817 220.9l5.202 206.63 63.496-45.717c11.997 16.49 28.293 24.333 28.3 70.247.01 74.342-62.207 94.419-63.595 179.151-.803 48.991 66.938 144.915 94.439 147.888l22.764-83.246c-8.624.412-42.442-46.45-42.124-72.505.743-60.949 71.024-125.892 66.645-183.68-3.007-39.69-19.046-84.35-37.377-107.575l53.272-38.355zm-79.53 223.005c-38.176 0-69.124 30.946-69.126 69.121 0 38.178 30.948 69.126 69.126 69.126 38.177 0 69.126-30.948 69.125-69.126-.002-38.175-30.95-69.121-69.125-69.12z"
    ></path>
  );
}

export default EvadeIcon;
