export const agilityGreen = 'rgb(85, 180, 33)';
export const black = '#000';
export const darkRed = '#800';
export const forcePurple = '#aa76b6';
export const goldenrod = '#fcc200';
export const lightGray = '#ccc';
export const green = '#33b338';
export const hullYellow = 'yellow';
export const lime = 'lime';
export const navy = '#0f142f';
export const orange = 'orange';
export const rebelRed = '#f73333';
export const red = '#f70c0b';
export const shieldBlue = '#3af';
export const weaponRed = 'rgb(255, 3, 20)';
export const white = '#fff';

const colors = {
  agilityGreen,
  black,
  darkRed,
  forcePurple,
  goldenrod,
  green,
  hullYellow,
  lightGray,
  lime,
  navy,
  orange,
  rebelRed,
  red,
  shieldBlue,
  weaponRed,
  white,
};

export default colors;
