import React from 'react';

function CalculateIcon({ color, ...rest }) {
  return (
    <path
      fill={color || '#fff'}
      strokeWidth="0.405"
      d="M499.999 293.254a206.746 206.746 0 00-157.17 72.59l-130.147 62.022-.913 138.68 123.865 60.546 24.734-24.735 37.762 37.762-23.672 23.673a206.746 206.746 0 00125.54 42.954 206.746 206.746 0 00125.294-42.757l-23.107-23.107 37.762-37.762 24.118 24.117 124.166-60.69-.912-138.68-126.396-60.235-25.823 25.823-26.764-26.764 24.797-24.797a206.746 206.746 0 00-95.372-45.103l-3.085 35.263-37.707-3.3 3.106-35.497a206.746 206.746 0 00-.076-.002zm0 65.61A141.136 141.136 0 01641.134 500 141.136 141.136 0 01500 641.137 141.136 141.136 0 01358.863 500 141.136 141.136 0 01500 358.863zm0 30.515a110.62 110.62 0 00-110.62 110.622A110.62 110.62 0 00500 610.621 110.62 110.62 0 00610.619 500a110.62 110.62 0 00-110.62-110.622zm-190.423 31.069A206.746 206.746 0 00293.254 500a206.746 206.746 0 0014.421 74.454l-58.682-24.826.793-104.52zm381.33.199l59.308 24.461.793 104.52-58.682 24.824a206.746 206.746 0 0014.42-74.45 206.746 206.746 0 00-15.84-79.355zM499.998 458.04a41.96 41.96 0 0141.959 41.961 41.96 41.96 0 01-41.96 41.959 41.96 41.96 0 01-41.958-41.959A41.96 41.96 0 01500 458.041z"
      opacity="1"
      {...rest}
    ></path>
  );
}

export default CalculateIcon;
