import React from 'react';
import BWingSilhouette from './BWingSilhouette';
import { SvgContainer, SvgWrapper } from './bWingStyles';

function BWing(props) {
  const {
    animateOnHover,
    fill,
    height,
    increaseBlur,
    open,
    outline,
    silhouetteColor,
    silhouetteOnly,
    stroke,
    width,
    ...rest
  } = props;

  return (
    <SvgWrapper>
      {outline && (
        <BWingSilhouette
          open={open}
          fill={silhouetteColor || 'black'}
          stroke={stroke}
          height={height}
          width={width}
          style={{ position: 'absolute', top: 0, left: 0 }}
          blur
        />
      )}
      {increaseBlur && (
        <BWingSilhouette
          open={open}
          fill={silhouetteColor || 'black'}
          stroke={stroke}
          height={height}
          width={width}
          style={{ position: 'absolute', top: 0, left: 0 }}
          blur
        />
      )}
      <SvgContainer
        xmlns="http://www.w3.org/2000/svg"
        width={width || '125px'}
        height={height || '200px'}
        version="1.1"
        viewBox="0 0 125 200"
        open={open}
        animateOnHover={animateOnHover}
        silhouetteOnly={silhouetteOnly}
        {...rest}
      >
        <g display="inline" className="b-wing-wing-set-1">
          <ellipse
            cx="103.694"
            cy="72.473"
            fill="#e6e6e6"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.185"
            opacity="1"
            rx="0.562"
            ry="0.687"
          ></ellipse>
          <ellipse
            cx="119.172"
            cy="70.522"
            fill="#e6e6e6"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.185"
            opacity="1"
            rx="0.562"
            ry="0.687"
          ></ellipse>
          <rect
            width="4.37"
            height="0.968"
            x="75.664"
            y="75.071"
            fill="#666"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.265"
            opacity="1"
            rx="0.404"
            ry="0.204"
          ></rect>
          <ellipse
            cx="72.493"
            cy="73.76"
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.227"
            display="inline"
            opacity="1"
            rx="1.53"
            ry="1.963"
          ></ellipse>
          <path
            fill="#aaa"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth="0.265"
            d="M74.213 72.497l42.591.094c.224 0 .48.26.405.565l-.266 1.07c-.075.304-.18.563-.404.565l-42.326.483c-.224.002-.67-.252-.67-.565v-1.647c0-.313.446-.565.67-.565z"
            opacity="1"
          ></path>
          <rect
            width="7.257"
            height="0.82"
            x="95.905"
            y="72.573"
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.132"
            opacity="1"
            rx="0"
            ry="0"
          ></rect>
          <path
            fill="#666"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.092"
            d="M74.113 72.465H75.148V73.467H74.113z"
            opacity="1"
          ></path>
          <rect
            width="1.391"
            height="1.508"
            x="118.567"
            y="75.256"
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.159"
            opacity="1"
            ry="0.049"
          ></rect>
          <path
            fill="#4d4d4d"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M118.037 70.61c-.531.292-1.323 1.026-1.323 1.026l-.397.96-.099 2.182.794.893h4.862l-.166-3.241s-.243-1.791-.628-1.82a46.722 46.722 0 00-3.043 0z"
            opacity="1"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="0.132"
            d="M83.298 73.014h1.015v2.119h-1.015zm-1.035 0h1.015v2.119h-1.015zm-1.024 0h1.016v2.119h-1.016zm-1.03 0h1.015v2.119h-1.016zm-1.04 0h1.016v2.119H79.17zm-1.034 0h1.015v2.119h-1.015z"
            opacity="1"
          ></path>
          <path
            fill="#666"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.092"
            d="M75.192 72.465H76.22699999999999V73.467H75.192z"
            opacity="1"
          ></path>
          <circle
            cx="119.243"
            cy="72.427"
            r="1.637"
            fill="#666"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.185"
            opacity="1"
          ></circle>
          <path
            fill="#333"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="1"
            d="M446.08 267.339c-1.87 1.364-3.223 2.522-4.513 3.654l-.576 1.398 2.232 4.398 2.431 5.39 3.756 1.282h.672v2.033h.955v-2.033h1.068l4.895-2.086 2.443-8.41c-.503-2.538-1.034-4.854-1.769-5.59-3.864-.152-7.729-.118-11.593-.036zm4.48.565c3.149.001 5.7 2.534 5.7 5.659-.012 2.931-2.279 5.369-5.223 5.615v-1.045c2.361-.243 4.157-2.215 4.162-4.57 0-2.543-2.076-4.605-4.638-4.606-2.564 0-4.642 2.062-4.641 4.606.005 2.355 1.8 4.327 4.162 4.57v1.045c-2.944-.246-5.211-2.684-5.223-5.615 0-3.125 2.552-5.659 5.702-5.659zm0 2.38c1.825 0 3.304 1.468 3.303 3.279a3.287 3.287 0 01-2.826 3.234v-.84a2.448 2.448 0 001.984-2.395 2.452 2.452 0 00-2.46-2.443 2.452 2.452 0 00-2.463 2.443c0 1.166.831 2.169 1.984 2.395v.842a3.287 3.287 0 01-2.826-3.237c0-1.81 1.48-3.28 3.305-3.279z"
            opacity="1"
            transform="scale(.26458)"
          ></path>
        </g>
        <g display="inline" className="b-wing-wing-set-2">
          <ellipse
            cx="-21.303"
            cy="72.473"
            fill="#e6e6e6"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.185"
            opacity="1"
            rx="0.562"
            ry="0.687"
            transform="scale(-1 1)"
          ></ellipse>
          <ellipse
            cx="-5.825"
            cy="70.522"
            fill="#e6e6e6"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.185"
            opacity="1"
            rx="0.562"
            ry="0.687"
            transform="scale(-1 1)"
          ></ellipse>
          <rect
            width="4.37"
            height="0.968"
            x="-49.333"
            y="75.071"
            fill="#666"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.265"
            opacity="1"
            rx="0.404"
            ry="0.204"
            transform="scale(-1 1)"
          ></rect>
          <ellipse
            cx="-52.504"
            cy="73.76"
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.227"
            display="inline"
            opacity="1"
            rx="1.53"
            ry="1.963"
            transform="scale(-1 1)"
          ></ellipse>
          <path
            fill="#aaa"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth="0.265"
            d="M50.784 72.497l-42.591.094c-.224 0-.48.26-.405.565l.266 1.07c.075.304.18.563.404.565l42.326.483c.224.002.67-.252.67-.565v-1.647c0-.313-.446-.565-.67-.565z"
            opacity="1"
          ></path>
          <rect
            width="7.257"
            height="0.82"
            x="-29.092"
            y="72.573"
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.132"
            opacity="1"
            rx="0"
            ry="0"
            transform="scale(-1 1)"
          ></rect>
          <path
            fill="#666"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.092"
            d="M-50.885 72.465H-49.85V73.467H-50.885z"
            opacity="1"
            transform="scale(-1 1)"
          ></path>
          <rect
            width="1.391"
            height="1.508"
            x="-6.43"
            y="75.256"
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.159"
            opacity="1"
            ry="0.049"
            transform="scale(-1 1)"
          ></rect>
          <path
            fill="#4d4d4d"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M6.96 70.61c.532.292 1.323 1.026 1.323 1.026l.397.96.099 2.182-.794.893H3.123l.166-3.241s.243-1.791.628-1.82a46.72 46.72 0 013.043 0z"
            opacity="1"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="0.132"
            d="M41.7 73.014h-1.016v2.119H41.7zm1.034 0H41.72v2.119h1.015zm1.024 0h-1.016v2.119h1.016zm1.03 0h-1.014v2.119h1.015zm1.04 0h-1.016v2.119h1.016zm1.035 0h-1.016v2.119h1.016z"
            opacity="1"
          ></path>
          <path
            fill="#666"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.092"
            d="M-49.806 72.465H-48.771V73.467H-49.806z"
            opacity="1"
            transform="scale(-1 1)"
          ></path>
          <circle
            cx="5.8"
            cy="72.427"
            r="1.637"
            fill="#666"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.185"
            opacity="1"
          ></circle>
          <path
            fill="#333"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M6.972 70.733c.495.361.853.668 1.194.967l.152.37-.59 1.164-.644 1.426-.993.339h-.178v.538H5.66v-.538h-.282l-1.295-.552-.647-2.225c.133-.672.274-1.284.468-1.48a50.745 50.745 0 013.068-.009zm-1.186.15c-.833 0-1.508.67-1.508 1.497.003.776.603 1.42 1.382 1.486v-.277a1.222 1.222 0 01-1.1-1.209c-.001-.673.548-1.218 1.226-1.218.679 0 1.228.545 1.228 1.218a1.222 1.222 0 01-1.101 1.21v.276a1.501 1.501 0 001.382-1.486c0-.827-.675-1.497-1.509-1.497zm0 .63a.87.87 0 00-.873.867.87.87 0 00.747.856v-.222a.648.648 0 01-.525-.634c0-.357.292-.646.651-.646.36 0 .652.289.652.646 0 .309-.22.574-.525.634v.222a.87.87 0 00.748-.856.87.87 0 00-.875-.868z"
            opacity="1"
          ></path>
        </g>
        <g display="inline" className="frame">
          <path
            fill="#666"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth="1"
            d="M236.07 150.979c-7.087.294-32.389 20.952-33.91 25.55-.953 5.686-.818 24.546.615 28.114 1.856 3.396 26.96 24.322 33.295 24.582 6.335-.26 31.44-21.186 33.295-24.582 1.434-3.568 1.569-22.428.615-28.114-1.52-4.598-26.822-25.256-33.91-25.55z"
            opacity="1"
            transform="matrix(.37402 0 0 .15456 -25.795 31.498)"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth="0.241"
            d="M62.5 54.832c-2.106.036-8.506 2.058-11.336 3.244v5.558c2.708 1.071 9.39 3.259 11.336 3.292 1.947-.033 8.628-2.22 11.336-3.292v-5.558c-2.83-1.186-9.23-3.208-11.336-3.244z"
            opacity="1"
          ></path>
          <path
            fill="#666"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M57.747 44.022l-.582.652v7.98c0 .026.022.049.05.049h10.571a.05.05 0 00.05-.05v-7.979l-.583-.652H62.5z"
            opacity="1"
          ></path>
          <rect
            width="17.084"
            height="18.673"
            x="53.958"
            y="51.604"
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.132"
            opacity="1"
            rx="3.09"
            ry="3.002"
          ></rect>
          <path
            fill="#aaa"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M58.309 72.754l.625 14.146 1.7 100.885 1.866.498 1.865-.498L66.066 86.9l.625-14.146H62.5z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="#666"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M62.5 118.203l-3.023.973 1.113.416v6.846l-.992.992 2.902.816 2.902-.816-.992-.993v-6.845l1.113-.416z"
          ></path>
          <path
            fill="#999"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M59.596 126.243l.195 11.533.872.81.235 19.364-.761.344.189 11.22s1.234 1.276 2.174 1.329c.94-.053 2.174-1.33 2.174-1.33l.19-11.219-.761-.344.234-19.364.872-.81.195-11.533c-.307.281-.79.552-1.173.626-.349.068-1.055.038-1.73.033-.676.005-1.383.035-1.732-.033-.382-.074-.866-.345-1.173-.626z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="#999"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M58.309 72.753l.624 14.148.077 4.602 1.186.685v7.905h-1.041l.178 10.55 1.284 1.283v5.94h-1.162l.022 1.31h6.046l.022-1.31h-1.162v-5.94l1.284-1.284.178-10.55h-1.04v-7.904l1.185-.685.077-4.602.625-14.148H62.5z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="#aaa"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M57.216 27.625l-.094 2.965 1.873 1.56.406-3.558zm10.568 0l.094 2.965-1.873 1.56-.406-3.558z"
          ></path>
          <path
            fill="#aaa"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.21"
            d="M58.315 28.102l-.074 2.348 1.483 1.236.322-2.818zm8.37 0l.074 2.348-1.483 1.236-.322-2.818z"
          ></path>
          <path
            fill="#999"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M60.965 17.8c-.912 5.947-1.723 11.906-2.518 17.868l-1.502 32.299 5.555 4.75 5.555-4.75-1.502-32.299c-.795-5.962-1.606-11.921-2.518-17.867H62.5z"
            display="inline"
          ></path>
          <path
            fill="#666"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.28"
            d="M76.717 12.19c0 1.83-.948 3.312-2.117 3.312s-2.117-1.483-2.117-3.312c0-1.83.948-3.313 2.117-3.313 1.17 0 2.117 1.483 2.117 3.313zm3.37-1.878c1.555.963 3.315.937 3.93-.057.616-.995-.147-2.581-1.703-3.544-1.556-.962-3.316-.936-3.93.058-.616.994.147 2.58 1.703 3.543z"
            opacity="1"
            transform="matrix(.86563 .54297 -.48237 .95242 .06 -3.921)"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.185"
            d="M60.854 45.5c-.844.4-2.843 1.77-3.108 2.191-.208.332 0 3.77 0 3.77l4.754.914 4.754-.914s.208-3.438 0-3.77c-.265-.421-2.264-1.79-3.108-2.191H62.5z"
          ></path>
          <path
            fill="#aaa"
            stroke="#000"
            strokeLinecap="round"
            d="M236.07 150.979c-7.087.294-32.389 20.952-33.91 25.55-.953 5.686-.818 24.546.615 28.114 1.856 3.396 26.96 24.322 33.295 24.582 6.335-.26 31.44-21.186 33.295-24.582 1.434-3.568 1.569-22.428.615-28.114-1.52-4.598-26.822-25.256-33.91-25.55z"
            opacity="1"
            transform="matrix(.30676 0 0 .29034 -9.917 5.684)"
          ></path>
          <path
            fill="#333"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeLinecap="round"
            d="M236.07 150.979c-7.087.293-32.389 20.952-33.91 25.55-.953 5.686-.818 24.546.615 28.114 1.856 3.396 26.961 24.323 33.295 24.582 6.334-.26 31.44-21.186 33.295-24.582 1.434-3.568 1.569-22.428.615-28.114-1.52-4.598-26.823-25.257-33.91-25.55z"
            opacity="1"
            transform="matrix(.27593 0 0 .26116 -2.638 11.232)"
          ></path>
          <path
            fill="#aaa"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M62.5 32.402c-.658.002-1.67.22-1.979.397-.309.176-.4 1.327-.332 1.523.068.197.447.25.442.463-.01.385-.877.412-.838.795.039.383-.322 2.929-.266 3.244.056.314.624.422.62.729-.005.35-.579.19-.63.508 0 0-.611 2.64-.453 2.847.155.202.971.088.971.088s-.07.84 0 1.059c.073.225 1.127.156 1.127.156s.19.532.342.672c.186.17.913.148.996.144.083.004.81.027.996-.144.153-.14.342-.672.342-.672s1.054.07 1.127-.156c.07-.219 0-1.059 0-1.059s.816.114.97-.088c.16-.207-.453-2.847-.453-2.847-.05-.319-.624-.158-.628-.508-.005-.307.563-.415.619-.729.056-.315-.305-2.861-.266-3.244.039-.383-.828-.41-.838-.795-.005-.213.374-.266.442-.463.067-.196-.023-1.347-.332-1.523-.308-.176-1.321-.395-1.979-.397z"
          ></path>
          <path
            fill="#aaa"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M52.923 72.085l-.5.936h-1.248v1.344h1.248l.875 1.029h18.404l.875-1.03h1.248v-1.343h-1.248l-.5-.936H62.5z"
          ></path>
          <path
            fill="#999"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="0.266"
            d="M56.314 73.014A6.187 17.513 0 0062.5 90.406a6.187 17.513 0 006.186-17.392z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="#aaa"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth="0.132"
            d="M61.533 72.362h1.934c.267 0 .377.431.483.932l.526 2.486c.106.5-.215.931-.483.931h-2.986c-.268 0-.59-.43-.483-.931l.526-2.486c.106-.5.216-.932.483-.932z"
            opacity="1"
          ></path>
          <path
            fill="#666"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth="0.132"
            d="M62.5 76.409c-1.154.013-4.397.375-4.397.375.115 1.352.265 2.58.442 3.69h1.402v.75h-1.274c1.036 5.783 2.742 8.055 3.827 8.077 1.085-.022 2.791-2.294 3.827-8.077h-1.274v-.75h1.402c.177-1.11.327-2.338.442-3.69 0 0-3.243-.362-4.397-.375z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="none"
            stroke="#666"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.265"
            d="M56.19 56.343h12.62m-12.62 9.072h12.62M56.19 60.88h12.62m0-6.35v12.598M56.19 54.528v12.599m6.31-16.34v20.217"
          ></path>
          <path
            fill="#4d4d4d"
            stroke="none"
            strokeWidth="0.416"
            d="M236.22 363.29l-6.238 4.333v34.672l6.239 4.334 6.238-4.334v-34.672zm0 142.208l-5.374 3.982 1.822 34.018 3.553 4.248 3.906-4.248 1.469-34.018zm0 74.61l-4.238 4.333.444 33.854 3.795 4.334 3.795-4.334.443-33.854z"
            opacity="1"
            transform="scale(.26458)"
          ></path>
        </g>
        <g display="inline" className="blaster-pod">
          <ellipse
            cx="63.283"
            cy="193.145"
            fill="red"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.185"
            opacity="1"
            rx="0.562"
            ry="0.687"
          ></ellipse>
          <path
            fill="#aaa"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.132"
            d="M62.5 175.487c-3.241 0-5.868 5.115-5.868 11.425-.39 1.466 11.728 1.438 11.736 0 0-6.31-2.627-11.425-5.868-11.425z"
            opacity="1"
          ></path>
          <path
            fill="#aaa"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.121"
            d="M62.5 180.634c-5.778 0-10.463 2.406-10.463 5.374.002 2.518 3.405 4.697 8.189 5.244v1.725h4.057V191.3c5.014-.446 8.677-2.68 8.68-5.293 0-2.968-4.684-5.374-10.463-5.374z"
            display="inline"
            opacity="1"
          ></path>
          <path
            fill="#333"
            strokeWidth="2.646"
            d="M214.748 690.625c-7.765 3.015-12.658 7.628-12.482 12.762.152 4.454 14.332 5.739 14.332 5.739-.02 2.744 10.794 10.204 20.17 10.132.006-2.414.009-.428 0-2.908 29.842 0 33.558-9.03 33.408-13.487-.172-4.947-5.01-9.336-12.487-12.238z"
            opacity="1"
            transform="scale(.26458)"
          ></path>
          <path
            fill="#4d4d4d"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="1"
            d="M239.752 696.63c-8.847 0-18.957.224-18.957.224L231.438 701l-8.313 1.75 6.045 6.988L249 710.75s3.75-12.393 3.75-13.498c0-.483-6.117-.62-12.998-.621zm-8.002 1.307l14.313.375s-.125 1.625-.313 2.063c-.188.437-10.25.187-11.125.25-.875.062-3.625-.062-4.125-.688-.5-.624 1.25-2 1.25-2zm15.875.688l1.938.313-.813 9.687-3.65-.77-1.245-4.33 2.208-1.587zm-12.813 3.875l-.312 5.688-4 .062-3.25-5.625zm7.25.063l.563 4.837-6.563.538.438-5.313z"
            transform="scale(.26458)"
          ></path>
          <circle
            cx="66.873"
            cy="185.804"
            r="1.59"
            fill="#aaa"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.27"
            opacity="1"
          ></circle>
          <circle
            cx="58.208"
            cy="185.473"
            r="1.356"
            fill="#aaa"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.23"
            opacity="1"
          ></circle>
          <circle
            cx="66.873"
            cy="185.804"
            r="1.356"
            fill="#000"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.23"
            opacity="1"
          ></circle>
          <path
            fill="#aaa"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.7"
            d="M252.625 707.131l-1.29-3.843-3.841-1.288 3.843-1.29 1.288-3.841 1.29 3.843 3.841 1.288-3.843 1.29z"
            opacity="1"
            transform="matrix(.25775 0 0 .25775 1.76 4.865)"
          ></path>
          <path
            fill="#000"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.7"
            d="M252.625 707.131l-1.29-3.843-3.841-1.288 3.843-1.29 1.288-3.841 1.29 3.843 3.841 1.288-3.843 1.29z"
            opacity="1"
            transform="matrix(.20078 0 0 .20078 16.153 44.86)"
          ></path>
          <circle
            cx="58.208"
            cy="185.473"
            r="1.169"
            fill="#000"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.198"
            opacity="1"
          ></circle>
          <circle
            cx="58.208"
            cy="185.473"
            r="0.841"
            fill="#4d4d4d"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.143"
            opacity="1"
          ></circle>
          <path
            fill="#1a1a1a"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.7"
            d="M230.417 702.98l-3.763.595-1.73-3.395 2.694-2.694 3.395 1.73z"
            opacity="1"
            transform="matrix(.31347 0 0 .31347 -10.996 -34.097)"
          ></path>
          <path
            fill="#aaa"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.7"
            d="M252.625 707.131l-1.29-3.843-3.841-1.288 3.843-1.29 1.288-3.841 1.29 3.843 3.841 1.288-3.843 1.29z"
            opacity="1"
            transform="matrix(.14375 .07953 -.07953 .14375 83.627 65.873)"
          ></path>
        </g>
        <g display="inline" className="cockpit">
          <circle
            cx="62.5"
            cy="18.1"
            r="10.948"
            fill="#666"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.529"
            display="inline"
          ></circle>
          <circle
            cx="62.5"
            cy="18.1"
            r="7.646"
            fill="none"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.37"
            display="inline"
          ></circle>
          <g className="cockpit-rotation">
            <circle
              cx="62.5"
              cy="18.1"
              r="9.224"
              fill="#999"
              stroke="#000"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.446"
              display="inline"
            ></circle>
            <path
              fill="#000"
              fillOpacity="1"
              stroke="none"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="1.26"
              d="M236.22 42.346a26.064 26.064 0 00-21.445 11.258l21.319 12.898a.27.27 0 00.254 0l21.32-12.898a26.064 26.064 0 00-21.447-11.258zm22.796 13.43l-22.657 13.708a.297.297 0 01-.277 0l-22.648-13.7a26.064 26.064 0 00-3.278 12.626 26.064 26.064 0 0026.065 26.065 26.064 26.064 0 0026.064-26.065 26.064 26.064 0 00-3.27-12.635z"
              display="inline"
              opacity="1"
              transform="scale(.26458)"
            ></path>
            <circle
              cx="62.5"
              cy="18.1"
              r="6.896"
              fill="none"
              fillOpacity="1"
              stroke="#000"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.333"
              display="none"
              opacity="1"
            ></circle>
            <ellipse
              cx="62.534"
              cy="17.914"
              fill="#999"
              fillOpacity="1"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.257"
              opacity="1"
              rx="1.543"
              ry="2.666"
            ></ellipse>
            <ellipse
              cx="62.534"
              cy="17.914"
              fill="none"
              fillOpacity="1"
              stroke="#000"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.129"
              opacity="1"
              rx="1.584"
              ry="1.924"
            ></ellipse>
            <path
              fill="#aaa"
              stroke="#000"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.35"
              d="M55.701 19.239a6.876 7.646 0 006.8 6.507 6.876 7.646 0 006.798-6.507z"
              display="inline"
              opacity="1"
            ></path>
            <path
              fill="#333"
              stroke="none"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.132"
              d="M58.408 20.043l-.097.693s-.209.01-.586.067c-.378.056-.431.158-.442.43.414.504.051.592.768.976.703.377 1.888.983 2.306 1.39.38.37.206-.48.815-.347.525.114 1.162.513 1.328.443.083-.034.414-.182.813-.227.398-.044.863.19 1.126.133 1.421-.116 2.135-.651 2.784-2.369-.01-.27-.064-.373-.441-.43-.378-.055-.586-.066-.586-.066l-.098-.693H62.5z"
              transform="matrix(1.07173 0 0 1.07173 -4.483 -1.569)"
            ></path>
            <path
              fill="#666"
              fillOpacity="1"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.185"
              d="M114.885 111.463a2.228.693 0 01-1.035.585l-1.193-.585z"
              opacity="1"
              transform="matrix(.61995 -.78464 -.04323 .99907 0 0)"
            ></path>
            <path
              fill="#666"
              fillOpacity="1"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.185"
              d="M-56.254 63.966a.854.582 0 01-.396.491l-.457-.491z"
              opacity="1"
              transform="matrix(-.72626 .68742 .29812 .95453 0 0)"
            ></path>
            <path
              fill="#666"
              fillOpacity="1"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.185"
              d="M61.427 21.951H64.187V22.068H61.427z"
              opacity="1"
            ></path>
            <path
              fill="#4d4d4d"
              fillOpacity="1"
              stroke="#000"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.5"
              d="M236.527 77.075l-6.059-3.294-.177-6.895 5.883-3.6 6.059 3.294.176 6.894z"
              opacity="1"
              transform="matrix(.26456 .0035 -.00307 .23144 .187 1.797)"
            ></path>
            <path
              fill="#aaa"
              stroke="#000"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.102"
              d="M63.759 19.337h-2.514l.556-.996h1.46z"
            ></path>
            <circle
              cx="60.742"
              cy="21.515"
              r="0.737"
              fill="#aaa"
              fillOpacity="1"
              stroke="#000"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.132"
              opacity="1"
            ></circle>
            <circle
              cx="60.742"
              cy="21.515"
              r="0.436"
              fill="#000"
              fillOpacity="1"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.132"
              opacity="1"
            ></circle>
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeOpacity="1"
              strokeWidth="0.265"
              d="M60.523 20.307s.926.198 1.323.76c.397.563 1.81.355 1.81.355l-.288-.983 2.216-.132-.133 1.058"
            ></path>
            <circle
              cx="64.267"
              cy="21.515"
              r="0.536"
              fill="#333"
              fillOpacity="1"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.162"
              opacity="1"
            ></circle>
            <circle
              cx="64.262"
              cy="22.021"
              r="0.737"
              fill="#4d4d4d"
              fillOpacity="1"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0.132"
              opacity="1"
            ></circle>
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeOpacity="1"
              strokeWidth="0.265"
              d="M59.267 20.604s-.166.265-.397.53c-.232.264-.496-1.09-.86-.48-.364.612.827 1.538.827 1.538l1.091.959.43-.567s1.356.567 1.588.7"
            ></path>
          </g>
          <path
            fill="#555"
            stroke="#000"
            strokeWidth="0.132"
            d="M60.334 28.563v1.43h-1.145l-.197 1.835h7.016l-.197-1.836h-1.145v-1.43H62.5z"
            opacity="1"
          ></path>
        </g>
      </SvgContainer>
    </SvgWrapper>
  );
}

export default BWing;
