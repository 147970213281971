import React from 'react';

function DepleteIcon({ color, ...rest }) {
  return (
    <path
      fill={color || '#fff'}
      d="m 297.97891,301.67884 187.07617,243.25977 -186.13282,-159.37695 123.4668,138.54882 -92.36523,-37.70117 102.73242,102.73438 -75.40039,-1.88477 c 0,0 111.00824,68.18242 176.50195,97.67578 39.13519,17.6235 128.4858,17.71193 146.7793,1.05664 31.57391,-28.74642 18.84961,-128.89258 18.84961,-128.89258 l -12.25391,7.53907 -6.5957,-73.51563 -18.85156,44.29883 -72.57227,-102.73437 -7.19531,41.83789 -101.19336,-90.84766 8.82812,33.92969 35.47071,64.09179 z M 596.4757,486.09888 c 5.97768,-0.0139 20.24755,22.98984 20.24755,22.98984 0,0 29.46096,54.92199 51.48417,55.18583 11.2988,32.07959 19.09126,81.39299 -2,103.19921 -25.18923,26.04311 -106.69531,6 -106.69531,6 0,0 -0.50489,-10.61634 -8.59375,-15.67187 -8.08885,-5.05555 -83.41609,-39.43346 -89.98828,-42.4668 -6.5722,-3.03332 16.68359,-4.12005 16.68359,-15.16601 0,-3.53888 -33.87305,-38.42188 -33.87305,-38.42188 0,0 -19.71592,-13.14514 -8.59375,-14.15625 11.12217,-1.0111 71.31033,11.77992 80.88861,19.46421 19.96073,16.01375 37.99289,36.47494 47.6117,46.09375 21.99157,21.99157 54.59893,20.72842 57.63225,18.20066 3.03332,-2.52777 3.70371,-20.56387 -0.59349,-31.18049 -2.30756,-5.70102 -32.38241,-51.14821 -32.38241,-51.14821 0,0 -15.14115,-33.60128 -12.10783,-49.779 1.61145,-8.59441 13.50529,-13.12725 20.28,-13.14299 z"
      {...rest}
    ></path>
  );
}

export default DepleteIcon;
