import React from 'react';

function ChargeIcon({ color }) {
  return (
    <path
      fill={color || '#fff'}
      strokeWidth="0.311"
      d="M 688.35156 216.08398 L 363.61133 533.4082 L 465.66797 533.4082 L 307.94141 783.91602 L 632.67969 464.74414 L 526.90625 464.74414 L 688.35156 216.08398 z M 500 266.19727 C 371.89945 266.19818 268.05423 369.83755 268.05273 497.68164 C 268.05345 559.14707 292.06127 615.01969 331.22461 656.4668 L 342.60742 626.05859 C 313.93622 591.08928 296.73082 546.39037 296.73047 497.68164 C 296.73104 385.64492 387.73853 294.82056 500 294.82227 C 509.76004 294.82273 519.36098 295.5171 528.75391 296.84375 L 561.75586 274.48828 C 542.09512 269.08237 521.38265 266.19749 500 266.19727 z M 662.78711 332.77734 L 653.5332 364.74219 C 684.51128 400.3453 703.25731 446.82718 703.25781 497.68164 C 703.25724 609.71452 612.2572 700.53466 500 700.53906 C 492.69928 700.53935 485.49218 700.15323 478.39062 699.4043 L 453.92773 722.08398 C 472.2076 726.70632 480.28041 729.16385 500 729.16406 C 628.10034 729.16314 731.94577 625.52589 731.94727 497.68164 C 731.94648 433.13632 705.48501 374.76035 662.78711 332.77734 z"
    ></path>
  );
}

export default ChargeIcon;
