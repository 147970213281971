import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import {
  Button,
  IconButton,
  Modal,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import { agilityGreen, weaponRed } from '../../styles/colors';

import { GameStateContext } from '../../context/gameState';

import factions from '../../gameData/factions.json';
import factionImages from '../../images/factions';
import { SocketContext } from '../../context/socket';
import { names } from '../../utils';

const UPDATE_GAME_SETTINGS = 'UPDATE_GAME_SETTINGS';

function HeaderQuickBuild() {
  const socket = useContext(SocketContext);
  const { gameState } = useContext(GameStateContext);

  const [gameSettings, setGameSettings] = useState(gameState.settings ?? {});
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const toggleSettingsModal = () => setShowSettingsModal(!showSettingsModal);

  useEffect(() => {
    setGameSettings(gameState.settings ?? {});
  }, [gameState.settings, showSettingsModal]);

  const { player1, player2, code } = gameState;

  const player1FactionData = factions.list.find(
    (f) => f.key === player1?.faction
  );
  const player2FactionData = factions.list.find(
    (f) => f.key === player2?.faction
  );

  const handleUpdateSettings = (setting, value) => {
    setGameSettings({ ...gameSettings, [setting]: value });
  };

  const handleSaveSettings = () => {
    const settingsChanged = Object.keys(gameSettings).some(
      (setting) => gameSettings[setting] !== gameState.settings[setting]
    );

    console.log('settingsChanged', settingsChanged);

    if (settingsChanged) {
      console.log('settings changed');
      socket.emit(UPDATE_GAME_SETTINGS, gameSettings);
    }

    toggleSettingsModal();
  };

  const selectPlayerCount = (count) => {
    handleUpdateSettings('players', count);
  };

  const playerCount = gameSettings?.players;

  const p1FactionList = gameSettings?.enforceFactionRules
    ? player1?.faction
    : [];

  const p2FactionList = gameSettings?.enforceFactionRules
    ? player2?.faction
    : [];

  return (
    <Header>
      <Link className="home-link" to="/">
        <Button variant="outlined" sx={{ fontWeight: 'bold' }}>
          Home
        </Button>
      </Link>
      <h1>Quick Build</h1>
      <div className="right">
        <div className="game">Game Code: {code ?? null}</div>
        <div className="players">
          <PlayerInfo
            styleprops={{
              color: player1FactionData?.color,
              background: player1FactionData?.background,
            }}
            className={names({
              'multi-faction': p1FactionList?.length < 1,
            })}
          >
            {player1?.selectionReady ? (
              <CheckCircleIcon title="Ready" sx={{ color: agilityGreen }} />
            ) : (
              <CancelIcon title="Not Ready" sx={{ color: weaponRed }} />
            )}
            <p className="player-name">
              P1: {player1?.name ? player1?.name : null}
            </p>
            {p1FactionList?.length > 0
              ? p1FactionList?.map((f) => (
                  <img key={f} src={factionImages[f]} alt={f} />
                ))
              : null}
          </PlayerInfo>
          {playerCount > 1 && (
            <PlayerInfo
              styleprops={{
                color: player2FactionData?.color,
                background: player2FactionData?.background,
              }}
              className={names({
                'multi-faction': p2FactionList?.length < 1,
              })}
            >
              {player2?.selectionReady ? (
                <CheckCircleIcon title="Ready" sx={{ color: agilityGreen }} />
              ) : (
                <CancelIcon title="Not Ready" sx={{ color: weaponRed }} />
              )}
              <p className="player-name">
                P2: {player2?.name ? player2?.name : null}
              </p>
              {p2FactionList?.length > 0
                ? p2FactionList?.map((f) => (
                    <img key={f} src={factionImages[f]} alt={f} />
                  ))
                : null}
            </PlayerInfo>
          )}
        </div>
        <div className="settings-button">
          <IconButton onClick={toggleSettingsModal}>
            <SettingsIcon />
          </IconButton>
        </div>
      </div>
      <Modal
        open={showSettingsModal}
        onClose={toggleSettingsModal}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ModalContainer>
          <h2>Settings</h2>
          <div className="settings-area">
            <div className="settings-group">
              <Tooltip title="Max difference between players' squadron total threat levels.">
                <label htmlFor="">Player Count</label>
              </Tooltip>
              <div className="player-count">
                <IconButton
                  onClick={() => selectPlayerCount(1)}
                  color={playerCount === 1 ? 'primary' : ''}
                  size="large"
                >
                  {playerCount === 1 ? (
                    <PersonIcon fontSize="inherit" />
                  ) : (
                    <PersonOutlineIcon fontSize="inherit" />
                  )}
                </IconButton>
                <h3>{playerCount}</h3>
                <IconButton
                  onClick={() => selectPlayerCount(2)}
                  color={playerCount === 2 ? 'primary' : ''}
                  size="large"
                >
                  {playerCount === 2 ? (
                    <PeopleIcon fontSize="inherit" />
                  ) : (
                    <PeopleOutlineIcon fontSize="inherit" />
                  )}
                </IconButton>
              </div>
            </div>
            <div className="settings-group">
              <Tooltip title="Disable if players want to use multiple factions or the same faction.">
                <label htmlFor="">Enforce Faction Rules</label>
              </Tooltip>
              <Switch
                checked={gameSettings?.enforceFactionRules}
                onChange={(e) => {
                  handleUpdateSettings('enforceFactionRules', e.target.checked);
                }}
              />
            </div>
            <div className="settings-group">
              <Tooltip title="Toggle using a max limit for player's squad. (Set below)">
                <label htmlFor="">Limit Threat Level</label>
              </Tooltip>
              <Switch
                checked={gameSettings.useMaxThreatLevel}
                onChange={(e) => {
                  handleUpdateSettings('useMaxThreatLevel', e.target.checked);
                }}
              />
            </div>
            <div
              className={names(
                { disabled: !gameSettings.useMaxThreatLevel },
                'settings-group'
              )}
            >
              <Tooltip title="Max quick build threat total for each player's squad.">
                <label htmlFor="">Max Threat</label>
              </Tooltip>
              <TextField
                type="number"
                size="small"
                value={gameSettings.maxThreatLevel}
                onChange={(e) => {
                  handleUpdateSettings(
                    'maxThreatLevel',
                    Math.max(e.target.value, 1)
                  );
                }}
                sx={{ opacity: !gameSettings.useMaxThreatLevel ? 0.2 : 1 }}
                disabled={!gameSettings.useMaxThreatLevel}
              />
            </div>
            <div className="settings-group">
              <Tooltip title="Max difference between players' squadron total threat levels.">
                <label htmlFor="">Max Threat Difference</label>
              </Tooltip>
              <TextField
                type="number"
                size="small"
                value={gameSettings.maxThreatDifference}
                onChange={(e) => {
                  handleUpdateSettings(
                    'maxThreatDifference',
                    Math.max(e.target.value, 0)
                  );
                }}
              />
            </div>
          </div>
          <SettingsButtonBar>
            <Button
              variant="outlined"
              color="error"
              onClick={toggleSettingsModal}
              sx={{ maxWidth: '100px' }}
            >
              Cancel
            </Button>
            <Button onClick={handleSaveSettings} variant="contained">
              Save
            </Button>
          </SettingsButtonBar>
        </ModalContainer>
      </Modal>
    </Header>
  );
}

const Header = styled.header`
  top: 0;
  position: fixed;
  display: grid;
  grid-template-columns: 300px 1fr auto;
  place-items: center;
  padding: 6px 24px;
  width: 100%;
  box-shadow: 2px 2px 4px 2px black;
  margin-bottom: 24px;
  background-color: #222;
  z-index: 10;

  .home-link {
    justify-self: start;
  }

  .players,
  .game {
    font-size: 18px;
    font-weight: bold;
  }

  .players {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 30px;
    gap: 30px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-self: end;
  }
`;

const PlayerInfo = styled.span`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: start;
  align-items: center;
  gap: 8px;

  &.multi-faction {
    grid-template-columns: repeat(3, 1fr);
  }

  .player-name {
    grid-column: 2 / span 2;
  }

  img {
    height: 30px;
    background-color: ${({ styleprops }) => styleprops.background};
    color: ${({ styleprops }) => styleprops.color};
    border-radius: 8px;
    padding: 4px;
  }
`;

const ModalContainer = styled.div`
  border: 3px solid white;
  border-radius: 8px;
  background-color: #222;
  color: white;
  padding: 36px 48px;

  h2 {
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
    color: gold;
  }

  .settings-area {
    display: grid;
    gap: 12px;
  }

  .settings-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      margin-right: 24px;
    }
  }
  .settings-group.disabled {
    color: #333;
  }

  .player-count {
    display: flex;
    align-items: center;
    gap: 12px;

    h3 {
      font-size: 24px;
    }
  }
`;

const SettingsButtonBar = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

export default HeaderQuickBuild;
