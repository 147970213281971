import React from 'react';
import colors from '../../styles/colors';
import { convertCase } from '../../utils';

const ForwardArc = ({ fillColor }) => {
  return (
    <path
      fill={fillColor || colors.red}
      fillOpacity="1"
      d="M500 2.463A497.537 497.537 0 002.463 500 497.537 497.537 0 00500 997.537 497.537 497.537 0 00997.537 500 497.537 497.537 0 00500 2.463zM95.639 236.65L500 641.012 904.361 236.65A482.759 482.759 0 01982.758 500 482.759 482.759 0 01500 982.758 482.759 482.759 0 0117.242 500 482.759 482.759 0 0195.64 236.65z"
      opacity="1"
    ></path>
  );
};

export const getArcSvg = (arc, fillColor) => {
  switch (convertCase(arc).initialCaps) {
    case 'Forward Arc':
    case 'Front Arc':
    case 'Front':
      return (
        <g>
          <ForwardArc fillColor={fillColor} />
        </g>
      );
    case 'Rear Arc':
      return (
        <g transform="rotate(180, 500, 500)">
          <ForwardArc fillColor={fillColor} />
        </g>
      );
    case 'Left':
    case 'Left Arc':
      return (
        <g transform="rotate(-90, 500, 500)">
          <ForwardArc fillColor={fillColor} />
        </g>
      );
    case 'Right':
    case 'Right Arc':
      return (
        <g transform="rotate(90, 500, 500)">
          <ForwardArc fillColor={fillColor} />
        </g>
      );
    case 'Turret':
    case 'Turret Arc':
    case 'Single Turret':
    case 'Single Turret Arc':
      return (
        <g>
          <path
            fill={fillColor || colors.red}
            fillOpacity="1"
            strokeWidth="2.982"
            d="M746.33 73.344L430.742 204.168C352.118 236.146 281.5 276.502 239.186 349.42c-83.164 144.043-33.81 328.231 110.234 411.394 144.043 83.164 328.231 33.81 411.394-110.234 42.614-73.82 44.753-146.552 29.715-241.01zM500 343.252A156.748 156.748 0 01656.748 500 156.748 156.748 0 01500 656.748 156.748 156.748 0 01343.252 500 156.748 156.748 0 01500 343.252z"
            opacity="1"
          ></path>
        </g>
      );
    case 'Double Turret':
    case 'Double Turret Arc':
      return (
        <g>
          <path
            fill={fillColor || colors.red}
            fillOpacity="1"
            strokeWidth="2.982"
            d="M746.33 73.344L430.742 204.168c-6.006 2.443-11.862 5.046-17.765 7.598-70.777 21.353-133.911 68.579-173.791 137.654-39.88 69.074-49.211 147.36-32.317 219.33.743 6.39 1.415 12.763 2.303 19.188l44.498 338.718L566.95 796.82c8.313-3.183 16.15-6.46 23.995-9.738 63.553-20.113 120.598-61.285 159.815-120.4.63-.948 1.292-1.841 1.914-2.801 2.81-4.34 5.526-8.773 8.14-13.3a306.01 306.01 0 007.457-13.722c.437-.853.807-1.708 1.233-2.562 31.786-63.645 38.983-133.822 24.59-199.078-1.083-8.433-2.165-16.858-3.565-25.649zM500 343.252A156.748 156.748 0 01656.748 500 156.748 156.748 0 01500 656.748 156.748 156.748 0 01343.252 500 156.748 156.748 0 01500 343.252z"
            opacity="1"
          ></path>
        </g>
      );
    case 'Bullseye':
    case 'Bullseye Arc':
      return (
        <g>
          <path
            fill={fillColor || colors.red}
            fillOpacity="1"
            d="M500 2.463A497.537 497.537 0 002.463 500 497.537 497.537 0 00500 997.537 497.537 497.537 0 00997.537 500 497.537 497.537 0 00500 2.463zM420.654 24.47v517.195L500 621.012l79.37-79.37V24.478A482.759 482.759 0 01982.757 500 482.759 482.759 0 01500 982.758 482.759 482.759 0 0117.242 500 482.759 482.759 0 01420.654 24.47z"
            opacity="1"
          ></path>
        </g>
      );
    case 'Full Front':
    case 'Full Front Arc':
    case 'Front Half Arc':
      return (
        <g>
          <path
            fill={fillColor || colors.red}
            fillOpacity="1"
            d="M500 2.463A497.537 497.537 0 002.463 500 497.537 497.537 0 00500 997.537 497.537 497.537 0 00997.537 500 497.537 497.537 0 00500 2.463zM21.652 561.65H420.64L500 641.012l79.361-79.362h398.987A482.759 482.759 0 01500 982.758 482.759 482.759 0 0121.652 561.65z"
            opacity="1"
          ></path>
        </g>
      );
    default:
      return null;
  }
};
