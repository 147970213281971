import React, { useContext, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

// styled-components & MUI
import { Button, Modal, Tooltip } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import factions from '../../gameData/factions.json';

// components
import HeaderQuickBuild from './HeaderQuickBuild';
import SelectedCards from './SelectedCards';
import SelectQuickBuild from './SelectQuickBuild';
import SelectShip from './SelectShip';

// context
import { GameStateContext } from '../../context/gameState';
import { GameDataContext } from '../../context/gameData';
import { SocketContext } from '../../context/socket';
import axiosRequest from '../../requests/axios';

// custom styles, images, etc...
import factionImages from '../../images/factions';
import {
  ConfirmModalWrapper,
  DataBar,
  Faction,
  FactionButton,
  FactionButtonContainer,
  LowerArea,
  ModalWrapper,
  PreviewBanner,
  QuickBuildWrapper,
  RouteWrapper,
  SelectionArea,
  SelectionButtons,
} from './quickBuildStyles';
import {
  REJOIN_GAME,
  X_WING_GAME_CODE,
  X_WING_PLAYER_NAME,
  X_WING_SOCKET_ID,
  names,
} from '../../utils';

// socket strings
const SELECT_FACTION = 'SELECT_FACTION';
// const FACTION_ALREADY_SELECTED = 'FACTION_ALREADY_SELECTED';
const ADD_SELECTED_BUILD = 'ADD_SELECTED_BUILD';
const REMOVE_SELECTED_BUILD = 'REMOVE_SELECTED_BUILD';
const CLEAR_SELECTED_BUILDS = 'CLEAR_SELECTED_BUILDS';
const UNIQUENESS_CONFLICT = 'UNIQUENESS_CONFLICT';
const TOGGLE_QB_READY = 'TOGGLE_QB_READY';
const READY_TO_START_GAME = 'READY_TO_START_GAME';
const START_GAME = 'START_GAME';

const modalSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function QuickBuild() {
  const navigate = useNavigate();
  const location = useLocation();
  const { shipParam } = useParams();
  const factionParam = location.pathname.slice(1).split('/')[1];
  const shipName = location.pathname.slice(1).split('/')[2];

  console.log('factionParam:', factionParam);
  // LOCAL STORAGE
  // CONTEXT
  const socket = useContext(SocketContext);
  const { gameState } = useContext(GameStateContext);
  const { gameData, setGameData } = useContext(GameDataContext);
  // STATE
  const [collapseSide, setCollapseSide] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [futureFaction, setFutureFaction] = useState();

  const [conflictList, setConflictList] = useState([]);

  const { player1, player2, isHost, settings } = gameState || {};

  const player = isHost ? player1 : player2;
  const otherPlayer = isHost ? player2 : player1;
  const previewOnly =
    settings?.enforceFactionRules &&
    otherPlayer?.faction.includes(factionParam);

  const selectFaction = (faction) => {
    if (factionParam === faction) return;

    setFutureFaction(faction);
    const warnPlayer =
      settings?.enforceFactionRules && player?.quickBuild?.length > 0;

    if (warnPlayer) {
      setConfirmModalOpen(true);
    } else {
      confirmFactionChange(faction);
    }
  };

  const handleConfirmFactionChange = (_event) => {
    if (gameState?.settings?.enforceFactionRules)
      return confirmFactionChange(futureFaction);

    socket.emit(SELECT_FACTION, futureFaction);
    setCollapseSide(true);
    navigate(`/quick-build/${futureFaction}`);
  };

  const confirmFactionChange = (factionInput) => {
    const faction = factionInput || futureFaction;

    socket.emit(SELECT_FACTION, faction);
    setCollapseSide(true);
    navigate(`/quick-build/${faction}`);
    setConfirmModalOpen(false);
    setFutureFaction(null);
  };

  const cancelFactionChange = () => {
    setConfirmModalOpen(false);
    setFutureFaction(null);
  };

  const handleSelectBuild = (card, ship) => {
    if (!previewOnly) {
      const newCard = { ...card, ship, faction: factionParam };
      socket.emit(ADD_SELECTED_BUILD, newCard);
    }
  };

  const clearSelection = () => socket.emit(CLEAR_SELECTED_BUILDS);

  const toggleReady = () => {
    socket.emit(TOGGLE_QB_READY);
  };

  const playGame = () => {
    socket.emit(READY_TO_START_GAME);
  };

  const removeCardFromSelection = (cardToRemove) => {
    socket.emit(REMOVE_SELECTED_BUILD, cardToRemove);
  };

  const handleUniquenessConflict = (conflictList) => {
    setConflictList(conflictList);
    setModalOpen(true);
  };

  useEffect(() => {
    const handleStartGame = () => {
      navigate('/game-board');
    };
    // const handleFactionRejected = () => {
    //   navigate('/quick-build');
    // };

    socket.on(UNIQUENESS_CONFLICT, handleUniquenessConflict);

    socket.on(START_GAME, handleStartGame);
    // TODO: implement faction rejection
    // socket.on(FACTION_ALREADY_SELECTED, handleFactionRejected);
  }, [socket, navigate]);

  useEffect(() => {
    const fetchQuickBuilds = async () => {
      const quickBuildRes = await axiosRequest.get('/game-data/quick-builds');
      setGameData({ ...gameData, quickBuilds: quickBuildRes.data });
    };

    const fetchShipMap = async () => {
      const shipMapRes = await axiosRequest.get('/game-data/ship-map');
      setGameData({ ...gameData, shipMap: shipMapRes.data });
    };

    if (!gameData.quickBuilds) {
      fetchQuickBuilds();
    }
    if (!gameData.shipMap) {
      fetchShipMap();
    }
  }, [gameData, setGameData]);

  const handleInitialPageLoad = () => {
    const savedGameCode = localStorage.getItem(X_WING_GAME_CODE);
    const savedPlayerName = localStorage.getItem(X_WING_PLAYER_NAME);
    const savedSocketId = localStorage.getItem(X_WING_SOCKET_ID);

    if (savedGameCode && savedPlayerName) {
      socket.emit(REJOIN_GAME, {
        gameCode: savedGameCode,
        playerName: savedPlayerName,
        savedSocketId: savedSocketId,
      });
    }
  };
  useEffect(handleInitialPageLoad, [socket]);

  const factionButtons = factions.list.map(
    ({ key, name, color, background }) => {
      const path = '/quick-build/' + encodeURIComponent(key);
      const disabled = gameState?.settings?.enforceFactionRules
        ? otherPlayer?.faction?.includes(key)
        : false;

      return (
        <FactionButton
          to={path}
          name={key}
          key={key}
          styleprops={{ color, background }}
          onClick={() => selectFaction(key)}
          title={disabled ? "Opponent's faction" : name}
          className={names({
            collapsed: collapseSide,
            selected: key === factionParam,
            'not-available': disabled,
          })}
          // disabled={disabled}
        >
          <img src={factionImages[key]} alt={key} />
          {collapseSide ? null : <p>{name}</p>}
        </FactionButton>
      );
    }
  );

  const selectedCards = player?.quickBuild ?? [];
  const opponentSelectedCards = otherPlayer?.quickBuild ?? [];

  const factionData = factions.list.find((f) => f.key === factionParam) || {};

  const ship = shipParam ? decodeURIComponent(shipParam) : shipParam;
  const playerCount = gameState?.settings?.players;

  return (
    <QuickBuildWrapper>
      <HeaderQuickBuild />
      <LowerArea>
        <FactionButtonContainer collapseSide={collapseSide}>
          {factionParam ? (
            <Button
              onClick={() => setCollapseSide(!collapseSide)}
              sx={{
                width: collapseSide ? '45px' : '100%',
                minWidth: 40,
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '12px',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.25s ease',
              }}
            >
              <p>{collapseSide ? null : 'Collapse'}</p>
              <KeyboardArrowRightIcon
                sx={{
                  transform: collapseSide ? null : 'rotate(180deg)',
                  justifySelf: 'flex-end',
                }}
              />
            </Button>
          ) : (
            <h3>Select Faction</h3>
          )}
          {factionButtons}
        </FactionButtonContainer>
        <SelectionArea>
          {previewOnly && (
            <PreviewBanner className="preview-only">
              <h3>Preview Only</h3>
              <p>Opponent has selected this faction.</p>
            </PreviewBanner>
          )}
          {playerCount === 2 && (
            <SelectedCards
              opponent
              selectedCards={opponentSelectedCards}
              handleClick={removeCardFromSelection}
              faction={otherPlayer?.faction}
              ship={ship}
            />
          )}

          <SelectedCards
            selectedCards={selectedCards}
            handleClick={removeCardFromSelection}
            faction={player?.faction}
            ship={ship}
          />
          <SelectionButtons>
            <Button
              variant="outlined"
              onClick={clearSelection}
              disabled={player?.quickBuild?.length < 1}
            >
              Clear Selection
            </Button>
            {isHost ? (
              <Button
                variant="contained"
                disabled={
                  playerCount > 1
                    ? !player?.selectionReady || !otherPlayer?.selectionReady
                    : !player?.selectionReady
                }
                onClick={playGame}
              >
                Start Game
              </Button>
            ) : null}
            <Button
              variant="outlined"
              onClick={toggleReady}
              disabled={
                !player?.selectionReady && player?.quickBuild.length < 1
              }
            >
              {player?.selectionReady ? 'Cancel Ready' : 'Confirm Ready'}
            </Button>
          </SelectionButtons>

          <DataBar>
            {factionParam ? (
              <>
                <Faction
                  className={names({ 'f-you': !shipName })}
                  to={`/quick-build/` + factionParam}
                  factiondata={factionData}
                >
                  <img src={factionImages[factionParam]} alt={factionParam} />
                  <p className="faction-name">{factions.map[factionParam]}</p>
                </Faction>
                {shipName ? (
                  <div className="ship-wrapper">
                    <DoubleArrowIcon fontSize="large" />
                    <h2 className="ship-name">
                      {shipName?.replace(/%20/g, ' ').replace(/%2F/g, '/')}
                    </h2>
                  </div>
                ) : null}
                {shipName ? (
                  <div className="ship-wrapper">
                    <DoubleArrowIcon fontSize="large" />
                    <h2 className="select-text">Select Quick Build</h2>
                  </div>
                ) : (
                  <div className="ship-wrapper">
                    <DoubleArrowIcon fontSize="large" />
                    <h2>Select a Ship</h2>
                  </div>
                )}
              </>
            ) : (
              <h2>Select a Faction to start</h2>
            )}
          </DataBar>

          <RouteWrapper>
            <Routes>
              <Route path="/:factionParam" element={<SelectShip />} />
              <Route
                path="/:factionParam/:shipParam"
                element={
                  <SelectQuickBuild
                    handleSelect={handleSelectBuild}
                    previewOnly={previewOnly}
                  />
                }
              />
            </Routes>
          </RouteWrapper>
        </SelectionArea>
      </LowerArea>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="uniqueness-violation-modal"
        aria-describedby="uniqueness-violation-description"
        sx={modalSx}
      >
        <ModalWrapper>
          <h1>Uniqueness Violation:</h1>
          <p>
            The following cards cannot be selected because you already have
            pilots or upgrades with uniqueness limitations.
          </p>
          <br />
          <ul>
            {conflictList.map((conflict) => (
              <li key={conflict.name}>
                <Tooltip
                  title={`Limit ${conflict.limited} per squad`}
                  placement="right"
                  arrow
                >
                  <p style={{ fontWeight: 'bold' }}>{conflict.name}</p>
                </Tooltip>
              </li>
            ))}
          </ul>
          <Button
            variant="contained"
            sx={{ position: 'absolute', right: '30px', bottom: '30px' }}
            onClick={() => setModalOpen(false)}
          >
            OK
          </Button>
        </ModalWrapper>
      </Modal>
      <Modal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        sx={modalSx}
      >
        <ConfirmModalWrapper>
          <h3>
            <span style={{ color: 'red' }}>
              Faction Rules are being enforced.
            </span>
            <br />
            <span
              style={{
                color: 'red',
                fontSize: '20px',
              }}
            >
              (you can change this in settings)
            </span>
            <br />
            <br />
            Changing factions will clear out all currently selected quick
            builds.
          </h3>
          <p>Are you sure you want to change?</p>
          {/* TODO: Add faction changes */}
          <div className="confirmation-actions">
            <Button onClick={handleConfirmFactionChange} variant="contained">
              Yes
            </Button>
            <Button
              onClick={cancelFactionChange}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
          </div>
        </ConfirmModalWrapper>
      </Modal>
    </QuickBuildWrapper>
  );
}

export default QuickBuild;
