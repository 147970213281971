import React from 'react';
import styled, { keyframes } from 'styled-components';

function TextCrawl(props) {
  const { children, title, subtitle, episode, topMargin, flipTitle, ...rest } =
    props;

  return (
    <Wrapper topMargin={topMargin} {...rest}>
      <div className="fade"></div>
      <section className="star-wars">
        <div className="crawl">
          <div className="title">
            {!flipTitle && <p>{episode}</p>}
            <h1 style={{ marginBottom: flipTitle ? '20px' : '100px' }}>
              {title}
            </h1>
            {subtitle && (
              <h1 style={{ marginBottom: flipTitle ? '20px' : '100px' }}>
                {subtitle}
              </h1>
            )}

            {flipTitle && <p style={{ marginBottom: '100px' }}>{episode}</p>}
          </div>

          {children}
        </div>
      </section>
    </Wrapper>
  );
}

const crawl = keyframes`
    0% {
      top: 800px;
      transform: rotateX(20deg) translateZ(0);
    }
    100% {
      top: -6000px;
      transform: rotateX(25deg) translateZ(-2500px);
    }
`;

const Wrapper = styled.section`
  position: absolute;
  width: 100%;
  height: calc(100vh - 64px);
  top: ${(props) => (props.topMargin ? props.topMargin + 'px' : '0')};
  overflow: hidden;

  .fade {
    position: relative;
    width: 100%;
    min-height: 20vh;
    top: -25px;
    background-image: linear-gradient(0deg, transparent, black 75%);
    z-index: 1;
  }

  .star-wars {
    display: flex;
    justify-content: center;
    position: relative;
    height: 800px;
    color: #feda4a;
    font-family: 'Pathway Gothic One', sans-serif;
    font-size: 500%;
    font-weight: 600;
    letter-spacing: 6px;
    line-height: 150%;
    perspective: 400px;
    text-align: justify;
    text-shadow: 1px 1px 6px #feda4a, 1px 1px 8px black;
  }

  .crawl {
    position: relative;
    top: 99999px;
    transform-origin: 50% 100%;
    animation: ${crawl} 60s linear 60s;
  }

  .crawl > .title {
    font-size: 90%;
    text-align: center;
  }

  .crawl > .title h1 {
    margin: 0 0 100px;
    text-transform: uppercase;
  }
`;

export default TextCrawl;
