import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, IconButton, Modal, Tooltip } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ScreenRotationAltIcon from '@mui/icons-material/ScreenRotationAlt';

import { createArrayWithLengthN, names } from '../../utils';
import { replaceTextWithTokens } from './textUtils';
import { getCallSignStyles } from './gameUtils';
import { Silhouette, parseUpgradeText } from './upgradeUtils';

import colors from '../../styles/colors';
import { ModalContainer, StatColumn } from './PilotCard.styles';
import TokenIcon from './TokenIcon';
import ArcIcon from '../../images/arcs/ArcIcon';
import ActionIcon from '../../components/ActionIcon';
import StatBlock from './StatBlock';
import RechargeArrow from '../../components/RechargeArrow';

const X_WING_UPGRADES = 'X_WING_UPGRADES';

const getUpgradeOpenStateMap = () => {
  let openStateObject = localStorage.getItem(X_WING_UPGRADES);
  if (!openStateObject) return (openStateObject = {});

  return (openStateObject = JSON.parse(openStateObject));
};

const storeOpenState = (pilotId, upgrade, isExpanded) => {
  const openStateObject = getUpgradeOpenStateMap();
  openStateObject[`${pilotId}-${upgrade.title}`] = !isExpanded;
  localStorage.setItem(X_WING_UPGRADES, JSON.stringify(openStateObject));
};

const getUpgradeOpenState = (pilotId, upgrade) => {
  const openStateObject = getUpgradeOpenStateMap();
  return Boolean(openStateObject[`${pilotId}-${upgrade.title}`]);
};

function UpgradeCard({
  upgrade,
  handleChargeClick,
  handleFlipClick,
  pilotId,
  pilotNameData,
}) {
  const { charges, maxCharges } = upgrade;

  const callSignStyle = getCallSignStyles(
    pilotNameData?.callSign || pilotNameData?.name || ''
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const expandClick = () => {
    storeOpenState(pilotId, upgrade, isExpanded);
    setIsExpanded(!isExpanded);
  };

  const handleFlip = () => {
    handleFlipClick(upgrade.title);
  };

  useEffect(() => {
    setIsExpanded(getUpgradeOpenState(pilotId, upgrade));
  }, [pilotId, upgrade]);

  const chargeTokens = maxCharges
    ? createArrayWithLengthN(maxCharges).map((_, i) => {
        const empty = i > charges - 1;
        const colorOverride = empty ? colors.darkRed : undefined;

        return (
          <ChargeTokenContainer
            key={i}
            onClick={() => handleChargeClick(upgrade.title, empty ? 1 : -1)}
          >
            <TokenIcon tokenName="charge" colorOverride={colorOverride} />
          </ChargeTokenContainer>
        );
      })
    : [];

  const twoSided = !!upgrade.back;

  const upgradeDisplayData = !twoSided
    ? upgrade
    : upgrade.isFaceUp
    ? upgrade
    : upgrade.back;

  const hasWeapon = !!upgrade?.weapon;
  const wpn = upgrade?.weapon;
  const rangeDisclaimerText =
    '[Do not apply Range Bonuses to attacks with this weapon.]';
  const hasRangeDisclaimer =
    hasWeapon && wpn?.range?.includes(rangeDisclaimerText);

  const rangeText = wpn?.range?.replace(rangeDisclaimerText, '');

  const {
    text: abilityText,
    actions,
    bonuses,
  } = parseUpgradeText(upgradeDisplayData.ability);

  const cardImage = upgrade.cardImage
    ? `/upgrades/${upgrade.cardImage}`
    : upgrade.imgUrl;
  const cardBackImage = upgrade?.back?.cardImage
    ? `/upgrades/${upgrade?.back?.cardImage}`
    : upgrade?.back?.imgUrl;

  return (
    <UpgradeContainer
      className={names(
        {
          expanded: isExpanded,
          collapsed: !isExpanded,
        },
        'upgrade-container'
      )}
    >
      {twoSided && (
        <Top>
          <Silhouette
            upgrade={upgrade}
            callSignStyle={callSignStyle}
            width="100%"
          />
          <Tooltip title="Flip Card">
            <IconButton color="success" onClick={handleFlip} size="large">
              <ScreenRotationAltIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Top>
      )}

      <Left>
        <AbilityName
          className={names(
            {
              force: upgrade.maxForceCharges > 0,
              hull: upgrade.name.includes('Hull Upgrade'),
              shield: upgrade.name.includes('Shield Upgrade'),
            },
            'ability-name'
          )}
          onClick={expandClick}
          expanded={isExpanded}
          title={upgradeDisplayData.ability}
        >
          {isExpanded ? '▲ ' : '▼ '}
          {upgradeDisplayData.name}
        </AbilityName>
        <AbilityContainer expanded={isExpanded}>
          {replaceTextWithTokens(abilityText, colors.white)}
        </AbilityContainer>
        {hasWeapon && (
          <WeaponInfo>
            <StatColumn className="wpn-col">
              <div className="top" style={{ marginBottom: '6px' }}>
                <ArcIcon type={wpn?.arc} size="36px" showRing />
              </div>
              <p className="wpn">{wpn?.attackDice}</p>
            </StatColumn>
            <div className="range-info">
              <div className="range-value">Range: {rangeText}</div>
              {hasRangeDisclaimer && (
                <div className="range-disclaimer">
                  (Do not apply Range Bonuses to attacks with this weapon.)
                </div>
              )}
            </div>
          </WeaponInfo>
        )}
      </Left>
      <Right className={names({ horizontal: isExpanded })}>
        {upgrade.recharge && (
          <Tooltip
            title={`Recharge ${upgrade.recharge} token${
              upgrade.recharge > 1 ? 's' : ''
            } in END phase`}
          >
            <span
              className={names(
                { horizontal: isExpanded },
                'recharges goldenRod'
              )}
            >
              {createArrayWithLengthN(upgrade.recharge).map((_, i) => (
                <RechargeArrow key={i} fill={colors.goldenrod} />
              ))}
            </span>
          </Tooltip>
        )}
        <Charges
          className={names(
            {
              horizontal: isExpanded,
              vertical: !isExpanded,
              'without-recharges': !upgrade.recharge,
            },
            'charges-container'
          )}
          maxCharges={maxCharges}
        >
          {chargeTokens}
        </Charges>
        {bonuses.length > 0 && (
          <Bonuses>
            {bonuses.map((bonus, i) => (
              <StatBlock
                key={bonus.value + i}
                type={bonus.value}
                sign={bonus.sign}
                number={bonus.mod}
                size="2.5em"
                horizontal
              />
            ))}
          </Bonuses>
        )}

        {actions.length > 0 && (
          <ActionsStack className="action-stack">
            {actions.map((action, i) => (
              <ActionContainer key={i}>
                <ActionIcon action={action} />
              </ActionContainer>
            ))}
          </ActionsStack>
        )}

        <InfoButton onClick={setInfoModalOpen} className="info">
          <InfoOutlinedIcon fontSize="small" />
        </InfoButton>
      </Right>

      <Modal open={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
        <ModalContainer factionColor={'pink'}>
          <Button
            className="close-button"
            onClick={() => setInfoModalOpen(false)}
          >
            <CloseOutlinedIcon fontSize="large" />
          </Button>
          <ImagesContainer>
            <ImageContainer>
              {twoSided && <FrontImageLabel>Front</FrontImageLabel>}
              <UpgradeCardImg src={cardImage} alt="image not available" />
            </ImageContainer>
            {twoSided && (
              <ImageContainer>
                <BackImageLabel>Back</BackImageLabel>
                <UpgradeCardImg
                  className="back"
                  src={cardBackImage}
                  alt="image not available"
                />
              </ImageContainer>
            )}
          </ImagesContainer>
          <div className="wikia-link">
            <a
              href={
                'https://xwing-miniatures-second-edition.fandom.com/' +
                upgrade.wikiLink
              }
              target="_blank"
              rel="noreferrer"
            >
              X-Wing Miniatures: Second Edition Wiki - {upgrade.title}
            </a>
          </div>
        </ModalContainer>
      </Modal>
    </UpgradeContainer>
  );
}

export const UpgradeContainer = styled.div`
  position: relative;
  max-width: 300px;
  min-width: 250px;
  height: 100%;
  padding: 12px;
  padding-bottom: 20px;
  border: 1px solid white;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;

  &.expanded {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }

  .goldenRod {
    color: ${colors.goldenrod};
    font-size: 12px;
    cursor: context-menu;
  }

  &:hover {
    .info {
      opacity: 0.3;
    }
  }
`;

const Top = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  place-items: center;
`;

const Left = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 8px;
  grid-column: 1;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  .action-stack {
    margin-top: 20px;
    margin-right: -13px;
  }

  &.horizontal {
    display: grid;
    grid-template-columns: 15px 1fr auto;
    width: 100%;
    gap: 8px;

    .charges-container {
      margin-right: 20px;
      justify-content: flex-start;
    }
    .charges-container.without-recharges {
      margin-left: 12px;
    }
    .action-stack {
      margin-top: 0px;
    }
  }

  .recharges {
    padding: 10px;
    height: 30px;
    width: fit-content;
    display: flex;
    gap: 2px;

    &.horizontal {
      padding: 0px;
      height: 30px;
      flex-direction: column;
      align-items: center;
    }

    svg {
      width: 12px;
    }
  }
`;

export const AbilityName = styled.div`
  display: flex;
  align-items: start;
  cursor: pointer;
  text-orientation: sideways;
  color: lightblue;
  font-weight: bold;

  &.force {
    color: ${colors.forcePurple};
  }
  &.hull {
    color: ${colors.hullYellow};
  }
  &.shield {
    color: ${colors.shieldBlue};
  }

  span {
    font-size: 16px;
    margin-left: 6px;
    margin-top: -4px;
  }
`;
const AbilityContainer = styled.div`
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
  font-size: 16px;
`;

const WeaponInfo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  align-items: start;
  gap: 8px;
  color: ${colors.weaponRed};

  .wpn-col {
    margin-right: 8px;
  }

  .wpn {
    font-family: 'Teko', 'roboto', sans-serif;
    font-size: 30px;
    font-weight: bold;
    margin-top: -4px;
  }

  .range-value {
    font-family: 'Teko', 'roboto', sans-serif;
    font-size: 28px;
  }

  .range-disclaimer {
    font-size: 0.7rem;
    font-style: italic;
    font-weight: 300;
  }

  svg {
    fill: ${colors.weaponRed};
  }
`;

const Charges = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  gap: 4px;
  align-items: center;
  cursor: pointer;

  &.horizontal {
    grid-template-columns: repeat(${({ maxCharges }) => maxCharges || 5}, auto);
  }

  svg {
    path {
      transition: fill 0.25s ease;
    }

    height: 40px;
    width: 40px;
    margin-bottom: 8px;
  }
`;

const Bonuses = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
`;

const ActionsStack = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  width: 100px;
  grid-column: 3;
`;

const ActionContainer = styled.div`
  display: grid;
  place-items: center;
  padding: 2px 6px;
  border-radius: 12px 0 0 12px;
  border: 1px solid white;
  gap: 4px;
  margin-bottom: 12px;
`;

const InfoButton = styled.button`
  position: absolute;
  bottom: 6px;
  right: 6px;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
  color: white;

  svg {
    cursor: pointer !important;
  }

  &:hover {
    opacity: 1 !important;
  }
`;

const ChargeTokenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImagesContainer = styled.div`
  display: flex;

  .back {
    margin-left: 20px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;

  .image-placeholder {
    color: #666;
  }
`;

const FrontImageLabel = styled.h3`
  color: ${colors.shieldBlue};
`;

const BackImageLabel = styled.h3`
  color: ${colors.forcePurple};
`;

export const UpgradeCardImg = styled.img`
  width: 500px;
  height: 400px;
  object-fit: contain;
  margin: 0 auto;
`;

export default UpgradeCard;
