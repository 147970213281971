import AlphaClassStarWing from './AlphaStarwing.webp';
import A$SF$01_B$Wing from './A-SF-01_B-Wing_silhouette.png';
import Aggressor_Assault_Fighter from './Aggressor_Assault_Fighter_silhouette.png';
import ARC$170_Starfighter from './ARC-170_Starfighter_silhouette.png';
import Attack_Shuttle from './Attack_Shuttle_silhouette.png';
import Auzituck_Gunship from './Auzituck_Gunship_silhouette.png';
import Belbullab$22_Starfighter from './Belbullab-22_Starfighter_silhouette.png';
import BTL$A4_Y$Wing from './BTL-A4_Y-Wing_silhouette.png';
import BTL$B_Y$Wing from './BTL-B_Y-Wing_silhouette.png';
import BTL$S8_K$Wing from './BTL-S8_K-Wing_silhouette.png';
import C$ROC_Cruiser from './C-ROC_Cruiser_silhouette.png';
import CR90_Corellian_Corvette from './CR90_Corellian_Corvette_silhouette.png';
import Customized_YT$1300_Light_Freighter from './Customized_YT-1300_Light_Freighter_silhouette.png';
import Delta$7_Aethersprite from './Delta-7_Aethersprite_silhouette.png';
import Delta$7B_Aethersprite from './Delta-7B_Aethersprite_silhouette.png';
import Droid_Tri$Fighter from './Droid_Tri-Fighter_silhouette.png';
import E$Wing from './E-Wing_silhouette.png';
import Escape_Craft from './Escape_Craft_silhouette.png';
import Eta$2_Actis from './Eta-2_Actis_silhouette.png';
import Fang_Fighter from './Fang_Fighter_silhouette.png';
import Fireball from './Fireball_silhouette.png';
import Firespray$class_Patrol_Craft from './Firespray-class_Patrol_Craft_silhouette.png';
import G$1A_Starfighter from './G-1A_Starfighter_silhouette.png';
import Gauntlet_Fighter from './Gauntlet_Fighter_silhouette.png';
import Gozanti$class_Cruiser from './Gozanti-class_Cruiser_silhouette.png';
import GR$75_Medium_Transport from './GR-75_Medium_Transport_silhouette.png';
import HMP_Droid_Gunship from './HMP_Droid_Gunship_silhouette.png';
import HWK$290_Light_Freighter from './HWK-290_Light_Freighter_silhouette.png';
import Hyena$class_Droid_Bomber from './Hyena-class_Droid_Bomber_silhouette.png';
import JumpMaster_5000 from './JumpMaster_5000_silhouette.png';
import Kihraxz_Fighter from './Kihraxz_Fighter_silhouette.png';
import LAAT$i_Gunship from './LAAT-i_Gunship_silhouette.png';
import Lambda$class_T$4a_Shuttle from './Lambda-class_T-4a_Shuttle_silhouette.png';
import Lancer$class_Pursuit_Craft from './Lancer-class_Pursuit_Craft_silhouette.png';
import M3$A_Interceptor from './M3-A_Interceptor_silhouette.png';
import M12$L_Kimogila_Fighter from './M12-L_Kimogila_Fighter_silhouette.png';
import MG$100_StarFortress from './MG-100_StarFortress_silhouette.png';
import Modified_TIE$ln_Fighter from './Modified_TIE-ln_Fighter_silhouette.png';
import Modified_YT$1300_Light_Freighter from './Modified_YT-1300_Light_Freighter_silhouette.png';
import Naboo_Royal_N$1_Starfighter from './Naboo_Royal_N-1_Starfighter_silhouette.png';
import Nantex$class_Starfighter from './Nantex-class_Starfighter_silhouette.png';
import Nimbus$class_V$Wing from './Nimbus-class_V-Wing_silhouette.png';
import Quadrijet_Transfer_Spacetug from './Quadrijet_Transfer_Spacetug_silhouette.png';
import Raider$class_Corvette from './Raider-class_Corvette_silhouette.png';
import Resistance_Transport_Pod from './Resistance_Transport_Pod_silhouette.png';
import Resistance_Transport from './Resistance_Transport_silhouette.png';
import RZ$1_A$Wing from './RZ-1_A-Wing_silhouette.png';
import RZ$2_A$Wing from './RZ-2_A-Wing_silhouette.png';
import Scavenged_YT$1300 from './Scavenged_YT-1300_silhouette.png';
import Scurrg_H$6_Bomber from './Scurrg_H-6_Bomber_silhouette.png';
import Sheathipede$class_Shuttle from './Sheathipede-class_Shuttle_silhouette.png';
import Sith_Infiltrator from './Sith_Infiltrator_silhouette.png';
import ST$70_Assault_Ship from './ST-70_Assault_Ship_silhouette.png';
import StarViper$class_Attack_Platform from './StarViper-class_Attack_Platform_silhouette.png';
import T$65_X$Wing from './T-65_X-Wing_silhouette.png';
import T$70_X$Wing from './T-70_X-Wing_silhouette.png';
import TIE_Advanced_v1 from './TIE_Advanced_v1_silhouette.png';
import TIE_Advanced_x1 from './TIE_Advanced_x1_silhouette.png';
import TIE_Interceptor from './TIE_Interceptor_silhouette.png';
import TIE_Reaper from './TIE_Reaper_silhouette.png';
import TIE$ag_Aggressor from './TIE-ag_Aggressor_silhouette.png';
import TIE$ba_Interceptor from './TIE-ba_Interceptor_silhouette.png';
import TIE$ca_Punisher from './TIE-ca_Punisher_silhouette.png';
import TIE$D_Defender from './TIE-D_Defender_silhouette.png';
import TIE$fo_Fighter from './TIE-fo_Fighter_silhouette.png';
import TIE$ln_Fighter from './TIE-ln_Fighter_silhouette.png';
import TIE$ph_Phantom from './TIE-ph_Phantom_silhouette.png';
import TIE$rb_Heavy from './TIE-rb_Heavy_silhouette.png';
import TIE$sa_Bomber from './TIE-sa_Bomber_silhouette.png';
import TIE$se_Bomber from './TIE-se_Bomber_silhouette.png';
import TIE$sf_Fighter from './TIE-sf_Fighter_silhouette.png';
import TIE$sk_Striker from './TIE-sk_Striker_silhouette.png';
import TIE$vn_Silencer from './TIE-vn_Silencer_silhouette.png';
import TIE$wi_Whisper_Modified_Interceptor from './TIE-wi_Whisper_Modified_Interceptor_silhouette.png';
import Trident$class_Assault_Ship from './Trident-class_Assault_Ship_silhouette.png';
import Upsilon$class_Command_Shuttle from './Upsilon-class_Command_Shuttle_silhouette.png';
import UT$60D_U$Wing from './UT-60D_U-Wing_silhouette.png';
import V$19_Torrent_Starfighter from './V-19_Torrent_Starfighter_silhouette.png';
import VCX$100_Light_Freighter from './VCX-100_Light_Freighter_silhouette.png';
import VT$49_Decimator from './VT-49_Decimator_silhouette.png';
import Vulture$class_Droid_Fighter from './Vulture-class_Droid_Fighter_silhouette.png';
import Xi$class_Light_Shuttle from './Xi-class_Light_Shuttle_silhouette.png';
import YT$2400_Light_Freighter from './YT-2400_Light_Freighter_silhouette.png';
import YV$666_Light_Freighter from './YV-666_Light_Freighter_silhouette.png';
import Z$95$AF4_Headhunter from './Z-95-AF4_Headhunter_silhouette.png';

const shipSilhouettes = {
  'Alpha-class Star Wing': AlphaClassStarWing,
  'A/SF-01 B-Wing': A$SF$01_B$Wing,
  'Aggressor Assault Fighter': Aggressor_Assault_Fighter,
  'ARC-170 Starfighter': ARC$170_Starfighter,
  'Attack Shuttle': Attack_Shuttle,
  'Auzituck Gunship': Auzituck_Gunship,
  'Belbullab-22 Starfighter': Belbullab$22_Starfighter,
  'BTA-NR2 Y-Wing': BTL$A4_Y$Wing,
  'BTL-A4 Y-Wing': BTL$A4_Y$Wing,
  'BTL-B Y-Wing': BTL$B_Y$Wing,
  'BTL-S8 K-Wing': BTL$S8_K$Wing,
  'C-ROC Cruiser': C$ROC_Cruiser,
  'CR90 Corellian Corvette': CR90_Corellian_Corvette,
  'Customized YT-1300 Light Freighter': Customized_YT$1300_Light_Freighter,
  'Delta-7 Aethersprite': Delta$7_Aethersprite,
  'Delta-7B Aethersprite': Delta$7B_Aethersprite,
  'Droid Tri-Fighter': Droid_Tri$Fighter,
  'E-Wing': E$Wing,
  'Escape Craft': Escape_Craft,
  'Eta-2 Actis': Eta$2_Actis,
  'Fang Fighter': Fang_Fighter,
  Fireball: Fireball,
  'Firespray-class Patrol Craft': Firespray$class_Patrol_Craft,
  'G-1A Starfighter': G$1A_Starfighter,
  'Gauntlet Fighter': Gauntlet_Fighter,
  'Gozanti-class Cruiser': Gozanti$class_Cruiser,
  'GR-75 Medium Transport': GR$75_Medium_Transport,
  'HMP Droid Gunship': HMP_Droid_Gunship,
  'HWK-290 Light Freighter': HWK$290_Light_Freighter,
  'Hyena-class Droid Bomber': Hyena$class_Droid_Bomber,
  'JumpMaster 5000': JumpMaster_5000,
  'Kihraxz Fighter': Kihraxz_Fighter,
  'LAAT/i Gunship': LAAT$i_Gunship,
  'Lambda-class T-4a Shuttle': Lambda$class_T$4a_Shuttle,
  'Lancer-class Pursuit Craft': Lancer$class_Pursuit_Craft,
  'M3-A Interceptor': M3$A_Interceptor,
  'M12-L Kimogila Fighter': M12$L_Kimogila_Fighter,
  'MG-100 StarFortress': MG$100_StarFortress,
  'Modified TIE/ln Fighter': Modified_TIE$ln_Fighter,
  'Modified YT-1300 Light Freighter': Modified_YT$1300_Light_Freighter,
  'Naboo Royal N-1 Starfighter': Naboo_Royal_N$1_Starfighter,
  'Nantex-class Starfighter': Nantex$class_Starfighter,
  'Nimbus-class V-Wing': Nimbus$class_V$Wing,
  'Quadrijet Transfer Spacetug': Quadrijet_Transfer_Spacetug,
  'Raider-class Corvette': Raider$class_Corvette,
  'Resistance Transport Pod': Resistance_Transport_Pod,
  'Resistance Transport': Resistance_Transport,
  'RZ-1 A-Wing': RZ$1_A$Wing,
  'RZ-2 A-Wing': RZ$2_A$Wing,
  'Scavenged YT-1300': Scavenged_YT$1300,
  'Scurrg H-6 Bomber': Scurrg_H$6_Bomber,
  'Sheathipede-class Shuttle': Sheathipede$class_Shuttle,
  'Sith Infiltrator': Sith_Infiltrator,
  'ST-70 Assault Ship': ST$70_Assault_Ship,
  'StarViper-class Attack Platform': StarViper$class_Attack_Platform,
  'T-65 X-Wing': T$65_X$Wing,
  'T-70 X-Wing': T$70_X$Wing,
  'TIE Advanced v1': TIE_Advanced_v1,
  'TIE Advanced/x1': TIE_Advanced_x1,
  'TIE/In Interceptor': TIE_Interceptor,
  'TIE Reaper': TIE_Reaper,
  'TIE/ag Aggressor': TIE$ag_Aggressor,
  'TIE/ba Interceptor': TIE$ba_Interceptor,
  'TIE/ca Punisher': TIE$ca_Punisher,
  'TIE/D Defender': TIE$D_Defender,
  'TIE/fo Fighter': TIE$fo_Fighter,
  'TIE/ln Fighter': TIE$ln_Fighter,
  'TIE/ph Phantom': TIE$ph_Phantom,
  'TIE/rb Heavy': TIE$rb_Heavy,
  'TIE/sa Bomber': TIE$sa_Bomber,
  'TIE/se Bomber': TIE$se_Bomber,
  'TIE/sf Fighter': TIE$sf_Fighter,
  'TIE/sk Striker': TIE$sk_Striker,
  'TIE/vn Silencer': TIE$vn_Silencer,
  'TIE/wi Whisper Modified Interceptor': TIE$wi_Whisper_Modified_Interceptor,
  'Trident-class Assault Ship': Trident$class_Assault_Ship,
  'Upsilon-class Command Shuttle': Upsilon$class_Command_Shuttle,
  'UT-60D U-Wing': UT$60D_U$Wing,
  'V-19 Torrent': V$19_Torrent_Starfighter,
  'VCX-100 Light Freighter': VCX$100_Light_Freighter,
  'VT-49 Decimator': VT$49_Decimator,
  'Vulture-class Droid': Vulture$class_Droid_Fighter,
  'Xi-class Light Shuttle': Xi$class_Light_Shuttle,
  'YT-2400 Light Freighter': YT$2400_Light_Freighter,
  'YV-666 Light Freighter': YV$666_Light_Freighter,
  'Z-95-AF4 Headhunter': Z$95$AF4_Headhunter,
};

const getSilhouette = (shipName) => {
  return shipSilhouettes[shipName];
};

export default getSilhouette;
