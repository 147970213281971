import colors from '../../styles/colors';

export const transformTokenState = (tokenArray, condenseAll) => {
  const tokenCounts = {};
  tokenArray.forEach((t) => {
    if (tokenCounts[t] >= 1) {
      return tokenCounts[t]++;
    }
    return (tokenCounts[t] = 1);
  });

  let totalTokens = 0;
  for (let token in tokenCounts) {
    const count = tokenCounts[token];
    totalTokens += count;
  }

  const tokenList = [];
  for (let token in tokenCounts) {
    const count = tokenCounts[token];

    if (count > 3 || totalTokens > 8 || condenseAll) {
      tokenList.push({ token, count });
    } else {
      for (let i = 0; i < count; i++) {
        tokenList.push({ token, count: 1 });
      }
    }
  }

  // tokenList.sort((a, b) => {
  //   if (a.token === 'stress' || b.token === 'stress') {
  //     return a.token === 'stress' ? -1 : 1;
  //   }

  //   return a.token.localeCompare(b.token);
  // });

  return tokenList.filter((item) => item.count);
};

const alternateFontSize = '14px';

const blackStyles = {
  color: 'black',
  fontSize: alternateFontSize,
  outline: 'white',
  textShadow:
    '1px 1px 2px #999, -1px -1px 2px #999, -1px 1px 2px #999, 1px -1px 2px #999',
};

export const getCallSignStyles = (callSign = '') => {
  const formattedCallSign = callSign.toLowerCase();

  if (formattedCallSign.includes('red'))
    return { color: 'red', outline: 'white' };

  if (formattedCallSign.includes('crimson'))
    return { color: '#9D2235', outline: 'white' };

  if (formattedCallSign.includes('gold'))
    return { color: 'gold', outline: 'white' };

  if (formattedCallSign.includes('phoenix'))
    return { color: 'orange', outline: 'white' };

  if (formattedCallSign.includes('green'))
    return { color: 'green', outline: 'white' };

  if (formattedCallSign.includes('blue'))
    return { color: 'rgb(0, 119, 225)', outline: 'white' };

  if (formattedCallSign.includes('gray'))
    return { color: '#777', outline: 'white' };

  if (formattedCallSign.includes('sage'))
    return { color: 'rgb(156,175,136)', outline: 'white' };

  if (formattedCallSign.includes('black')) return blackStyles;

  if (formattedCallSign.includes('onyx')) return blackStyles;

  if (formattedCallSign.includes('obsidian')) return blackStyles;

  return { color: 'white' };
};

export const factionMap = {
  rebel: 'Rebels',
  empire: 'Empire',
  scum: 'Scum and Villainy',
  republic: 'Galactic Republic',
  separatist: 'Separatists',
  resistance: 'Resistance',
  firstOrder: 'First Order',
};

export const factionColors = {
  Rebellion: colors.rebelRed,
  'Galactic Empire': '#35b',
  scum: 'forestgreen',
  republic: 'maroon',
  separatist: 'blue',
  resistance: 'orange',
  firstOrder: 'grey',
};
