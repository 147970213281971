import React from 'react';
import styled from 'styled-components';
import Icon from '../images/tokens/Icon';
import { Tooltip } from '@mui/material';
import { getActionData } from './actionUtils';

function ActionIcon({ action, size }) {
  const actionData = getActionData(action);
  const [action1, action2] = actionData.icons;

  if (action2) {
    return (
      <Tooltip title={actionData.label} placement="left" arrow>
        <LinkedActions>
          <Icon
            type={action1.key}
            color={action1.color}
            sx={{ height: size || '32px', width: size || '32px' }}
          />
          <span>►</span>
          <Icon
            type={action2.key}
            color={action2.color}
            sx={{ height: size || '32px', width: size || '32px' }}
          />
        </LinkedActions>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={actionData.label} placement="left" arrow followCursor>
      <ActionContainer>
        <Icon
          type={action1.key}
          color={action1.color}
          sx={{ height: size || '32px', width: size || '32px' }}
        />
      </ActionContainer>
    </Tooltip>
  );
}

const ActionContainer = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: help;
`;

const LinkedActions = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: help;
  span {
    margin: 0 2px;
    font-size: 10px;
  }
`;

export default ActionIcon;
