import bg1 from './backgrounds/bg1.jpg';
import bg2 from './backgrounds/bg2.png';
import bg3 from './backgrounds/bg3.jpg';
import bg4 from './backgrounds/bg4.jpg';
import bg5 from './backgrounds/bg5.jpg';
import bg6 from './backgrounds/bg6.jpg';
import bg7 from './backgrounds/bg7.jpg';
import bg8 from './backgrounds/bg8.jpg';
import bg9 from './backgrounds/bg9.jpg';
import bg10 from './backgrounds/bg10.jpg';
import bg11 from './backgrounds/bg11.jpg';
import bg12 from './backgrounds/bg12.jpg';

export const randomBackground = () => {
  const backgrounds = [
    { background: bg1, opacity: 0.5 }, // ds
    { background: bg2, opacity: 0.5, increaseBlur: true }, // trench
    { background: bg3, opacity: 0.7 }, // blue star field
    { background: bg4, opacity: 0.7 }, // black star field
    { background: bg5, opacity: 0.7 }, // ds and endor
    {
      background: bg6,
      opacity: 0.65,
      isSilhouette: true,
      silhouetteColor: '#6E0000',
    }, // sunset tie fighters
    { background: bg7, opacity: 0.5 }, // ds rising
    { background: bg8, opacity: 0.5 }, // asteroid field
    { background: bg9, opacity: 0.75, increaseBlur: true }, // star destroyers
    { background: bg10, opacity: 0.5 }, // space battle
    { background: bg11, opacity: 0.75 }, // star destroyer
    { background: bg12, opacity: 0.6 }, // star destroyer flotilla
  ];
  const randomIndex = Math.floor(Math.random() * backgrounds.length);

  return backgrounds[randomIndex];
};
