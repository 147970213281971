import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

function Header() {
  return (
    <NavContainer>
      <NavLink to="/quick-build">
        <Button>Quick Build</Button>
      </NavLink>
      <h1>Star Wars X-Wing Card & Ship Helper</h1>
      <NavLink to="/game-board">
        <Button>Resume Game</Button>
      </NavLink>
    </NavContainer>
  );
}

const NavContainer = styled.nav`
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #222;
  background-color: #111;
  width: 100%;
  height: 64px;
  z-index: 200;

  h1 {
    color: yellow;
    font-family: 'Pathway Gothic One', sans-serif;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

export default Header;
