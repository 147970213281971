import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getShipsFromName } from '../../utils';
import { GameDataContext } from '../../context/gameData';

import ShipCard from './ShipCard';

const getFactionCards = (quickBuilds = {}, faction) => {
  if (faction) {
    return Object.keys(quickBuilds[faction] || {});
  }

  return [];
};

function ShipSelect() {
  const { factionParam } = useParams();
  const { gameData } = useContext(GameDataContext);

  const shipList = getFactionCards(gameData.quickBuilds, factionParam);
  console.log('shipList:', shipList);
  console.log('gameData.shipMap:', gameData.shipMap);

  const shipsWithData = getShipsFromName(shipList, gameData.shipMap);

  return (
    <Container className="ship-select-container">
      {shipsWithData && (
        <CardsContainer>
          {shipsWithData.length > 0 &&
            shipsWithData.map((ship, i) => {
              const key = `${ship.name} + ${i}`;
              const path = encodeURIComponent(`${ship.path}`);

              return <ShipCard key={key} path={path} ship={ship} />;
            })}
        </CardsContainer>
      )}
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;

  .faction-name {
    padding: 8px;
    border-radius: 8px;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;
  padding: 12px 0;
  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 25%);
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 33.3%);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 50%);
  }
  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`;

export default ShipSelect;
