import React from 'react';
import styled from 'styled-components';

import StatBlock from './GameBoard/StatBlock';
import colors from '../styles/colors';
import ArcIcon from '../images/arcs/ArcIcon';
import TokenIcon from './GameBoard/TokenIcon';
import Token from '../images/tokens/Token';
import Icon from '../images/tokens/Icon';
import StatIcon from '../components/StatIcon';
import XOverX from '../components/XOverX';

function Sandbox() {
  return (
    <Page>
      <h1>Sandbox</h1>
      <Content>
        <StatIcon size="200px" type="bullseye" number={3} showRing />
        <StatIcon size="200px" type="agility" number={3} sign="-" showRing />
        <StatIcon size="200px" type="shield" number={3} showRing recharge={6} />
        <StatIcon
          size="200px"
          type="charge"
          number={<XOverX top={3} bottom={2} />}
          showRing
          recharge={3}
        />
        <StatIcon size="200px" type="force" number={3} showRing recharge={2} />
        <StatIcon size="50px" type="force" number={3} showRing recharge={2} />
        <StatIcon size="50px" type="force" number={3} showRing recharge={6} />
        <StatIcon size="200px" type="front arc" number={3} />
        <StatBlock size="200px" type="front-arc" number="2/3" />
        <StatBlock size="200px" type="agility" number="2" />
        <StatBlock size="200px" type="shield" number="2" />
        <StatBlock size="200px" type="force" number="2" recharge={1} />
        <StatBlock size="200px" type="charge" number="3" recharge={2} />
        <StatBlock size="200px" type="force" number="4" recharge={3} />
        <StatBlock size="200px" type="charge" number="5" recharge={1} />
        <StatBlock size="200px" type="hull" number="4" />
        <StatBlock size="200px" type="hull" number="4" />
        <ArcIcon type="rear arc" size="200px" />
        <ArcIcon type="left arc" size="200px" />
        <ArcIcon type="right arc" size="200px" />
        <TokenIcon tokenName="Deplete" size="large" />
        <ArcIcon type="double turret" size="200px" />
        <ArcIcon type="bullseye arc" size="200px" />
        <ArcIcon type="full front" size="200px" />
        <ArcIcon type="turret" size="200px" />
        <Icon type="Deplete" size="200px" />
        <Token type="Deplete" />
      </Content>
    </Page>
  );
}

const Page = styled.div`
  padding: 20px;

  h1 {
    text-align: center;
    color: ${colors.hullYellow};
  }
`;

const Content = styled.div`
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px 0;
`;

export default Sandbox;
