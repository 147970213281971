import React from 'react';

function CritHitIcon({ color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="1000"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...rest}
    >
      <g>
        <path
          fill={color || '#000'}
          fillOpacity="1"
          stroke="none"
          strokeWidth="0.443"
          d="M497.834 16.357l-62.078 330.61-249.762-207.893 152.31 287.297-231.714 20.211 212.947 96.729L73.385 759.867l301.013-106.113-83.013 329.889 201.396-288.02 144.371 192.734-21.656-237.49 311.12 93.842-248.32-201.398 233.882-98.893-256.979-20.213 153.031-285.13-251.203 204.282-59.193-327zm1.043 232.987l30.678 169.472 130.191-105.873-79.31 147.776 133.183 10.474-121.213 51.254 128.696 104.377-161.245-48.635 11.225 123.084-74.822-99.888-104.38 149.271 43.024-170.97-156.006 54.996 127.573-112.235-110.364-50.13L416.2 461.84l-78.94-148.897 129.444 107.745 32.174-171.344z"
          opacity="1"
        ></path>
      </g>
    </svg>
  );
}

export default CritHitIcon;
