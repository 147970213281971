import styled, { css } from 'styled-components';

export const openCss = css`
  .x-wing-wing-set-1 {
    transform: rotate(18deg);
  }
  .x-wing-wing-set-2 {
    transform: rotate(-18deg);
  }
`;

export const hoverCss = css`
  &:hover {
    ${openCss}
  }
`;

export const SvgWrapper = styled.div`
  position: relative;
`;

export const XWingSvgContainer = styled.svg`
  opacity: ${({ silhouetteOnly }) => (silhouetteOnly ? 0 : 1)};
  z-index: 10;

  .x-wing-wing-set-1,
  .x-wing-wing-set-2 {
    transition: transform 1.2s ease;
    transform-origin: 50% 50%;
  }
  &.open {
    .x-wing-wing-set-1 {
      transform: rotate(18deg);
    }
    .x-wing-wing-set-2 {
      transform: rotate(-18deg);
    }
  }

  ${({ open }) => open && openCss}

  ${({ animateOnHover }) => animateOnHover && hoverCss}
`;

export const XWingSilhouetteSvgContainer = styled(XWingSvgContainer)`
  z-index: 1;
`;
