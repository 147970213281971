import bg1 from '../../images/backgrounds/bg1.jpg';
import bg2 from '../../images/backgrounds/bg2.png';
import bg3 from '../../images/backgrounds/bg3.jpg';
import bg4 from '../../images/backgrounds/bg4.jpg';
import bg7 from '../../images/backgrounds/bg7.jpg';
import bg8 from '../../images/backgrounds/bg8.jpg';

export const CUSTOM = 'CUSTOM';
const X_WING_RANDOM_BG = 'X_WING_RANDOM_BG';

export const gameBoardOptions = [
  { key: 'random', label: 'Random Wallpaper' },
  { key: 'black', label: 'Black', color: '#000' },
  {
    key: CUSTOM,
    label: 'Custom Background',
    color:
      'linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red);',
  },
];

const gameBoardOptionsKeys = gameBoardOptions.map((option) => option.key);

export const gameBoardBGs = [
  { key: 'ep6-death-star', imgUrl: `url(${bg1})`, src: bg1, opacity: 1 },
  { key: 'death-star-rising', imgUrl: `url(${bg7})`, src: bg7, opacity: 0.6 },
  { key: 'death-star-trench', imgUrl: `url(${bg2})`, src: bg2, opacity: 1 },
  { key: 'asteroid-field', imgUrl: `url(${bg8})`, src: bg8, opacity: 1 },
  { key: 'blue-star-field', imgUrl: `url(${bg3})`, src: bg3, opacity: 1 },
  { key: 'star-field', imgUrl: `url(${bg4})`, src: bg4, opacity: 1 },
];

export const getRandomBackground = () => {
  const randomIndex = Math.floor(Math.random() * gameBoardBGs.length);

  return gameBoardBGs[randomIndex];
};

export const getBackground = (preSelected, customValue) => {
  const currentRandom = localStorage.getItem(X_WING_RANDOM_BG);
  if (preSelected === 'random') {
    if (currentRandom)
      return gameBoardBGs.find((bg) => bg.key === currentRandom);
    const newRandom = getRandomBackground();
    localStorage.setItem(X_WING_RANDOM_BG, newRandom.key);

    return newRandom;
  }

  if (gameBoardOptionsKeys.includes(preSelected)) {
    const bg = gameBoardOptions.find((option) => option.key === preSelected);
    if (bg.key === CUSTOM) {
      return { key: CUSTOM, color: customValue || bg.color };
    }
  }

  if (preSelected) return gameBoardBGs.find((bg) => bg.key === preSelected);

  const newRandom = getRandomBackground();
  localStorage.setItem(X_WING_RANDOM_BG, newRandom.key);

  return newRandom;
};
