import React from 'react';

function JamIcon({ color, ...rest }) {
  return (
    <path
      className="jam-icon"
      fill={color || '#fff'}
      strokeWidth="0.26"
      d="M415.693 309.566l-62.578 237.99-14.578-67.992H253.38v28.868h59.158l40.578 168.006 62.578-220.008 59.159 234.004 25.142-109.455 25.154 109.455 59.159-234.004 62.578 220.007 40.578-168.005h59.158v-28.868h-85.158l-14.578 67.993-62.578-237.99-57.15 231.995-27.163-114.974-27.15 114.974z"
      opacity="1"
      {...rest}
    ></path>
  );
}

export default JamIcon;
