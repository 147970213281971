import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useContext } from 'react';
import styled from 'styled-components';
import QuickBuildCard from './QuickBuildCard';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import factions from '../../gameData/factions.json';
import factionImages from '../../images/factions';
import { GameStateContext } from '../../context/gameState';
import colors from '../../styles/colors';
import { names } from '../../utils';

const getTotalThreatLevel = (selectedCards) =>
  selectedCards.reduce((total, card) => {
    return total + card.threatLevel;
  }, 0);

function SelectedCards({
  selectedCards,
  handleClick,
  opponent,
  faction,
  ship,
}) {
  const { gameState } = useContext(GameStateContext);
  const totalThreatLevel = getTotalThreatLevel(selectedCards);

  const { player1, player2, isHost } = gameState || {};

  const enforceFactionRules = gameState?.settings?.enforceFactionRules;

  let otherPlayer = isHost ? player2 : player1;
  if (opponent) otherPlayer = isHost ? player1 : player2;

  const otherPlayerThreatLevel = getTotalThreatLevel(
    otherPlayer?.quickBuild || []
  );

  const threatDiff = totalThreatLevel - otherPlayerThreatLevel;

  const selectedFactions = enforceFactionRules
    ? faction
    : selectedCards.reduce((factions, card) => {
        if (!factions.includes(card.faction)) factions.push(card.faction);
        return factions;
      }, []);

  return (
    <Container className="selected-cards">
      <Accordion defaultExpanded={!opponent}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <PlayerSummary>
            <h3>
              {opponent ? 'Opponent' : 'Your'} Total Threat Level:{' '}
              <span className="current-threat">{totalThreatLevel}</span>
              {gameState.settings?.useMaxThreatLevel ? (
                <span className="max-threat">
                  / {gameState.settings?.maxThreatLevel}
                </span>
              ) : null}
            </h3>
            {selectedFactions?.length > 0 ? (
              selectedFactions?.map((f) => {
                const factionData = factions.list.find((fa) => fa.key === f);

                return (
                  <FactionTag
                    key={f}
                    color={factionData?.color}
                    background={factionData?.background}
                  >
                    <img src={factionImages[f]} alt={f} />
                    <p>{factionData?.name}</p>
                  </FactionTag>
                );
              }) ?? []
            ) : (
              <FactionTag className="simple">
                No {enforceFactionRules ? 'faction' : 'ships'} selected
              </FactionTag>
            )}
            {threatDiff === 0 ? null : (
              <ThreatDiff
                className={names({
                  positive: threatDiff > 0,
                  negative: threatDiff < 0,
                })}
              >
                {threatDiff < 0 ? 'Disadvantage: ' : 'Advantage: +'}
                {threatDiff}
              </ThreatDiff>
            )}
          </PlayerSummary>
        </AccordionSummary>
        <AccordionDetails>
          <CardGrid>
            {selectedCards.length > 0 &&
              selectedCards.map((card, i) => {
                return (
                  <QuickBuildCard
                    key={card.name + i}
                    card={card}
                    handleSelect={() => handleClick(card)}
                    remove
                    isOpponentCard={opponent}
                    ship={ship}
                  />
                );
              })}
          </CardGrid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 12px;

  .card,
  .action-bar {
    border: 1px solid green;
    &:hover {
      border: 1px solid red;
    }
  }
`;

const CardGrid = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const PlayerSummary = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  .current-threat {
    color: ${colors.hullYellow};
  }

  .max-threat {
    font-size: 1rem;
    margin-left: 4px;
  }
`;

const ThreatDiff = styled.div`
  margin-left: 12px;
  font-size: 1.25rem;
  font-weight: bold;
  &.positive {
    color: ${colors.agilityGreen};
  }
  &.negative {
    color: ${colors.weaponRed};
  }
`;

const FactionTag = styled.div`
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  display: grid;
  gap: 6px;
  grid-template-columns: 32px auto;
  align-items: center;
  justify-items: start;
  padding: 6px;
  font-weight: bold;
  text-align: left;
  border-radius: 8px;
  margin-left: 16px;

  img {
    height: 30px;
  }

  p {
    justify-self: start;
    font-size: 18px;
    text-shadow: 1px 1px 1px black;
  }

  &.simple {
    grid-template-columns: auto;
    color: #555;
    border: 1px dashed #555;
  }
`;

export default SelectedCards;
