import styled from 'styled-components';
import colors from '../../styles/colors';

// hull / shield / agility / attack
import AgilityStatIcon from '../../images/icons/AgilityStatIcon';
import ChargeStatIcon from '../../images/icons/ChargeStatIcon';
import ForceStatIcon from '../../images/icons/ForceStatIcon';
import HullStatIcon from '../../images/icons/HullStatIcon';
import ShieldStatIcon from '../../images/icons/ShieldStatIcon';
import { Tooltip } from '@mui/material';
import { convertCase, createArrayWithLengthN, names } from '../../utils';
import RechargeArrow from '../../components/RechargeArrow';

const getIcon = (type) => {
  switch (type.toLowerCase()) {
    case 'hull':
      return { Icon: HullStatIcon, color: colors.hullYellow };
    case 'shield':
      return { Icon: ShieldStatIcon, color: colors.shieldBlue };
    case 'agility':
      return { Icon: AgilityStatIcon, color: colors.agilityGreen };
    case 'charge':
      return { Icon: ChargeStatIcon, color: colors.goldenrod };
    case 'force':
      return { Icon: ForceStatIcon, color: colors.forcePurple };
    case 'front-arc':
      return { Icon: null, color: colors.white };
    default:
      return {};
  }
};

// TODO: add attack icons

const StatBlock = ({
  type,
  sign,
  number,
  recharge,
  horizontal,
  inverted,
  size,
  sx,
  overrideColor,
  ...rest
}) => {
  const { Icon, color } = getIcon(type);

  const iconColor = overrideColor || color || colors.white;

  if (!Icon) return null;

  const showNumber = number !== undefined && number !== null;

  const rechargeArrows = createArrayWithLengthN(recharge || 0).map((_, i) => {
    return <RechargeArrow key={i} />;
  });

  return (
    <Tooltip title={convertCase(type).initialCaps}>
      <StatContainer
        className={names({ horizontal, inverted })}
        style={{ fontSize: size, ...sx }}
        {...rest}
      >
        <Icon color={iconColor} width="100%" height="100%" />
        {showNumber && (
          <Num className={type}>
            {sign && <span className="sign">{sign}</span>}
            <p>{number}</p>
            {rechargeArrows.length > 0 && (
              <RechargeArrowContainer>{rechargeArrows}</RechargeArrowContainer>
            )}
          </Num>
        )}
      </StatContainer>
    </Tooltip>
  );
};

const StatContainer = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 1em;
    height: 1em;
  }

  &.horizontal {
    flex-direction: row;
    align-items: center;

    .stat-ring {
      transition: transform 0.5s;
      transform-origin: 50% 50%;
      transform: rotate(-90deg);
    }
  }

  &.inverted {
    flex-direction: column-reverse;
    align-items: center;

    .stat-ring {
      transition: transform 0.5s;
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  }
`;

const Num = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};

  font-family: 'Teko', 'roboto', sans-serif;
  font-size: 0.7em;
  font-weight: 700;

  p {
    line-height: 1;

    span {
      font-weight: 400;
      margin-top: -20px;
    }
  }

  &.shield {
    color: ${colors.shieldBlue};
    svg {
      fill: ${colors.shieldBlue};
    }
  }

  &.hull {
    color: ${colors.hullYellow};
    svg {
      fill: ${colors.hullYellow};
    }
  }
  &.agility {
    color: ${colors.agilityGreen};
    svg {
      fill: ${colors.agilityGreen};
    }
  }
  &.force {
    color: ${colors.forcePurple};
    svg {
      fill: ${colors.forcePurple};
    }
  }
  &.charge {
    color: ${colors.goldenrod};
    svg {
      fill: ${colors.goldenrod};
    }
  }

  .sign {
    font-size: 0.75em;
    margin-right: 5px;
  }
`;

const RechargeArrowContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
  row-gap: 0em;
  column-gap: 0.04em;
  margin-left: 0.15em;
  height: 100%;
  /* align-self: flex-start; */

  svg {
    width: 0.25em;
    height: 0.22em;
    fill: ${colors.white};
  }
`;

export default StatBlock;
