import React, { useContext, useState } from 'react';
import { Button, Modal, Tooltip } from '@mui/material';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import UpgradeCard, { AbilityName, UpgradeContainer } from './UpgradeCard';

import factionImages from '../../images/factions';

import HullShieldBars from './HullShieldBars';
import TokenIcon from './TokenIcon';
import colors, { white } from '../../styles/colors';
import {
  factionColors,
  factionMap,
  getCallSignStyles,
  transformTokenState,
} from './gameUtils';
import { capitalize, createArrayWithLengthN, names } from '../../utils';
import { SocketContext } from '../../context/socket';
import ActionBar from './ActionBar';
import CritCard from './CritCard';
import PilotButtons from './PilotButtons';
import {
  AbilitiesContainer,
  BottomLeftContainer,
  CardMainSection,
  CardTopRow,
  Container,
  CritEffectsContainer,
  DestroyedContainer,
  ForceTokenContainer,
  InfoButton,
  ModalContainer,
  PilotCardImg,
  PilotContainer,
  PilotMain,
  ShipIndex,
  ShipName,
  ShipSilhouette,
  ShipStatsArea,
  StatColumn,
  TokenContainer,
  TokenIconContainer,
  UpgradeSection,
} from './PilotCard.styles';

import { replaceTextWithTokens } from './textUtils';
import getSilhouette from '../../images/silhouettes';
import { getActionsList, upgradeSortFunction } from './upgradeUtils';
import ShipDock from './ShipDock';
import StatIcon from '../../components/StatIcon';
import XOverX from '../../components/XOverX';
import RechargeArrow from '../../components/RechargeArrow';

// SOCKET EVENTS:
// const ADJUST_PILOT_CHARGE = 'ADJUST_PILOT_CHARGE';
const ADJUST_PILOT_HULL = 'ADJUST_PILOT_HULL';
const ADJUST_PILOT_SHIELD = 'ADJUST_PILOT_SHIELD';
const ADJUST_UPGRADE_CHARGE = 'ADJUST_UPGRADE_CHARGE';
const ADJUST_FORCE = 'ADJUST_FORCE';
const FLIP_UPGRADE = 'FLIP_UPGRADE';
const REMOVE_PILOT_CRIT = 'REMOVE_PILOT_CRIT';
const REMOVE_PILOT_TOKEN = 'REMOVE_PILOT_TOKEN';
const TOGGLE_DOCKED = 'TOGGLE_DOCKED';

// constant values:
const WIKI_BASE_URL = 'https://xwing-miniatures-second-edition.fandom.com/';

function PilotCard({
  pilot,
  player,
  isOpponentCard,
  currentShips,
  destroyedPilots,
  phase,
  forwardRef,
}) {
  const socket = useContext(SocketContext);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  if (!pilot) return null;

  const { isPlayerOne } = player;
  const {
    crits = [],
    faction,
    force,
    forceRecharge: pilotForceRecharge,
    hull,
    id,
    maxForce,
    maxHull,
    maxShield = 0,
    nameData = {},
    shield,
    ship,
    tokens = [],
  } = pilot || {};
  const factionColor = factionColors[faction] || white;

  const adjustPilotHull = (delta) => {
    if (isOpponentCard) return;
    socket.emit(ADJUST_PILOT_HULL, { pilotId: id, delta });
  };
  const adjustPilotShield = (delta) => {
    if (isOpponentCard) return;
    socket.emit(ADJUST_PILOT_SHIELD, { pilotId: id, delta });
  };
  const removeToken = (token) => {
    if (isOpponentCard) return;
    socket.emit(REMOVE_PILOT_TOKEN, { pilotId: id, token });
  };

  const removeCritCard = (critCardName) => {
    if (isOpponentCard) return;
    socket.emit(REMOVE_PILOT_CRIT, { pilotId: id, critCardName });
  };

  const handleChargeClick = (upgradeTitle, delta) => {
    if (isOpponentCard) return;
    socket.emit(ADJUST_UPGRADE_CHARGE, { pilotId: id, upgradeTitle, delta });
  };

  const handleForceClick = (delta) => {
    if (isOpponentCard) return;
    socket.emit(ADJUST_FORCE, { pilotId: id, delta });
  };

  const handleFlipClick = (upgradeTitle) => {
    if (isOpponentCard) return;
    socket.emit(FLIP_UPGRADE, { pilotId: id, upgradeTitle });
  };

  const toggleDocked = () => {
    if (isOpponentCard) return;
    socket.emit(TOGGLE_DOCKED, { pilotId: id });
  };

  const tokenList = transformTokenState(tokens);

  const [nameFirstLine] =
    pilot?.name?.split(' (') ?? pilot?.cardName?.split(' (') ?? [];

  let upgradeTileCount = pilot?.upgrades?.length;
  if (pilot?.maxForce > 0) upgradeTileCount += 1;

  const silhouette = getSilhouette(ship?.name);

  const coShipIsDestroyed = destroyedPilots?.includes(pilot?.coShip) ?? false;

  const repairableCritCardCount = crits.reduce((count, crit) => {
    return crit?.effect?.includes('Action: Repair this card.')
      ? count + 1
      : count;
  }, 0);

  return (
    <Container
      className={names({
        'turn-highlight': currentShips.includes(pilot.id),
        'is-opponent-card': isOpponentCard,
        engagement: phase === 'ENGAGEMENT',
        'main-ship':
          pilot.isMainShip && !pilot.isDestroyed && !coShipIsDestroyed,
      })}
      ref={forwardRef}
    >
      {!pilot.isDestroyed && (
        <>
          <PilotButtons
            socket={socket}
            pilotId={id}
            isOpponentCard={isOpponentCard}
          />
          {tokenList.length > 0 ? (
            <TokenContainer>
              {tokenList.map((token, i) => (
                <TokenIconContainer
                  key={`${token.token}:${i}`}
                  onClick={() => removeToken(token.token)}
                  style={{ justifySelf: 'center' }}
                >
                  <TokenIcon
                    tokenName={token.token}
                    count={token.count}
                    toolTipText={
                      isOpponentCard
                        ? null
                        : `Remove ${capitalize(token.token)} Token`
                    }
                  />
                </TokenIconContainer>
              ))}
            </TokenContainer>
          ) : null}
        </>
      )}
      <PilotMain>
        <PilotContainer
          className={names({
            crit: crits.length > 0 && !pilot.isDestroyed,
          })}
          factionColor={factionColor}
        >
          <CardTopRow factionColor={factionColor}>
            <div className="initiative">
              {pilot.initiative} <br />
            </div>
            <div className="names">
              <div className="name">
                {nameData.limited ? (
                  <span className="limited-dots">
                    {'•'.repeat(nameData.limited)}
                  </span>
                ) : null}
                {nameFirstLine}
                <InfoButton onClick={setInfoModalOpen} className="info">
                  <InfoOutlinedIcon fontSize="small" />
                </InfoButton>
              </div>
            </div>
            <img
              src={factionImages[faction]}
              alt={factionMap[faction]}
              title={factionMap[faction]}
            />
            {nameData.callSign ? (
              <div
                className="call-sign"
                style={getCallSignStyles(nameData.callSign)}
              >
                <p>{nameData.callSign}</p>
              </div>
            ) : null}
          </CardTopRow>
          <CardMainSection>
            <AbilitiesContainer
              className={names({
                'no-ability': pilot.pilotAbility === 'no pilot ability',
              })}
              factionColor={factionColor}
            >
              <div className="pilot-ability">
                {replaceTextWithTokens(pilot.pilotAbility, colors.black)}
              </div>
              {ship.ability ? (
                <div className="ship-ability">
                  {replaceTextWithTokens(ship.ability, colors.black)}
                </div>
              ) : null}
            </AbilitiesContainer>
            <ShipStatsArea className="ship-stats">
              <div className="wpn-container">
                {ship.wpn.map((wpn) => (
                  <StatIcon
                    key={wpn.arc}
                    type={wpn.arc}
                    size="40px"
                    number={wpn?.damage}
                    showRing
                  />
                ))}
              </div>
              <StatIcon
                showRing
                size="40px"
                type="agility"
                number={ship.agl || 0}
              />
              {maxForce ? (
                <StatColumn className="force">
                  <StatIcon
                    showRing
                    vertical
                    size="40px"
                    type="force"
                    number={<XOverX top={force} bottom={maxForce} />}
                    recharge={pilotForceRecharge}
                  />
                </StatColumn>
              ) : null}
              {pilot.maxCharges ? (
                <StatColumn className="charge">
                  <StatIcon
                    showRing
                    vertical
                    size="40px"
                    type="charge"
                    number={
                      <XOverX top={pilot.charges} bottom={pilot.maxCharges} />
                    }
                    recharge={pilot.recharge}
                  />
                </StatColumn>
              ) : null}
            </ShipStatsArea>
            <ActionBar
              actions={getActionsList(ship.actions, pilot)}
              factionColor={factionColor}
              repairableCritCardCount={repairableCritCardCount}
            />
            <ShipName className="ship-name">
              <p>{pilot.ship.name}</p>
            </ShipName>
          </CardMainSection>
          <BottomLeftContainer>
            <ShipIndex black={!isPlayerOne ^ isOpponentCard}>
              {pilot.shipNumber}
            </ShipIndex>
            {silhouette && (
              <ShipSilhouette
                src={silhouette}
                alt={ship.name}
                factionColor={factionColor}
              />
            )}
          </BottomLeftContainer>
          {pilot.isDestroyed && (
            <DestroyedContainer className="destroyed">
              <div className="overlay">
                <p>Destroyed</p>
              </div>
            </DestroyedContainer>
          )}
        </PilotContainer>
        <HullShieldBars
          crit={crits.length > 0 && !pilot.isDestroyed}
          maxHull={maxHull}
          hull={hull}
          maxShield={maxShield}
          shield={shield}
          hullClick={adjustPilotHull}
          shieldClick={adjustPilotShield}
          factionColor={factionColor}
        />
        {pilot.isMainShip && !pilot.isDestroyed && !coShipIsDestroyed && (
          <ShipDock
            clickFunc={isOpponentCard ? null : toggleDocked}
            docked={pilot.docked}
            factionColor={factionColor}
            ship={pilot}
          />
        )}
      </PilotMain>
      {!pilot.isDestroyed && (
        <>
          <UpgradeSection big={upgradeTileCount > 6}>
            {maxForce > 0 ? (
              <UpgradeContainer
                style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <AbilityName className="force">
                  Force Tokens
                  <Tooltip
                    title={`Recharge ${pilotForceRecharge} token${
                      pilotForceRecharge > 1 ? 's' : ''
                    } in end phase`}
                  >
                    <span className="recharge-arrows">
                      {pilotForceRecharge &&
                        createArrayWithLengthN(pilotForceRecharge).map(
                          (_, i) => (
                            <RechargeArrow key={i} fill={colors.forcePurple} />
                          )
                        )}
                    </span>
                  </Tooltip>
                </AbilityName>
                <ForceTokenContainer>
                  {createArrayWithLengthN(maxForce).map((_, i) => {
                    const isEmpty = i > force - 1 ? colors.darkRed : undefined;
                    return (
                      <div
                        key={i}
                        onClick={() => handleForceClick(isEmpty ? 1 : -1)}
                        style={{ cursor: 'pointer' }}
                      >
                        <TokenIcon
                          tokenName="force"
                          colorOverride={isEmpty}
                          suppressToolTip={isOpponentCard}
                          toolTipText={
                            isEmpty ? 'Add Force Token' : 'Remove Force Token'
                          }
                        />
                      </div>
                    );
                  })}
                </ForceTokenContainer>
              </UpgradeContainer>
            ) : null}
            {pilot.upgrades.sort(upgradeSortFunction).map((upgrade, i) => (
              <UpgradeCard
                key={`${upgrade.title}-${i}`}
                pilotId={id}
                pilotNameData={nameData}
                upgrade={upgrade}
                handleChargeClick={handleChargeClick}
                handleFlipClick={handleFlipClick}
              />
            ))}
          </UpgradeSection>
          {crits.length > 0 && (
            <CritEffectsContainer>
              {crits.map((crit, i) => {
                return (
                  <CritCard
                    crit={crit}
                    key={crit.title + i}
                    removeCritCard={removeCritCard}
                  />
                );
              })}
            </CritEffectsContainer>
          )}
        </>
      )}
      <Modal open={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
        <ModalContainer factionColor={factionColor}>
          <Button
            className="close-button"
            onClick={() => setInfoModalOpen(false)}
          >
            <CloseOutlinedIcon fontSize="large" />
          </Button>
          <PilotCardImg src={`/pilots/${pilot.cardImage}`} />
          <div className="wikia-link">
            <a
              href={new URL(pilot.wikiLink, WIKI_BASE_URL)}
              target="_blank"
              rel="noreferrer"
            >
              {pilot.name} Wiki Page
            </a>
          </div>
        </ModalContainer>
      </Modal>
    </Container>
  );
}

export default PilotCard;
