import React from 'react';

function StrainIcon({ color, ...rest }) {
  return (
    <path
      fill={color || '#fff'}
      d="M563.775 365.22v53.723l-42.478 76.815-43.522 78.699h86v60.084l215.592-138.783zm15.42 32.046l162.668 98.492H547.367l31.828-57.553zm-245.42 21.677l-42.478 76.815-43.522 78.699h60.791l86-155.514zm98.27 0l-86 155.514h94.252l86-155.514zm-90.27 16h24.791l-33.584 60.815h-24.79zm100.27 2h54.252l-33.033 58.815h-54.252z"
      display="inline"
      {...rest}
    ></path>
  );
}

export default StrainIcon;
