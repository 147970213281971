import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';

import { getArcSvg } from './arc';
import { convertCase, names } from '../../utils';
import colors from '../../styles/colors';
import { generateOpacityArray } from '../icons/statUtils';
import { GameSettingsContext } from '../../context/gameSettings';

const opacityArray = [
  0.4, 0.2, 0.6, 0.2, 0.2, 0.4, 0.8, 1, 0.2, 0.4, 0.8, 0.4, 0.2, 1, 0.6, 0.6, 1,
  0.5, 0.5,
];

const scale = 0.6;
const translate = (1000 * (1 - scale)) / 2;

const ArcIcon = ({ type, size, color, horizontal, showRing, ...rest }) => {
  const { gameSettings } = useContext(GameSettingsContext);
  const [array, setArray] = useState(opacityArray);
  const arc = getArcSvg(type, color);

  const fillColor = color || colors.weaponRed;

  useEffect(() => {
    if (gameSettings.showAnimations) {
      setTimeout(() => {
        setArray(generateOpacityArray());
      }, 1900);
    }
  }, [array, gameSettings.showAnimations]);

  return (
    <Tooltip title={convertCase(type).initialCaps}>
      <SvgContainer
        xmlns="http://www.w3.org/2000/svg"
        width={size || '100%'}
        height={size || '100%'}
        version="1.1"
        viewBox="0 0 1000 1000"
        className={names(
          {
            horizontal: horizontal,
            vertical: !horizontal,
          },
          'arc-icon'
        )}
        {...rest}
      >
        {showRing && (
          <g
            className="stat-ring"
            stroke="none"
            strokeOpacity="1"
            display="inline"
          >
            <path
              fill="none"
              d="M989.868 501.232a483.71 483.71 0 01-483.29 483.71 483.71 483.71 0 01-484.13-482.87 483.71 483.71 0 01482.45-484.55 483.71 483.71 0 01484.967 482.03"
              display="inline"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[0]}
              className="stat-ring-box-1"
              strokeDasharray="none"
              d="M824.744 266.403a447.159 447.159 0 00-28.895-34.105c-.766-.817-.682-2.136.192-2.961l34.93-33.003c.874-.825 2.27-.758 3.124.156a492.185 492.185 0 0132.256 38.178c.757.995.591 2.381-.367 3.105l-38.29 28.929c-.958.723-2.271.589-2.95-.299z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[1]}
              className="stat-ring-box-1"
              strokeDasharray="none"
              d="M876 352.67a412.658 412.658 0 00-20.05-39.496c-.556-.963-.174-2.22.861-2.82l41.38-24.025c1.034-.6 2.371-.215 2.991.869a466.407 466.407 0 0122.435 44.544c.5 1.143.018 2.447-1.077 2.922l-43.764 18.976c-1.095.475-2.33.041-2.776-.97z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[2]}
              className="stat-ring-box-2"
              strokeDasharray="none"
              d="M903.985 447.88c-2.187-14.464-5.279-28.782-9.21-42.892-.295-1.059.385-2.172 1.53-2.498l45.805-13.024c1.146-.325 2.344.38 2.68 1.584a428.632 428.632 0 0110.619 48.914c.194 1.242-.607 2.383-1.788 2.558l-47.214 7.001c-1.18.175-2.258-.558-2.422-1.644z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[3]}
              className="stat-ring-box-3"
              strokeDasharray="none"
              d="M905.035 547.09a338.4 338.4 0 002.808-43.866c-.002-1.103.961-1.992 2.167-1.992h48.207c1.205 0 2.17 1.025 2.154 2.297a413.617 413.617 0 01-3.713 50.395c-.168 1.246-1.262 2.117-2.445 1.954l-47.302-6.528c-1.183-.164-2.016-1.17-1.876-2.26z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[4]}
              className="stat-ring-box-4"
              strokeDasharray="none"
              d="M879.008 642.796a385.87 385.87 0 0014.682-41.504c.306-1.056 1.47-1.645 2.615-1.32l45.805 13.025c1.146.326 1.793 1.556 1.445 2.756a446.616 446.616 0 01-16.562 47.057c-.479 1.153-1.754 1.704-2.852 1.24l-43.925-18.578c-1.098-.465-1.635-1.657-1.208-2.676z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[5]}
              className="stat-ring-box-5"
              strokeDasharray="none"
              d="M829.367 729.903a433.351 433.351 0 0024.562-37.147c.564-.958 1.847-1.247 2.882-.647l41.38 24.025c1.034.601 1.364 1.955.734 3.033a481.851 481.851 0 01-27.416 41.726c-.74 1.007-2.115 1.24-3.078.524l-38.525-28.603c-.963-.715-1.202-2.012-.54-2.91z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[6]}
              className="stat-ring-box-6"
              strokeDasharray="none"
              d="M760.627 803.898a449.846 449.846 0 0032.446-30.796c.772-.81 2.094-.8 2.968.025l34.93 33.002c.874.825.886 2.222.023 3.128a494.208 494.208 0 01-36.275 34.399c-.95.813-2.344.727-3.122-.188l-31.101-36.606c-.778-.915-.718-2.236.13-2.964z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[7]}
              className="stat-ring-box-7"
              strokeDasharray="none"
              d="M677.3 860.265a424.142 424.142 0 0038.336-22.449c.931-.612 2.21-.305 2.872.695l26.46 39.956c.661 1 .355 2.359-.69 3.042a474.97 474.97 0 01-43.14 25.077c-1.111.57-2.444.166-2.985-.9l-21.655-42.585c-.541-1.065-.182-2.327.803-2.836z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[8]}
              className="stat-ring-box-8"
              strokeDasharray="none"
              d="M583.904 894.49c14.279-3.189 28.377-7.246 42.23-12.106 1.039-.365 2.195.24 2.595 1.363l15.963 44.888c.4 1.123-.226 2.363-1.404 2.774a437.698 437.698 0 01-48.01 13.761c-1.22.275-2.41-.446-2.665-1.61l-10.184-46.542c-.255-1.164.403-2.288 1.475-2.527z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[9]}
              className="stat-ring-box-9"
              strokeDasharray="none"
              d="M485.04 902.397c7.02.392 14.06.564 21.118.51a334.04 334.04 0 0022.92-.615c1.099-.067 2.051.835 2.137 2.03l3.446 47.796c.086 1.196-.859 2.236-2.12 2.326a410.99 410.99 0 01-26.383 1.023 410.785 410.785 0 01-24.312-.882c-1.262-.083-2.213-1.119-2.133-2.315l3.2-47.83c.08-1.196 1.028-2.104 2.126-2.043z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[10]}
              className="stat-ring-box-10"
              strokeDasharray="none"
              d="M387.89 882.979c13.872 4.794 27.987 8.782 42.281 11.9 1.073.234 1.737 1.355 1.488 2.52l-9.958 46.595c-.25 1.166-1.436 1.893-2.658 1.623a437.014 437.014 0 01-48.08-13.537c-1.18-.406-1.81-1.643-1.417-2.767l15.755-44.959c.394-1.124 1.548-1.735 2.588-1.375z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[11]}
              className="stat-ring-box-11"
              strokeDasharray="none"
              d="M298.21 838.818a423.357 423.357 0 0038.423 22.279c.987.504 1.352 1.765.815 2.832l-21.465 42.676c-.536 1.067-1.867 1.477-2.98.912a474.386 474.386 0 01-43.246-24.89c-1.048-.679-1.36-2.036-.703-3.038l26.287-40.064c.657-1.002 1.935-1.315 2.868-.707z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[12]}
              className="stat-ring-box-12"
              strokeDasharray="none"
              d="M220.514 774.43a449.746 449.746 0 0032.57 30.661c.852.725.917 2.045.143 2.964l-30.947 36.732c-.774.919-2.168 1.01-3.121.201a494.127 494.127 0 01-36.418-34.248c-.867-.901-.86-2.298.01-3.127l34.795-33.147c.87-.829 2.192-.844 2.968-.037z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[13]}
              className="stat-ring-box-13"
              strokeDasharray="none"
              d="M159.317 694.327a434.004 434.004 0 0024.722 37.05c.667.896.434 2.193-.527 2.913l-38.408 28.766c-.96.72-2.337.493-3.08-.511a482.338 482.338 0 01-27.595-41.611c-.635-1.075-.31-2.43.722-3.036l41.28-24.206c1.032-.605 2.317-.321 2.886.635z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[14]}
              className="stat-ring-box-14"
              strokeDasharray="none"
              d="M119.132 603.024a386.836 386.836 0 0014.88 41.443c.43 1.017-.1 2.212-1.197 2.682l-43.844 18.777c-1.097.47-2.375-.076-2.859-1.226a447.325 447.325 0 01-16.776-46.979c-.354-1.198.288-2.43 1.432-2.762l45.742-13.242c1.144-.33 2.31.253 2.622 1.307z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[15]}
              className="stat-ring-box-15"
              strokeDasharray="none"
              d="M104.474 505.036a339.06 339.06 0 003.042 43.841c.146 1.088-.683 2.1-1.864 2.269l-47.259 6.764c-1.182.17-2.28-.695-2.455-1.94a414.025 414.025 0 01-3.963-50.352c-.022-1.272.937-2.302 2.142-2.308l48.18-.254c1.206-.006 2.173.877 2.177 1.98z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[16]}
              className="stat-ring-box-16"
              strokeDasharray="none"
              d="M117.06 406.727c-3.862 14.126-6.882 28.46-8.994 42.934-.159 1.086-1.232 1.825-2.414 1.656l-47.259-6.765c-1.182-.169-1.988-1.307-1.8-2.55a428.01 428.01 0 0110.387-48.975c.33-1.205 1.524-1.916 2.672-1.596l45.866 12.806c1.147.32 1.832 1.43 1.542 2.49z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[17]}
              className="stat-ring-box-17"
              strokeDasharray="none"
              d="M155.458 314.756a411.772 411.772 0 00-19.87 39.575c-.442 1.013-1.676 1.453-2.773.983l-43.844-18.777c-1.097-.47-1.586-1.772-1.09-2.917a465.748 465.748 0 0122.238-44.641c.615-1.086 1.95-1.478 2.988-.882l41.477 23.843c1.038.597 1.425 1.851.874 2.816z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
            <path
              fill={fillColor}
              fillOpacity={array[18]}
              className="stat-ring-box-18"
              strokeDasharray="none"
              d="M215.213 233.641a446.854 446.854 0 00-28.753 34.221c-.675.89-1.987 1.03-2.948.31l-38.408-28.766c-.96-.719-1.132-2.105-.379-3.102a491.957 491.957 0 0132.096-38.312c.85-.917 2.245-.99 3.122-.168l35.066 32.857c.877.822.966 2.14.204 2.96z"
              transform="rotate(179.901 523.914 519.52) scale(1.07008)"
            ></path>
          </g>
        )}
        <g
          transform={
            showRing
              ? `translate(${translate} ${translate}) scale(${scale})`
              : null
          }
        >
          {arc}
        </g>
      </SvgContainer>
    </Tooltip>
  );
};

const SvgContainer = styled.svg`
  &.horizontal {
    flex-direction: row;
    align-items: center;

    .stat-ring {
      transition: transform 0.5s;
      transform-origin: 50% 50%;
      transform: rotate(-90deg);
    }
  }
`;

export default ArcIcon;
