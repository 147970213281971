import React from 'react';

function RechargeArrow({ recharge = 0, fill }) {
  const fillColor = fill;

  return (
    <svg viewBox="0 0 100 87" width="100%" height="100%">
      <path fill={fillColor} d="M 50,0 0,87 H 100 Z" />
    </svg>
  );
}

export default RechargeArrow;
