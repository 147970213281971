import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { GameDataContext } from '../../context/gameData';

import QuickBuildCard from './QuickBuildCard';

const getLevels = (shipBuilds) => {
  return shipBuilds ? Object.keys(shipBuilds) : [];
};

const getFactionCards = (quickBuilds, faction, ship) => {
  if (faction && ship) return quickBuilds?.[faction]?.[ship];

  return [];
};

function SelectQuickBuild({ handleSelect, previewOnly }) {
  const { gameData } = useContext(GameDataContext);
  const { factionParam, shipParam } = useParams();
  const faction = factionParam
    ? decodeURIComponent(factionParam)
    : factionParam;
  const ship = shipParam ? decodeURIComponent(shipParam) : shipParam;

  const quickBuilds = gameData?.quickBuilds ?? {};
  const qbMap = getFactionCards(quickBuilds ?? {}, faction, ship);
  const threatLevels = getLevels(qbMap);

  return (
    <Container>
      <LevelsContainer className="levels-container">
        {threatLevels.map((level) => {
          const cards = qbMap[level];
          return (
            <LevelContainer key={level}>
              <h2>{level}</h2>
              <CardContainer>
                {cards.map((card, i) => {
                  return (
                    <QuickBuildCard
                      key={card.name + i}
                      card={card}
                      ship={shipParam}
                      handleSelect={handleSelect}
                      faction={faction}
                      previewOnly={previewOnly}
                    />
                  );
                })}
              </CardContainer>
            </LevelContainer>
          );
        })}
      </LevelsContainer>
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding-right: 16px;
`;

const LevelsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-top: 12px;
    align-self: start;
  }
`;

const LevelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;
  padding: 4px 0;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export default SelectQuickBuild;
